import React, { forwardRef, useRef, useEffect } from "react";
import { CheckBoxNoLabel } from "../CheckBoxes/CheckBoxes";
import { RadioButton } from "../RadioButton/RadioButton";

export type ICheckboxProps = {
  indeterminate: boolean;
  input_type: "radio" | "checkbox";
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: string;
};

export const TableCheckBox = forwardRef<HTMLInputElement, ICheckboxProps>(
  ({ indeterminate, input_type, handleChange, name, value, ...rest }, ref) => {
    const defaultRef = useRef<HTMLInputElement>();
    const resolvedRef: any = ref || defaultRef;
    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return input_type === "radio" ? (
      <RadioButton
        theref={resolvedRef}
        {...rest}
        onClick={(e) => e.stopPropagation()}
        handleChange={handleChange}
        name={name ?? "select-radio"}
        value={value}
      />
    ) : (
      <CheckBoxNoLabel
        ref={resolvedRef}
        {...rest}
        onClick={(e) => e.stopPropagation()}
      />
    );
  }
);
