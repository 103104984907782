import { yupResolver } from "@hookform/resolvers/yup";
import {
  GoBackButton,
  PrimaryButtonLarge,
  TextButton,
} from "../../../components/Buttons/Buttons";
import { CheckBoxNoLabel } from "../../../components/CheckBoxes/CheckBoxes";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
  WarningMessageBox,
} from "../../../components/Form/Form";
import { DesktopFormContainerCart } from "../../../components/Layout/Layout";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { TextField } from "../../../components/TextFields/TextFields";
import { H3, Title } from "../../../components/Typography/Typography";
import { GuestCart } from "../../../components/Cart/Cart";
import { strings } from "../../../util/strings";
import type {
  ILeadSchema,
  OptionType,
  SupportedLanguage,
} from "../../../types/types";
import {
  ColoredTextOnError,
  getBrowserLanguage,
} from "../../../util/util-components";
import { Flex, Flex1, Flex2, Form } from "../../../layout/FormLayout";
import Axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import type { mutateCallback } from "swr/dist/types";
import * as Yup from "yup";
import "yup-phone";
import { Auth } from "../../../components/Auth";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { PrivacyPolicyLink } from "../../../components/PrivacyPolicyLink/PrivacyPolicyLink";
import { CartContext } from "../../../components/quoteCart/CartContext";
import type {
  CartSubmission,
  ICartItem,
  Lead,
} from "../../../components/quoteCart/cartUtils";
import { prepareCartItemToSend } from "../../../components/quoteCart/cartUtils";
import { GuestQuoteItemForm } from "../../../components/quoteCart/GuestQuoteItemForm";
import { getPhoneCodesOptions } from "../../../util/phone";
import { emailAddressRegex } from "../../../util/regexes";
import { useRoutePath } from "../../../util/Routing";
import { useValidateEmail } from "../../../util/useValidateEmail";
import {
  isAxiosError,
  useStoreState,
  useFormWrapper,
  usePolicyDocuments,
  isValidPhoneNumber,
} from "../../../util/util";
import { useCookies } from "react-cookie";
import {
  RequestAddress,
  getRequestAddressSchema,
} from "../RequestAddress/RequestAddress";
import {
  PaymentRequest,
  getPaymentRequestSchema,
} from "../PaymentRequest/PaymentRequest";
import { WarningIcon } from "../../../components/Icons/Icons";
import {
  configured_checkboxes_schema_yup,
  create_configured_checkboxes_fields,
} from "../../admin/SellerAdmin/SellerAdminSettings/TermsTab/ConfigureCheckboxsCard/utils";
import {
  captcha_yup_validation,
  captcha_yup_validation_not_required,
  ControlledRecaptcha,
} from "../../../components/Recaptcha/ControlledRecaptcha";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.secondaryBG};
  border: solid 1px ${({ theme }) => theme.primaryBorder};
  border-radius: 6px;
  padding: 24px 24px 36px;
  margin: 15px 0px;
`;

const FormWrapper = styled.div`
  max-width: 452px;
`;

const ConfirmationSpacer = styled.div`
  margin: 15px 0 10px;
`;

interface LeadBuyer {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  buyer_company_name: string;
  country_code: OptionType;
  required_eta: string;
  country: OptionType<string>;
  address1: string;
  address2?: string;
  state: OptionType<string>;
  county?: string; // only shows if selected country is India
  city: string;
  postal_code: string;
  billing_country?: OptionType<string>;
  billing_address1: string;
  billing_address2?: string;
  billing_state: OptionType<string>;
  billing_county?: string; // only shows if selected country is India
  billing_city: string;
  billing_postal_code: string;
  configured_checkboxes?: { value: boolean; label: string }[];
  captcha_token: string;
}

type CartMutate = (
  data?: Lead | Promise<Lead> | mutateCallback<Lead> | undefined,
  shouldRevalidate?: boolean | undefined
) => Promise<Lead | undefined>;

// TODO: get this to type check without the `any`. Maybe use zod instead?
const schema = (
  t: TFunction,
  transactionData: boolean,
  hideBillingAddress: boolean,
  require_captcha: boolean
) =>
  Yup.lazy((formValues: any) => {
    return Yup.object().shape({
      first_name: Yup.string().required(strings(t).thisIsARequiredField),
      last_name: Yup.string().required(strings(t).thisIsARequiredField),

      email: Yup.string()
        .email(strings(t).emailAddressMustBeValid)
        .required(strings(t).thisIsARequiredField),

      phone_number: Yup.string().min(1, strings(t).phoneNumberMustBeValid),
      country_code: Yup.object()
        .shape({
          label: Yup.string().required(strings(t).thisIsARequiredField),
          value: Yup.string().required(strings(t).thisIsARequiredField),
        })
        .required(strings(t).thisIsARequiredField),
      buyer_company_name: Yup.string().required(
        strings(t).thisIsARequiredField
      ),
      ...(transactionData
        ? {
            ...getRequestAddressSchema(t, hideBillingAddress),
            ...getPaymentRequestSchema(t),
          }
        : {}),
      confirmation_pv: Yup.boolean().oneOf([true]),
      configured_checkboxes: configured_checkboxes_schema_yup({
        formValues,
        t,
      }),
      ...(require_captcha
        ? captcha_yup_validation(t)
        : captcha_yup_validation_not_required()),
    });
  });

export const LeadsCart = ({
  lead,
  mutate,
  setRedirectURI,
  goToParentPage,
}: {
  lead: Lead;
  mutate: CartMutate;
  setRedirectURI: React.Dispatch<React.SetStateAction<string>>;
  goToParentPage: () => void;
}) => {
  const { validateEmail } = useValidateEmail();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { cartId, ulpId, clearCartStorage } = useContext(CartContext);
  const { t } = useTranslation();
  const { user } = useContext(Auth);
  const history = useHistory();
  const { storePath } = useRoutePath();
  const { privacyPolicy } = usePolicyDocuments();
  const cartItems = lead?.items;

  const {
    slug: tenantSlug,
    storefront_metadata: {
      supported_languages,
      enable_transaction_lead_quote,
      configured_checkboxes,
      require_captcha,
    },
    api_metadata: { captcha_site_key },
  } = useStoreState();

  const langs = Object.keys(supported_languages);

  const [submitting, setSubmitting] = useState(false);

  const [phoneNumberWarning, setPhoneNumberWarning] =
    useState<{ message: string } | null>(null);

  const [hideBillingAddress, setHideBillingAddress] = useState(true);

  const [cookies] = useCookies([`preferred-language-${tenantSlug}`]);

  const preferredLanguage: SupportedLanguage | undefined = cookies[
    `preferred-language-${tenantSlug}`
  ] as SupportedLanguage;

  const browserLanguage: SupportedLanguage | undefined =
    getBrowserLanguage() as SupportedLanguage;

  const quoteCheckboxes = configured_checkboxes.filter((item) => item.quote);

  const methodsOfUseForm = useFormWrapper<
    LeadBuyer & { confirmation_pv: boolean }
  >({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    resolver: yupResolver(
      schema(
        t,
        enable_transaction_lead_quote,
        hideBillingAddress,
        require_captcha
      )
    ),
    defaultValues: {
      country_code: {},
    },
  });

  const { register, handleSubmit, formState, errors, control } =
    methodsOfUseForm;

  const { fields, append } = useFieldArray({
    control,
    name: "configured_checkboxes",
  });

  useEffect(() => {
    if (quoteCheckboxes.length > 0 && fields?.length < 1) {
      quoteCheckboxes.forEach((element) => {
        append({ value: false, label: element.name, id: element.id });
      });
    }
  }, [quoteCheckboxes, append, fields]);

  const onFormSubmit = async (data: LeadBuyer) => {
    setSubmitting(true);
    const {
      first_name,
      last_name,
      email,
      buyer_company_name,
      phone_number,
      country_code,
      captcha_token,
    } = data;

    if (cartId && cartItems?.length) {
      const validEmail = await validateEmail({ email, source: "guest cart" });
      if (validEmail && tenantSlug) {
        try {
          const cartSubmission: CartSubmission = {
            seller_id_or_slug: tenantSlug,
            cart_id: cartId,
            buyer_first_name: first_name,
            buyer_last_name: last_name,
            buyer_email: email,
            buyer_company_name: buyer_company_name,
            buyer_phone_number: `${country_code.value}${phone_number}`,
            captcha_token: require_captcha ? captcha_token : undefined,
            recaptcha_key: require_captcha ? captcha_site_key : undefined,
            language:
              preferredLanguage ??
              user?.preferred_language ??
              (() => {
                const supportedLanguage = langs.find(
                  (lang) => lang === browserLanguage
                );
                if (supportedLanguage) {
                  return supportedLanguage;
                } else {
                  return null;
                }
              })() ??
              "en",
          };

          if (
            !isValidPhoneNumber({ phone_number, country_code }) &&
            !phoneNumberWarning
          ) {
            // only display this once, if the user clicks submit a second time let
            // them go
            setPhoneNumberWarning({
              message: t(
                "Phone number might be invalid, press submit to continue"
              ),
            });
            setSubmitting(false);

            return;
          }

          if (ulpId) {
            cartSubmission.ulp_id = ulpId;
          }

          if (enable_transaction_lead_quote) {
            cartSubmission.required_eta = data.required_eta;
            cartSubmission.shipping_address = {
              country: data.country.value,
              state: data.state.value,
              address1: data.address1,
              address2: data.address2,
              county: data.county,
              city: data.city,
              postal_code: data.postal_code,
            };
            if (data.billing_country) {
              cartSubmission.billing_address = {
                country: data.billing_country.value,
                state: data.billing_state.value,
                address1: data.billing_address1,
                address2: data.billing_address2,
                county: data.billing_county,
                city: data.billing_city,
                postal_code: data.billing_postal_code,
              };
            }
          }
          const response = await Axios.post<ILeadSchema>(
            `/v1/storefronts/${tenantSlug}/leads`,
            cartSubmission
          );

          if (response.status === 208) {
            notifyError(
              t(
                "You are already registered on the storefront. Please log in to raise the request"
              )
            );
            history.push(`${storePath}/login`);
          } else {
            notifySuccess(t("Cart submitted successfully"));
            history.push(`${storePath}/cart/thank-you-quote`);
          }

          // The side effects from the following call causes the cart items
          // in this component to become empty and that causes a useEffect
          // to call goBack() which takes the user back where they came from.
          clearCartStorage();

          // TODO: We can do this optimization if we can make TypeScript happy.
          // await mutateCart(response.data.cart);
          await mutate();
        } catch (error) {
          // If an email address has been used to submit a guest quote
          // request (a.k.a. a lead) and a guest tries to submit another
          // quote request with the same email, that will fail (400). We
          // show the error message sent from the backend so guest can see
          // if it was that email error or some other bad request error.
          // If there's no message from the backend, show a fallback message.
          const message =
            isAxiosError(error) && error.response?.data.message
              ? error.response.data.message
              : t("There was an error submitting the cart, please try again");

          notifyError(message, {
            error,
            logMessage: "Submitting the cart failed",
          });
        }
      }
    }
    setSubmitting(false);
  };

  const [cartItemToEdit, setCartItemToEdit] = useState<ICartItem | null>(null);

  const handleCloseSlideOut = () => {
    setCartItemToEdit(null);
  };

  const editCartItem = (itemToEdit: ICartItem): void => {
    setCartItemToEdit(itemToEdit);
  };

  /**
   * Deletes an item from the cart. A new version of the cart state is created
   * without the item to be deleted, and then that new version of the cart
   * state is sent to the server where it replaces the old state. Since there is
   * no concept of deleting the cart itself, but rather the items in it we
   * delete the cart ID from localStorage when the last item is removed. For
   * user tracking purposes this means that if they abandon a cart and then
   * create a new quote request it counts as a new cart.
   */
  const removeCartItem = async (itemToRemove: ICartItem): Promise<void> => {
    // This check should always succeed, since we can't remove a cart item if
    // there is no lead ID or no items in the cart.
    if (lead?.id && cartItems) {
      const idToDelete = itemToRemove.product.id;
      const newCartItems = cartItems.filter(
        (item) => item.product.id !== idToDelete
      );
      try {
        const cartItemsToSend = newCartItems.map(prepareCartItemToSend);
        const url = `/v1/cart/${lead.id}`;

        const { data: newLead } = await Axios.put<Lead>(url, {
          items: cartItemsToSend,
        });

        if (newLead.items.length === 0) {
          clearCartStorage();
        }
        // Update SWR's cached version of the lead/cart.
        mutate(newLead);
      } catch (error) {
        notifyError("There was an error deleting the cart item.", { error });
      }

      // If the cart is now empty the user is redirected back where they came
      // by a call to goBack() in a useEffect in the parent `CartSwitcher`
    }
  };

  if (!cartItems || cartItems.length === 0) {
    // The cart will become empty on successful submit and also if the user
    // removes all of the cart items.

    goToParentPage();
  }

  return (
    <Wrapper>
      <DesktopFormContainerCart>
        <TextButton onClick={goToParentPage}>
          <GoBackButton text={t("Portfolio")} />
        </TextButton>
        <Title style={{ fontWeight: 600 }}>{t("Quote request")}</Title>
        <GuestCart
          cartItems={cartItems}
          edit={editCartItem}
          remove={removeCartItem}
        />
        <Container>
          <H3>{t("Send your request")}</H3>
          {phoneNumberWarning && (
            <WarningMessageBox>
              <div style={{ marginRight: "4px" }}>
                <WarningIcon width={18} />
              </div>
              {t(
                `The phone number provided cannot be validated, if you're confident it is correct please press "submit" again to continue`
              )}
            </WarningMessageBox>
          )}
          <FormWrapper>
            <Form onSubmit={handleSubmit(onFormSubmit)} noValidate>
              <TextField
                name={"first_name"}
                label={t("First Name")}
                type={"text"}
                theref={register({
                  maxLength: 50,
                  required: true,
                })}
                formState={formState}
                errors={errors}
              />
              <TextField
                name={"last_name"}
                label={t("Last Name")}
                theref={register({
                  required: true,
                })}
                type={"text"}
                formState={formState}
                errors={errors}
              />
              <TextField
                name={"email"}
                label={t("Business Email Address")}
                theref={register({
                  required: true,
                  pattern: {
                    value: emailAddressRegex,
                    message: t("invalid email address"),
                  },
                })}
                type={"email"}
                formState={formState}
                errors={errors}
              />
              <Flex>
                <Flex1>
                  <Controller
                    as={SelectBoxV2}
                    control={control}
                    name="country_code"
                    autoComplete="countryCode"
                    placeholder={"Country Code"}
                    id="countryCodeSelectBox"
                    options={getPhoneCodesOptions()}
                    rules={{
                      required: false,
                    }}
                    errors={errors}
                    formState={formState}
                  />
                </Flex1>
                <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
                  <TextField
                    name={"phone_number"}
                    theref={register({
                      required: false,
                    })}
                    label={t("Phone Number")}
                    warningText={phoneNumberWarning}
                    formState={formState}
                    errors={errors}
                    type={"tel"}
                  />
                </Flex2>
              </Flex>
              <TextField
                // This has a distinct name so as not to conflict with company_name `RequestAddress`
                // which is not visible on this specific form but
                // nonetheless has a yup rule marking `company_name` as not required
                name={"buyer_company_name"}
                label={t("Company Name")}
                theref={register({
                  required: true,
                })}
                type={"text"}
                formState={formState}
                errors={errors}
              />
              {enable_transaction_lead_quote && (
                <>
                  <PaymentRequest methodsOfUseForm={methodsOfUseForm} />
                  <RequestAddress
                    hideBillingAddress={hideBillingAddress}
                    showBillingAddressOption={true}
                    setHideBillingAddress={setHideBillingAddress}
                    showLocationId={false}
                    methodsOfUseForm={methodsOfUseForm}
                  />
                </>
              )}
              <ConfirmationSpacer>
                {!!privacyPolicy && (
                  <CheckBoxContainer>
                    <div style={{ width: "22px", marginRight: "15px" }}>
                      <CheckBoxNoLabel
                        name="confirmation_pv"
                        id="confirmation_pv"
                        ref={register()}
                        error={!!errors.confirmation_pv?.message}
                      />
                    </div>
                    <CheckBoxFinePrintLabel htmlFor="confirmation_pv">
                      <ColoredTextOnError
                        isError={!!errors.confirmation_pv?.message}
                      >
                        <>
                          <span>{t("I agree to the ")}</span>
                          <PrivacyPolicyLink customPolicy />
                          <span>.</span>
                        </>
                      </ColoredTextOnError>
                    </CheckBoxFinePrintLabel>
                  </CheckBoxContainer>
                )}
              </ConfirmationSpacer>
              {fields.length > 0 && (
                <>
                  {create_configured_checkboxes_fields({
                    fields,
                    methodsOfUseForm,
                    t,
                  })}
                </>
              )}
              {require_captcha && (
                <ControlledRecaptcha methodsOfUseForm={methodsOfUseForm} />
              )}
              <PrimaryButtonLarge
                style={{ minWidth: "242px" }}
                loading={submitting}
              >
                {t("Request Quote")}
              </PrimaryButtonLarge>
            </Form>
          </FormWrapper>
        </Container>
      </DesktopFormContainerCart>

      {cartItemToEdit && (
        <SlideOut closeFlyout={handleCloseSlideOut} show={!!cartItemToEdit}>
          <GuestQuoteItemForm
            allDone={handleCloseSlideOut}
            product={cartItemToEdit.product}
            cartItem={cartItemToEdit}
          />
        </SlideOut>
      )}
    </Wrapper>
  );
};
