import * as z from "zod";
import {
  useFormWrapper,
  useStoreState,
  isAxiosError,
  toTitleCase,
  getDateTime,
} from "../../../../../../util/util";
import styled from "styled-components";
import { stringIsNotOnlyWhiteSpaceRegex } from "../../../../../../util/regexes";
import type { AxiosError } from "axios";
import Axios from "axios";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { PrimaryButtonFitContainer } from "../../../../../../components/Buttons/Buttons";
import { endpoints } from "../../../../../../endpoints";
import { Form } from "../../../../../../layout/FormLayout";
import type {
  Assets,
  ListDetailsSchema,
  ListItemObjectSchema,
  SupportedAssetCategoryType,
  SupportedAssetType,
} from "../../../../../../types/types.PIM";
import React, { useState, useContext, useEffect } from "react";
import {
  zodOptionalString,
  zodSelectBoxDefault,
  zodSelectBoxType,
} from "../../../../../../util/zod.util";
import { SectionTitle } from "../../../../../../components/Form/Form";
import { HeaderLeft } from "../../../../../../components/Layout/Layout";
import { TextField } from "../../../../../../components/TextFields/TextFields";
import { SelectBoxV2 } from "../../../../../../components/SelectBoxV2/SelectBoxV2";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import { getNameAndExtension } from "../../../../../SharedPages/ProductDetailPage/Resources/Resources.utils";
import { FileUploader } from "../util/FileUploader";
import useSWR from "swr";
import type {
  Language,
  OptionType,
  SupportedLanguage,
} from "../../../../../../types/types";
import { ErrorPlaceholder } from "../../../../../../components/Error";
import type { AssetCategory, AssetType } from "../util/AssetsUtil";
import {
  useCountriesList,
  useRegionsList,
} from "../../../../../../util/Locations";
import { getCountryOption } from "../../../../../../util/location";
import { DatePicker } from "../../../../../../components/DatePicker/DatePicker";
import type { IAssetPayload } from "../../../../../SharedPages/ProductDetailPage/Assets/NewAssetForm";

/**
 * This is used for type inference
 */
const AddAssetSchema = z.object({
  asset_name: z.string(),
  input_asset_type: zodSelectBoxType,
  input_category_type: zodSelectBoxType,
  input_lang_type: zodSelectBoxType.optional().nullable(),
  input_document_type: zodSelectBoxType.optional().nullable(),
  file_type: z.instanceof(File).optional(),
  input_issue_date: zodOptionalString,
  input_expiration_date: zodOptionalString,
  input_renewal_date: zodOptionalString,
  input_country: zodSelectBoxType.optional().nullable(),
  input_region: zodSelectBoxType.optional().nullable(),
});

type FormValues = z.infer<typeof AddAssetSchema>;

/**
 *
 * This is used for schema validation
 */
const AddAssetSchemaFn = (t: (s: string) => string) =>
  z
    .object({
      asset_name: z
        .string()
        .regex(stringIsNotOnlyWhiteSpaceRegex, t("This is a required field")),
      input_asset_type: zodSelectBoxDefault(t),
      input_category_type: zodSelectBoxDefault(t),
      input_lang_type: zodSelectBoxDefault(t).optional().nullable(),
      file_type: z.instanceof(File).optional().nullable(),
      input_issue_date: zodOptionalString,
      input_expiration_date: zodOptionalString,
      input_renewal_date: zodOptionalString,
      input_country: zodSelectBoxDefault(t).optional().nullable(),
      input_region: zodSelectBoxDefault(t).optional().nullable(),
      input_document_type: zodSelectBoxDefault(t).optional().nullable(),
    })
    .superRefine(({ input_asset_type, input_category_type }, ctx) => {
      if (!input_asset_type?.value || !input_category_type?.value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("This is a required field"),
          path: ["input_asset_type"],
        });
      }
    });

const MarginBottomHeaderLeft = styled(HeaderLeft)`
  margin-bottom: 28px;
`;

export const EditAsset = ({
  onComplete,
  refreshAssetsList,
  assetData,
}: {
  onComplete: () => void;
  refreshAssetsList: any;
  assetData: Assets;
}) => {
  const { t } = useTranslation();
  const countries = useCountriesList();
  const regions = useRegionsList();
  const [submitting, setSubmitting] = useState(false);

  const { notifySuccess, notifyError } = useContext(Notifications);
  const { tenant_id, storefront_id } = useStoreState();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [assetTypes, setAssetTypes] = useState<
    OptionType<SupportedAssetType>[]
  >([]);
  const [assetCategories, setAssetCategories] = useState<
    OptionType<SupportedAssetCategoryType>[]
  >([]);

  const [documentTypesFilters, setDocumentTypesFilters] = useState<
    OptionType<string>[]
  >([]);
  const [assetLanguages, setAssetLanguages] = useState<
    OptionType<SupportedLanguage>[]
  >([]);
  const {
    category,
    asset_type,
    language,
    id,
    name,
    file_name,
    issue_date,
    expiration_date,
    renewal_date,
    document_type,
    country,
    region,
  } = assetData;
  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(AddAssetSchemaFn(t)),
    defaultValues: {
      input_asset_type: { label: toTitleCase(asset_type), value: asset_type },
      input_category_type: {
        label: category?.name,
        value: category?.id,
      },
      input_lang_type: assetLanguages.find((item) => item.value === language),

      input_document_type: document_type
        ? {
            label: toTitleCase(document_type),
            value: document_type,
          }
        : undefined,
      asset_name: getNameAndExtension(name)?.name,
      input_issue_date: issue_date ?? undefined,
      input_expiration_date: expiration_date ?? undefined,
      input_renewal_date: renewal_date ?? undefined,
      input_country: country ? getCountryOption(countries, country) : undefined,
      input_region: region
        ? {
            label: region,
            value: region,
          }
        : undefined,
    },
  });
  const {
    handleSubmit,
    control,
    errors,
    formState,
    register,
    setValue,
    watch,
  } = methodsOfUseForm;

  const selectedAssetType: string = watch("input_asset_type")?.value;

  const { error: assetTypeError } = useSWR<AssetType>(
    endpoints.v2_storefronts_id_pim_asset_types(),
    {
      onSuccess: ({ asset_types }) =>
        setAssetTypes(
          asset_types.map((type) => ({
            label: toTitleCase(type),
            value: type,
          }))
        ),
    }
  );

  const { error: categoryTypeError } = useSWR<AssetCategory>(
    selectedAssetType
      ? `${endpoints.v2_tenants_id_pim_assets_categories(
          tenant_id
        )}?asset_type=${selectedAssetType}`
      : endpoints.v2_tenants_id_pim_assets_categories(tenant_id),
    {
      onSuccess: ({ asset_categories }) =>
        setAssetCategories(
          asset_categories.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        ),
    }
  );

  const { data: languagesResponse, error: LanguageError } = useSWR<{
    languages: Language[];
  }>(endpoints.v1_storefronts_id_languages(storefront_id), {
    onSuccess: ({ languages }) => {
      setAssetLanguages(
        languages.map((item) => ({
          label: `${item.name} (${item.alpha_2.toUpperCase()})`,
          value: item.alpha_2,
        }))
      );
      const selectLanguageOption = languages.find(
        (item) => item.alpha_2 === language
      );
      setValue(
        "input_lang_type",
        selectLanguageOption?.alpha_2
          ? {
              label: `${
                selectLanguageOption?.name
              } (${selectLanguageOption?.alpha_2.toLocaleUpperCase()})`,
              value: selectLanguageOption?.alpha_2,
            }
          : undefined
      );
    },
    onError: () =>
      notifyError(t("could not load asset languages, Something went wrong.")),
  });

  const { data: documentTyoesResponse, error: documentTypeError } = useSWR<{
    data: ListDetailsSchema;
  }>(
    endpoints.v2_tenants_id_pim_get_list_by_name(tenant_id, "Document Types"),
    {
      onError: (error) => {
        console.error("There was an error fetching the Document types list.", {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: true,
      onSuccess: (data) => {
        setDocumentTypesFilters(
          data.data.items.map((type: ListItemObjectSchema) => {
            return { label: type.name, value: type.name };
          })
        );
      },
    }
  );

  const onSubmit = async ({
    asset_name,
    input_asset_type,
    input_category_type,
    input_lang_type,
    input_document_type,
    file_type,
    input_issue_date,
    input_expiration_date,
    input_renewal_date,
    input_region,
    input_country,
  }: FormValues): Promise<void> => {
    const paramsObj: IAssetPayload = {
      name: asset_name,
      asset_type: input_asset_type?.value.toLowerCase(),
      category_id: input_category_type?.value,
      language: input_lang_type?.value,
      issue_date: input_issue_date
        ? getDateTime(input_issue_date, true).dateFullYear
        : null,
      expiration_date: input_expiration_date
        ? getDateTime(input_expiration_date, true).dateFullYear
        : null,
      renewal_date: input_renewal_date
        ? getDateTime(input_renewal_date, true).dateFullYear
        : null,
      document_type: input_document_type?.value || null,
      country: input_country?.value || null,
      region: input_region?.value || null,
    };

    const baseURL = endpoints.v2_storefronts_id_pim_assets_id(tenant_id, id);

    setSubmitting(true);

    try {
      await Axios.patch(baseURL, paramsObj);

      if (file_type) {
        const formData = new FormData();
        formData.append("file", file_type);
        await Axios.put(baseURL, formData);
      }
      await refreshAssetsList();
      notifySuccess(t("Asset successfully edited"));
      onComplete();
    } catch (error) {
      if (
        isAxiosError(error) &&
        (error as AxiosError)?.response?.data?.message
      ) {
        notifyError(error?.response?.data?.message);
      } else {
        notifyError(t("could not edit asset, Something went wrong."));
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (languagesResponse) {
      setAssetLanguages(
        languagesResponse.languages.map((item) => ({
          label: `${item.name} (${item.alpha_2.toUpperCase()})`,
          value: item.alpha_2,
        }))
      );
      const selectLanguageOption = languagesResponse.languages.find(
        (item) => item.alpha_2 === language?.toLocaleLowerCase()
      );
      setValue(
        "input_lang_type",
        selectLanguageOption?.alpha_2
          ? {
              label: `${
                selectLanguageOption?.name
              } (${selectLanguageOption?.alpha_2.toLocaleUpperCase()})`,
              value: selectLanguageOption?.alpha_2,
            }
          : undefined
      );
    }
  }, [languagesResponse, language, setValue]);

  useEffect(() => {
    if (documentTyoesResponse) {
      setDocumentTypesFilters(
        documentTyoesResponse.data.items.map((type: ListItemObjectSchema) => {
          return { label: type.name, value: type.name };
        })
      );
    }
  }, [documentTyoesResponse]);

  useEffect(() => {
    if (countries && country) {
      setValue("input_country", getCountryOption(countries, country));
    }
  }, [countries, country, setValue]);

  if (assetTypeError || categoryTypeError) {
    return (
      <ErrorPlaceholder
        message={t(
          "There was an error loading asset types and categories. Please try again later"
        )}
      />
    );
  }

  return (
    <>
      <MarginBottomHeaderLeft>
        <SectionTitle>{t("Edit Asset")}</SectionTitle>
      </MarginBottomHeaderLeft>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="input_asset_type"
          autoComplete={"input_asset_type"}
          placeholder={t("Asset Type")}
          options={assetTypes}
          errors={errors}
          formState={formState}
          isDisabled={true}
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="input_category_type"
          autoComplete={"input_category_type"}
          placeholder={t("Category")}
          options={assetCategories}
          errors={errors}
          formState={formState}
        />
        <FileUploader
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setValue={setValue}
          selectedAssetType={selectedAssetType.toLowerCase()}
          control={control}
          errors={errors}
          formState={formState}
          existingFileName={file_name ? file_name : name}
        />
        <TextField
          name="asset_name"
          label={t("Asset Name")}
          autoComplete={"asset_name"}
          formState={formState}
          theref={register({
            required: false,
          })}
          errors={errors}
          type="text"
        />
        {!LanguageError && (
          <Controller
            as={SelectBoxV2}
            control={control}
            name="input_lang_type"
            autoComplete={"input_lang_type"}
            placeholder={t("Language")}
            options={assetLanguages}
            defaultValue={assetLanguages.find(
              (item) => item.value === language
            )}
            errors={errors}
            formState={formState}
          />
        )}
        {!documentTypeError && (
          <Controller
            as={SelectBoxV2}
            control={control}
            isClearable={true}
            name="input_document_type"
            autoComplete={"input_document_type"}
            placeholder={t("Document Type")}
            options={documentTypesFilters}
            errors={errors}
            formState={formState}
          />
        )}
        <DatePicker
          label={t("Issue Date")}
          name={"input_issue_date"}
          required={false}
          methodsOfUseForm={methodsOfUseForm}
          defaultValue={issue_date ?? undefined}
          placeholder={t("MM/DD/YYYY")}
          isOutsideRange={(day) => false}
        />

        <DatePicker
          label={t("Expiration Date")}
          name={"input_expiration_date"}
          required={false}
          methodsOfUseForm={methodsOfUseForm}
          placeholder={t("MM/DD/YYYY")}
          defaultValue={expiration_date ?? undefined}
          isOutsideRange={(day) => false}
        />

        <DatePicker
          label={t("Renewal Date")}
          name={"input_renewal_date"}
          required={false}
          methodsOfUseForm={methodsOfUseForm}
          placeholder={t("MM/DD/YYYY")}
          defaultValue={renewal_date ?? undefined}
          isOutsideRange={(day) => false}
        />
        {countries && (
          <Controller
            as={SelectBoxV2}
            control={control}
            name="input_country"
            isClearable={true}
            autoComplete={"country"}
            placeholder={t("Country")}
            defaultValue={
              country ? getCountryOption(countries, country) : undefined
            }
            options={countries}
            rules={{
              required: false,
            }}
            errors={errors}
            formState={formState}
          />
        )}
        <Controller
          as={SelectBoxV2}
          control={control}
          name="input_region"
          isClearable={true}
          autoComplete={"region"}
          placeholder={t("Region")}
          options={regions}
          rules={{
            required: false,
          }}
          errors={errors}
          formState={formState}
        />
        <PrimaryButtonFitContainer
          style={{ marginTop: "32px" }}
          type="submit"
          loading={submitting}
        >
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
};
