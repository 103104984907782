import type { FunctionComponent } from "react";
import React, { useContext, useState } from "react";
import { Notifications } from "../Notifications/NotificationsContext";
import { CartContext } from "./CartContext";
import { useFormWrapper, convertApplicationToOption } from "../../util/util";
import {
  useProductPackagingTypes,
  usePackagingUnits,
  packagingTypeOrUnitToOption,
} from "../../util/SkuUtils";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { HeaderSection } from "../QuoteAndOrderFormShared/QuoteAndOrderFormShared";
import type { Lead, CartItemToSend, ICartItem } from "./cartUtils";
import { prepareCartItemToSend } from "./cartUtils";
import Axios from "axios";
import type { OptionType, Product } from "../../types/types";
import { mutate } from "swr";
import { ErrorPlaceholder } from "../Error";
import { Title } from "../Typography/Typography";
import { Form } from "../../layout/FormLayout";
import { StatusBox } from "../Form/Form";
import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import { TextField } from "../TextFields/TextFields";
import { PrimaryButtonFitContainer } from "../Buttons/Buttons";
import { strings } from "../../util/strings";
import * as yup from "yup";
import { positiveNumberRegex } from "../../util/regexes";
import styled from "styled-components/macro";
import {
  productApplicationSchema,
  ProductApplicationSelect,
} from "../ProductApplicationSelect/ProductApplicationSelect";
import { useTranslation } from "react-i18next";

const StatusBoxInnerContainer = styled.div`
  width: 100%;
  & > * {
    margin-bottom: 15px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  & > div {
    flex-grow: 1;
    margin-right: 9px;
    width: 49%;
  }
  & > div:nth-last-child(1) {
    margin-right: 0;
  }
`;

/**
 * Add a new item to the cart items, or if an item for the product is already
 * in the cart replace it with the new item. (No more than one item for a
 * given product should ever exist in the cart.)
 *
 * @return Array of cart items including the new item.
 */
const updateCartItems = (
  newItem: CartItemToSend,
  items: CartItemToSend[]
): CartItemToSend[] => {
  const index = items.findIndex(
    (item) => item.product_id === newItem.product_id
  );

  if (index === -1) {
    // Add new item.
    items.push(newItem);
  } else {
    // Replace existing item.
    items.splice(index, 1, newItem);
  }

  return items;
};
interface GuestFormInputs {
  packaging_type: { value: string; label: string } | null;
  packaging_unit: { value: string; label: string } | null;
  total_volume: string;
  // If user does not select an application then we get undefined.
  application?: OptionType<string> | OptionType<null>;
  custom_application?: string;
}

interface GuestQuoteItemFormProps {
  product: Product;
  allDone: () => void;
  cartItem?: ICartItem;
}

/**
 * A form used by guests (not-logged-in public site visitors) to add
 * "quote request" items to their cart, or to edit existing cart items.
 *
 * @param props.product  The product the user wants a quote for.
 *
 * @param props.allDone  Function to call when the user is done with this form,
 *                       e.g. to close a modal or flyout, etc.
 *
 * @param props.cartItem  Optional, when editing an existing cart item, this is
 *                        used to populate the form with existing values.
 */
export const GuestQuoteItemForm: FunctionComponent<GuestQuoteItemFormProps> = ({
  product,
  allDone,
  cartItem,
}) => {
  const { t } = useTranslation();
  const { notifyError } = useContext(Notifications);
  const { cartId, setCartId } = useContext(CartContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { packagingTypes, packagingTypesError } = useProductPackagingTypes(
    product.id
  );
  const { packagingUnits, packagingUnitsError } = usePackagingUnits();
  const productSKUs = product.product_skus.filter((sku) => !sku.is_sample);
  const packagingTypeOptions =
    productSKUs?.length > 0
      ? productSKUs
          .filter((sku) => !sku.is_sample)
          .reduce<Array<{ label: string; value: string }>>(
            (uniqueOptions, sku) => {
              const typeId = sku.packaging_type.id;
              const alreadyExists = uniqueOptions.some(
                (option) => option.value === typeId
              );

              if (!alreadyExists) {
                uniqueOptions.push({
                  label:
                    sku.packaging_type.name ??
                    t("Error: packaging type name not found"),
                  value: typeId,
                });
              }

              return uniqueOptions;
            },
            []
          )
      : packagingTypes?.map(packagingTypeOrUnitToOption) || [];

  const packagingUnitOptions =
    packagingUnits?.map(packagingTypeOrUnitToOption) || [];

  const guestFormYupSchema = yup.object().shape({
    packaging_type: yup
      .object()
      .required(strings(t).thisIsARequiredField)
      .nullable(),

    total_volume: yup
      .string()
      .required(strings(t).thisIsARequiredField)
      // Use a regex to validate because it's a string.
      .matches(positiveNumberRegex, t("Must be a valid numeric value")),

    packaging_unit: yup
      .object()
      .required(strings(t).thisIsARequiredField)
      .nullable(),

    ...productApplicationSchema(t),
  });

  const methodsOfUseForm = useFormWrapper<GuestFormInputs>({
    resolver: yupResolver(guestFormYupSchema),
    defaultValues: {
      packaging_type: cartItem
        ? packagingTypeOptions.find(
            (option) => option.value === cartItem.packaging_type.id
          )
        : null,

      packaging_unit: cartItem
        ? packagingUnitOptions.find(
            (option) => option.value === cartItem.packaging_unit.id
          )
        : null,

      total_volume: cartItem?.total_volume || "",

      application:
        cartItem && cartItem.product_applications.length > 0
          ? convertApplicationToOption(cartItem.product_applications[0])
          : undefined,
    },
  });

  const { register, handleSubmit, control, formState, errors } =
    methodsOfUseForm;

  /**
   * On submit we add an item to the cart or edit an item that is already in
   * the cart. There are three cases:
   *
   * 1. cart doesn't exist yet, create it and add an item to it.
   * 2. cart already exists, add a new item to it.
   * 3. cart already exists, modify an existing item in it (by replacing it).
   *
   * Adding cart items and updating existing cart items happens client-side,
   * because the API takes a new array of cart items and overwrites the
   * previous array.
   *
   * If a cart already exists then its ID will be in localStorage. We need to
   * use the ID to get its current state from the server, so we can modify it
   * and send the new data back.
   *
   * If there is no ID in localStorage, we need to create the cart and persist
   * the newly created cart/lead ID in localStorage.
   */
  const onFormSubmit = async (data: GuestFormInputs) => {
    setIsSubmitting(true);
    const {
      packaging_type,
      packaging_unit,
      total_volume,
      application,
      custom_application,
    } = data;

    if (!packaging_type || !packaging_unit) {
      // For TypeScript, this should never happen, because of form validation.
      console.error("packaging_type or packaging_unit was not defined");
      setIsSubmitting(false);
      return;
    }

    try {
      const cartItem: CartItemToSend = {
        product_id: product.id,
        packaging_type_id: packaging_type.value,
        packaging_unit_id: packaging_unit.value,
        total_volume: total_volume.trim(),
        // applications is an array of IDs.
        applications: application?.value ? [application.value] : [],
        ...(custom_application ? { custom_application } : {}),
      };

      if (!cartId) {
        // Cart does not exist yet, create it with one item in it.
        const postResponse = await Axios.post(`/v1/cart`, cartItem);
        const newLead: Lead = postResponse.data;

        setCartId(newLead.id);
        setIsSubmitting(false);
        allDone();
      } else if (cartId) {
        // Cart exists, get its current state.
        const getResponse = await Axios.get(`/v1/cart/${cartId}`);
        const existingLead: Lead = getResponse.data;

        const items = updateCartItems(
          cartItem,
          existingLead.items.map(prepareCartItemToSend)
        );

        const putResponse = await Axios.put(`/v1/cart/${cartId}`, {
          items,
        });
        const newLead: Lead = putResponse.data;

        // Update SWR's cached version of the lead/cart.
        mutate(`/v1/cart/${cartId}`, newLead);
        setIsSubmitting(false);
        allDone();
      }
    } catch (error) {
      notifyError("There was an error editing the cart item", { error });
      setIsSubmitting(false);
    }
  };

  if (packagingTypesError || packagingUnitsError) {
    return (
      <ErrorPlaceholder
        message={"There was an error getting the packaging options."}
      />
    );
  }

  return (
    <div>
      <Title>{cartItem ? t("Edit Quote") : t("Get a Quote")}</Title>
      <Form onSubmit={handleSubmit(onFormSubmit)} noValidate>
        <StatusBox>
          <StatusBoxInnerContainer>
            <HeaderSection product={product} />
            <div>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="packaging_type"
                placeholder={t("Packaging")}
                options={packagingTypeOptions}
                rules={{ required: true }}
                errors={errors}
                formState={formState}
              />
            </div>
            <FlexRow>
              <div>
                <TextField
                  name={"total_volume"}
                  label={t("Total Volume")}
                  theref={register({ required: true })}
                  errors={errors}
                  formState={formState}
                  type="number"
                />
              </div>
              <div>
                <Controller
                  as={SelectBoxV2}
                  control={control}
                  name="packaging_unit"
                  placeholder={t("Unit of Measure")}
                  options={packagingUnitOptions}
                  rules={{ required: true }}
                  errors={errors}
                  formState={formState}
                />
              </div>
            </FlexRow>
          </StatusBoxInnerContainer>
        </StatusBox>
        <ProductApplicationSelect
          methodsOfUseForm={methodsOfUseForm}
          applications={product?.product_applications ?? []}
        />
        <PrimaryButtonFitContainer loading={isSubmitting}>
          {cartItem ? t("Save") : t("Add to quote request")}
        </PrimaryButtonFitContainer>
      </Form>
    </div>
  );
};
