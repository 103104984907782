import type { OptionType } from "../types/types";

/**
 * Get a country option object.
 * @param countryCode  E.g. "US", "CA", "ET".
 * @return A country option object for that country code (or undefined).
 */
export const getCountryOption = (
  countries: OptionType[],
  countryCode: string | undefined
): OptionType<string> | undefined => {
  // Some of the data has "USA" instead of "US".
  const value = countryCode === "USA" ? "US" : countryCode;
  return countries.find((country) => country.value === value);
};

/**
 * Get a state option object.
 * @param stateCode  E.g. "NY, "NJ, "KY".
 * @return A state option object for that state code (or undefined).
 */
export const getStateOption = (
  states: OptionType[],
  stateCode: string
): OptionType<string> | undefined => {
  const statesList = states.map((state) => {
    return { value: state.value.split("-").pop(), label: state.label };
  });
  return statesList.find((state) => state.value === stateCode);
};

export const getStatesInputLabel = (
  countryCode: string | undefined | null,
  t?: (val: string) => string
) => {
  switch (countryCode) {
    case "US":
      return t ? t("State") : "State";
    case "CN":
      return t ? t("State/Province/Region") : "State/Province/Region";
    case "DE":
      return t ? t("County") : "County";
    case "JP":
      return t ? t("State/Province/Region") : "State/Province/Region";
    case "MX":
      return t ? t("State/Province/Region") : "State/Province/Region";

    default:
      return t ? t("State") : "State";
  }
};

export const getAddressOneInputLabel = (
  countryCode: string | undefined | null,
  t?: (val: string) => string
) => {
  switch (countryCode) {
    case "US":
      return t ? t("Street Name") : "Street Name";
    case "IN":
      return t ? t("Address Line 1") : "Address Line 1";
    case "DE":
      return t ? t("Address Line 1") : "Address Line 1";
    case "CN":
      return t
        ? t("Street address, Organization name")
        : "Street address, Organization name";
    case "JP":
      return t
        ? t("Street address, Organization name")
        : "Street address, Organization name";
    case "MX":
      return t
        ? t("Street address, Organization name")
        : "Street address, Organization name";

    default:
      return t ? t("Address Line 1") : "Address Line 1";
  }
};

export const getAddressTwoInputLabel = (
  countryCode: string | undefined | null,
  t?: (val: string) => string
) => {
  switch (countryCode) {
    case "US":
      return t ? t("Address 2 (optional)") : "Address 2 (optional)";
    case "IN":
      return t ? t("Landmark (optional)") : "Landmark (optional)";
    case "CN":
      return t
        ? t("Building, Apartment, Floor (Optional)")
        : "Building, Apartment, Floor (Optional)";
    case "JP":
      return t
        ? t("Building, Apartment, Floor (Optional)")
        : "Building, Apartment, Floor (Optional)";
    case "DE":
      return t
        ? t("Street Number, House Number (Optional)")
        : "Street Number, House Number (Optional)";
    case "MX":
      return t ? t("Colony") : "Colony";

    default:
      return t ? t("Address 2 (optional)") : "Address 2 (optional)";
  }
};

export const getZipCodeInputLabel = (
  countryCode: string | undefined | null,
  t?: (val: string) => string
) => {
  switch (countryCode) {
    case "US":
      return t ? t("ZIP Code") : "ZIP Code";
    case "CN":
      return t ? t("Post Code") : "Post Code";
    case "CA":
      return t ? t("Postal Code") : "Postal Code";
    case "IN":
      return t ? t("PIN Code") : "PIN Code";
    case "DE":
      return t ? t("ZIP Code") : "ZIP Code";
    case "JP":
      return t ? t("Postal Code") : "Postal Code";
    case "MX":
      return t ? t("ZIP Code") : "ZIP Code";

    default:
      return t ? t("ZIP Code") : "ZIP Code";
  }
};
