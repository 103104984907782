import { useEffect, useMemo, useRef, useState } from "react";
import type { OptionType } from "../../../../../../types/types";
import { useStoreState } from "../../../../../../util/util";
import { useDebounce } from "../../../../../../util/hooks";
import type { Row } from "react-table";
import type {
  Assets,
  AttributeSchema,
  ImageAttributeValue,
  PIMAssetsResponse,
  SupportedAssetType,
} from "../../../../../../types/types.PIM";
import { useNotifications } from "../../../../../../components/Notifications/NotificationsContext";
import { endpoints } from "../../../../../../endpoints";
import type { AssetCategory } from "../../SellarAdminPIMAssets/util/AssetsUtil";
import {
  get_content_type,
  GetDocumentIcon,
  TrimmedName,
} from "../../SellarAdminPIMAssets/util/AssetsUtil";
import useSWR from "swr";
import {
  InfoIcon,
  MagicDocIcon,
  SearchIcon,
  XIcon,
} from "../../../../../../components/Icons/Icons";
import ReactTooltip from "react-tooltip";
import {
  H6,
  SoftHeader2,
} from "../../../../../../components/Typography/Typography";
import { DropDown } from "../../../../../../components/DropDown/DropDown";
import { Table } from "../../../../../../components/Table/Table";
import { GridAssetView } from "../../SellarAdminPIMAssets/GridAssetView/GridAssetView";
import {
  CancelButton,
  PrimaryButtonMedium,
  PrimaryCancelButton,
  TextButton,
} from "../../../../../../components/Buttons/Buttons";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components/macro";
import { Controller, useFormContext } from "react-hook-form";
import { SelectBoxV2 } from "../../../../../../components/SelectBoxV2/SelectBoxV2";
import { TextField } from "../../../../../../components/TextFields/TextFields";
import { Pagination } from "../../../../../../components/Pagination/Pagination";
import { strings } from "../../../../../../util/strings";
import type { TableRefProps } from "../../../../../../components/Table/utils";
import { ConfirmDialog } from "../../../../../../components/ConfirmDialog/ConfirmDialog";

const TableContainer = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.colors.secondaryLightGray};
`;

const ScrollArea = styled.div`
  max-height: 460px;
  overflow-y: auto;
  display: block;
`;

const SearchWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 52px;
  margin-bottom: 28px;
  > svg {
    position: absolute;
    right: 13px;
    top: 14px;
    path {
      fill: #9fa4aa;
    }
  }
`;

const FilterDropdownContainer = styled.div`
  div[class*="DropDown__DropDownModalWrapper"] {
    left: auto !important;
    right: 0;
  }
  .view_type,
  .category_type {
    z-index: 2;
  }
`;

const InnerTitleText = styled.div`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  min-height: 52px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  font-family: Inter;
  padding: 10px 13px 10px 13px;
  font-size: 13px;

  &:focus {
    border: 1px solid ${({ theme }) => theme.tertiaryBorder};
    box-shadow: 0 0 1px 1px ${({ theme }) => theme.tertiaryBorder};
    outline: none;
  }
`;

const ClearInput = styled.span`
  position: absolute;
  top: 14px;
  right: 13px;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  > * {
    margin-bottom: 15px;
  }
`;

export const ExistingImageAttribute = ({
  onComplete,
  onCancel,
  attribute,
  existing_asset,
  image_attribute_value,
}: {
  onComplete?: (image_attr: ImageAttributeValue | null) => void;
  onCancel: () => void;
  attribute: AttributeSchema;
  existing_asset?: Assets;
  image_attribute_value?: ImageAttributeValue;
}) => {
  const { t } = useTranslation();

  const [viewType, setViewType] = useState<"list" | "grid">("list");
  const [categoryType, setCategoryType] = useState<string>("All");
  const [categoryTypes, setCategoryTypes] = useState<string[]>([]);
  const [perPage] = useState(20);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [offset, setOffset] = useState(0);
  const [show_delete_modal, set_show_delete_modal] = useState(false);

  const { tenant_id } = useStoreState();
  const theme = useTheme();

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [selectedRow, setSelectedRow] =
    useState<{ [pageNumber: string]: Row<Assets> }>();
  const [defaultSelectedRow, setDefaultSelectedRow] =
    useState<{ [x: string]: Row<Assets>[] }>();
  const [defaultSelectedGridItem, setDefaultSelectedGridItem] = useState<
    Row<Assets>[]
  >([]);
  const [is_default_row_selected, set_is_default_row_selected] =
    useState(false);
  const [trigger_reset_selected_row, set_trigger_reset_selected_row] =
    useState(false);
  const [tableRef, setTableRef] = useState<Record<string, () => void>>();

  const { notifyError } = useNotifications();
  const resetSelectedRowsRef = useRef<TableRefProps>(null);

  const sizeOptions = useMemo<
    OptionType<ImageAttributeValue["image_dimensions"]>[]
  >(
    () => [
      { label: t("Small (Thumbnail)"), value: "small" },
      { label: t("Medium (Half-width)"), value: "medium" },
      { label: t("Large (Full-width)"), value: "large" },
    ],
    [t]
  );

  const defaultValues = useMemo(() => {
    const row = Object.values(selectedRow ?? {})[0];
    return {
      size: sizeOptions.find(
        (option) =>
          (image_attribute_value?.image_dimensions ??
            (attribute?.values?.[0]?.value as ImageAttributeValue)
              ?.image_dimensions) === option.value
      ),
      caption: (
        image_attribute_value ??
        (attribute?.values?.[0]?.value as ImageAttributeValue)
      )?.caption,
      asset_id: row?.original?.id,
    };
  }, [attribute?.values, image_attribute_value, selectedRow, sizeOptions]);

  const { register, control, errors, formState, trigger, getValues, watch } =
    useFormContext();

  const watchSize = watch(`${attribute.name}.size`) as OptionType<
    ImageAttributeValue["image_dimensions"]
  >;

  function constructQuery({
    baseURL,
    q,
    asset_category,
  }: {
    baseURL: string;
    q?: string;
    asset_category: string | string[];
  }) {
    const params = new URLSearchParams();
    if (q) {
      params.append("q", q);
    }
    if (asset_category !== "All") {
      if (Array.isArray(asset_category)) {
        asset_category.forEach((category) =>
          params.append("asset_category", category)
        );
      } else {
        params.append("asset_category", asset_category);
      }
    }
    params.append("asset_type", "image");
    params.append("limit", `${perPage}`);
    params.append("offset", `${offset}`);
    params.append("show_generated_assets", "false");
    return baseURL + "?" + params;
  }

  const { data: assetsResponse, error: assetsError } =
    useSWR<PIMAssetsResponse>(
      constructQuery({
        baseURL: endpoints.v2_storefronts_id_pim_assets(tenant_id),
        q: debouncedSearchQuery,
        asset_category:
          categoryType === "All"
            ? categoryTypes.length > 0
              ? categoryTypes.slice(1) // Category type: ["All", "Marketing", ...]. This removes the "All", as it isn't recognized from the BE
              : "All"
            : categoryType,
      })
    );

  const { error: categoryTypeError } = useSWR<AssetCategory>(
    `${endpoints.v2_tenants_id_pim_assets_categories(
      tenant_id
    )}?asset_type=image&deduped=true`,
    {
      onSuccess: ({ asset_categories }) =>
        setCategoryTypes(["All", ...asset_categories.map(({ name }) => name)]),
    }
  );

  const isLoading = !assetsResponse && !assetsError;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  const changeFilterType = (categoryType: string) => {
    setCategoryType(categoryType);
  };

  const changeViewType = (view_type: string) => {
    setViewType(view_type === t("List") ? "list" : "grid");
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const [tableData, setTableData] = useState<Assets[]>([]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t("Asset Name"),
        accessor: "name",
        Cell: ({
          value,
          row: {
            original: {
              asset_type,
              is_generated,
              content_type,
              is_downloadable,
            },
          },
        }: {
          value: string;
          row: {
            original: Assets;
          };
        }) => {
          return (
            <FlexWrapper>
              {is_generated ? (
                <div
                  data-tip={t("Generated Document")}
                  data-for="generated-doc-tooltip"
                  style={{ marginRight: "8px" }}
                >
                  <MagicDocIcon />
                  <ReactTooltip effect="solid" id="generated-doc-tooltip" />
                </div>
              ) : (
                <div style={{ marginRight: "8px" }}>
                  <GetDocumentIcon
                    asset_type={asset_type as SupportedAssetType}
                    file_type={get_content_type(
                      content_type ?? "application/pdf"
                    )}
                  />
                </div>
              )}
              <div style={{ width: "80%" }}>
                <TrimmedName text={value} />
              </div>
              {!is_downloadable && (
                <div
                  data-tip={t("This asset is not accessible outside login")}
                  data-for="is_downloadable-tooltip"
                >
                  <InfoIcon
                    width={16}
                    height={16}
                    fill={theme.secondaryIconColor}
                  />
                  <ReactTooltip effect="solid" id="is_downloadable-tooltip" />
                </div>
              )}
            </FlexWrapper>
          );
        },
      },
      {
        Header: t("Category"),
        accessor: "asset_category",
      },
    ],
    [t, theme.secondaryIconColor]
  );

  const handleSelectRows = (
    selectedRows:
      | {
          [pageNumber: string]: Row<Assets>[];
        }
      | "all"
  ) => {
    if (selectedRows !== "all") {
      if (Object.keys(selectedRows).length > 0) {
        const [pageNumber, rows] = Object.entries(selectedRows)[0];
        setSelectedRow({ [pageNumber]: rows[0] });
      } else {
        setSelectedRow(undefined);
      }
    }
  };

  const handleGridSelectedItems = (selectedItems: Row<Assets>[]) => {
    if (selectedItems.length) {
      setSelectedRow({
        [tablePagination.pageIndex]: selectedItems[0],
      });
    } else {
      setSelectedRow(undefined);
    }
  };

  const onDeleteImageAttribute = () => {
    set_show_delete_modal(false);
    onComplete?.(null);
  };

  const on_close_delete_modal = () => {
    set_show_delete_modal(false);
  };

  const onSubmit = async () => {
    if (!selectedRow || !Object.keys(selectedRow).length) {
      notifyError(t("Please select an asset and continue"));
    } else {
      const result = await trigger(`${attribute.name}.size`);
      if (result) {
        if (onComplete) {
          const values = getValues();
          const { caption, size, asset_id } = values[`${attribute.name}`];
          onComplete({
            caption,
            image_dimensions: size.value,
            asset_id,
          });
        }
      }
    }
  };

  useEffect(() => {
    const handleProductsData = ({ data, pagination }: PIMAssetsResponse) => {
      setTableData(
        data.map(
          ({
            id,
            name,
            asset_category,
            category,
            language,
            asset_type,
            is_generated,
            is_downloadable,
            content_type,
            preview_url,
          }) =>
            ({
              id,
              name: name ?? "--",
              category,
              asset_category,
              language,
              asset_type,
              is_downloadable,
              is_generated,
              content_type,
              preview_url,
            } as Assets)
        )
      );
      setTablePagination({
        perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
      set_trigger_reset_selected_row(true);
    };

    if (assetsResponse) {
      const { data: assets, pagination } = assetsResponse;
      const assetsData = assets?.filter(
        ({ asset_type }) => asset_type === "image"
      );
      handleProductsData({ data: assetsData, pagination });
    }
  }, [assetsResponse, perPage]);

  useEffect(() => {
    if (tableData.length && selectedRow && trigger_reset_selected_row) {
      const asset_idx = tableData.findIndex(
        (asset) =>
          asset.id === selectedRow[tablePagination.pageIndex].original.id
      );
      const row: Row<Assets> = {
        id: asset_idx,
        original: tableData[asset_idx],
      } as unknown as Row<Assets>;
      setDefaultSelectedGridItem(asset_idx === -1 ? [] : [row]);
      setDefaultSelectedRow({
        [tablePagination.pageIndex]: asset_idx === -1 ? [] : [row],
      });
      resetSelectedRowsRef?.current?.resetIsDefaultSelected();
    }
    return () => {
      set_trigger_reset_selected_row(false);
    };
  }, [
    selectedRow,
    tableData,
    tablePagination.pageIndex,
    trigger_reset_selected_row,
  ]);

  useEffect(() => {
    if (resetSelectedRowsRef?.current) {
      setTableRef(resetSelectedRowsRef.current);
    }
  }, []);

  useEffect(() => {
    const on_trigger = async () => {
      await trigger(`${attribute.name}.size`);
    };
    if (watchSize?.value) {
      on_trigger();
    }
  }, [attribute.name, trigger, watchSize]);

  useEffect(() => {
    if (existing_asset && tableData && !is_default_row_selected) {
      const asset_idx = tableData.findIndex(
        (asset) => asset.id === existing_asset.id
      );
      if (asset_idx !== -1) {
        const row: Row<Assets> = {
          id: asset_idx,
          original: existing_asset,
        } as unknown as Row<Assets>;
        setSelectedRow({ [tablePagination.pageIndex]: row });
        setDefaultSelectedRow({
          [tablePagination.pageIndex]: [row],
        });
        setDefaultSelectedGridItem([row]);
        set_is_default_row_selected(true);
      }
    }
  }, [
    existing_asset,
    is_default_row_selected,
    tableData,
    tablePagination.pageIndex,
  ]);

  const numOfAssets = tableData.length ?? 0;
  const has_selected_assets = !!selectedRow;
  const size_errors = errors?.[`${attribute.name}`]?.["size"];

  return (
    <>
      <div style={{ margin: "24px 0px 16px" }}>
        <H6>{t("Choose assets")}</H6>
      </div>
      <SearchWrapper>
        <SearchInput
          placeholder={t("Search by name")}
          name="asset_name"
          onChange={handleSearch}
          value={searchQuery}
        />
        {searchQuery ? (
          <ClearInput onClick={handleClearSearch}>
            <XIcon width={20} height={20} />
          </ClearInput>
        ) : (
          <SearchIcon />
        )}
      </SearchWrapper>
      <TableContainer>
        <FlexWrapper style={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <InnerTitleText>
              {t("Showing {{numOfAssets}} assets", { numOfAssets })}
            </InnerTitleText>
            {selectedRow && Object.keys(selectedRow).length > 0 && (
              <TextButton
                onClick={() => {
                  resetSelectedRowsRef?.current
                    ? resetSelectedRowsRef?.current.resetSelectedRows()
                    : tableRef?.["resetSelectedRows"]();
                  setDefaultSelectedGridItem([]);
                }}
              >
                <SoftHeader2>{t("Unselect")}</SoftHeader2>
              </TextButton>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <FilterDropdownContainer>
              <DropDown
                items={[t("List") as string, t("Grid") as string]}
                activeItem={viewType === "list" ? t("List") : t("Grid")}
                textLeft={t("View") + ":"}
                direction={"left"}
                className={"view_type"}
                clickHandler={changeViewType}
              />
            </FilterDropdownContainer>
            {!categoryTypeError && (
              <FilterDropdownContainer>
                <DropDown
                  items={categoryTypes}
                  activeItem={categoryType}
                  textLeft={t("Filter") + ":"}
                  direction={"left"}
                  className={"category_type"}
                  clickHandler={changeFilterType}
                ></DropDown>
              </FilterDropdownContainer>
            )}
          </div>
        </FlexWrapper>
        <ScrollArea>
          {viewType === "list" ? (
            <Table
              columns={tableColumns}
              data={tableData}
              isLoading={isLoading}
              error={assetsError}
              handleSelectRows={handleSelectRows}
              defaultSelectedRows={defaultSelectedRow}
              name="existing_image_attributes_table"
              rowSelectionType="radio"
              pageIndex={tablePagination.pageIndex}
              ref={resetSelectedRowsRef}
            />
          ) : (
            <GridAssetView
              assets={
                assetsResponse?.data
                  ? assetsResponse.data.map((asset) => ({
                      ...asset,
                      can_edit: false,
                      can_change_visibility: false,
                      can_delete: false,
                      can_external_download: true,
                      can_view_details: true,
                    }))
                  : []
              }
              on_download_asset={(asset) => {
                window.open(asset.signed_url, "_blank");
              }}
              handleSelectedItems={handleGridSelectedItems}
              selectedItems={defaultSelectedGridItem}
              select_type="radio"
              is_existing_asset_page={true}
            />
          )}
        </ScrollArea>
        <Pagination
          miniPagination={true}
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </TableContainer>
      {existing_asset && (
        <CancelButton
          style={{ padding: "17px 0 0" }}
          onClick={() => {
            set_show_delete_modal(true);
          }}
        >
          {t("Delete")}
        </CancelButton>
      )}
      <FormWrapper style={{ marginTop: "24px" }}>
        <TextField
          name={`${attribute.name}.caption`}
          label={t("Caption")}
          autoComplete={"caption"}
          formState={formState}
          theref={register({
            required: false,
          })}
          errors={errors}
          defaultValue={defaultValues.caption}
          type="text"
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name={`${attribute.name}.size`}
          autoComplete={"image_size"}
          defaultValue={defaultValues.size ?? { label: "", value: undefined }}
          placeholder={t("Size")}
          options={sizeOptions}
          errors={errors}
          error={size_errors}
          formState={{ submitCount: 1 }}
          rules={{
            required: true,
            validate: (
              val: OptionType<
                ImageAttributeValue["image_dimensions"] | undefined
              >
            ) => (val?.value ? true : strings(t).thisIsARequiredField),
          }}
        />
        <input
          type="hidden"
          name={`${attribute.name}.asset_id`}
          ref={register}
          defaultValue={defaultValues.asset_id}
        />
        <div style={{ display: "flex", gap: "16px" }}>
          <PrimaryCancelButton
            style={{ flex: 1 }}
            onClick={onCancel}
            type="reset"
          >
            {t("Cancel")}
          </PrimaryCancelButton>
          <PrimaryButtonMedium
            style={{ flex: 1 }}
            disabled={!has_selected_assets}
            datafor={has_selected_assets ? "" : "save-button-tooltip"}
            datatip={
              has_selected_assets
                ? ""
                : t("Please select an asset and continue")
            }
            type="button"
            onClick={() => {
              onSubmit();
            }}
          >
            {t("Save")}
          </PrimaryButtonMedium>
          {!has_selected_assets && (
            <ReactTooltip id="save-button-tooltip" effect="solid" />
          )}
        </div>
      </FormWrapper>
      <ConfirmDialog
        show={show_delete_modal}
        closeDialog={on_close_delete_modal}
        confirmMessage={t(
          "Are you sure you want to delete this image attribute value?"
        )}
        handleConfirm={onDeleteImageAttribute}
        handleCancel={on_close_delete_modal}
      />
    </>
  );
};
