import { useTranslation } from "react-i18next";
import type {
  Language,
  OptionType,
  SupportedLanguage,
} from "../../../../../../types/types";
import { useEffect, useMemo, useState } from "react";
import type {
  Assets,
  AttributeSchema,
  ImageAttributeValue,
  SupportedAssetCategoryType,
} from "../../../../../../types/types.PIM";
import { useNotifications } from "../../../../../../components/Notifications/NotificationsContext";
import { useStoreState } from "../../../../../../util/util";
import useSWR from "swr";
import type { AssetCategory } from "../../SellarAdminPIMAssets/util/AssetsUtil";
import { endpoints } from "../../../../../../endpoints";
import { ErrorPlaceholder } from "../../../../../../components/Error";
import type { AxiosError } from "axios";
import axios from "axios";
import { H6 } from "../../../../../../components/Typography/Typography";
import type { FormState } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import { SelectBoxV2 } from "../../../../../../components/SelectBoxV2/SelectBoxV2";
import { FileUploader } from "../../SellarAdminPIMAssets/util/FileUploader";
import { TextField } from "../../../../../../components/TextFields/TextFields";
import { ConfirmOrCancelButtonContainer } from "../../../../../../components/Buttons/Buttons";
import styled from "styled-components/macro";
import { strings } from "../../../../../../util/strings";

const FormWrapper = styled.div`
  > * {
    margin-bottom: 15px;
  }
`;

type FormValues = {
  asset_name: string;
  file: File;
  asset_type: OptionType<SupportedAssetCategoryType>;
  category_type: OptionType<string>;
  language: OptionType<SupportedLanguage>;
  caption?: string;
  size: OptionType<ImageAttributeValue["image_dimensions"]>;
};

export const NewImageAttributeForm = ({
  attribute,
  onComplete,
  onCancel,
}: {
  attribute: AttributeSchema;
  onComplete?: (image_attr: ImageAttributeValue) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);
  const [assetCategories, setAssetCategories] = useState<
    OptionType<SupportedAssetCategoryType>[]
  >([]);

  const [assetLanguages, setAssetLanguages] = useState<
    OptionType<SupportedLanguage>[]
  >([]);

  const { notifyError } = useNotifications();
  const { tenant_id, storefront_id } = useStoreState();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const defaultValues = useMemo(
    () => ({
      asset_name: "",
      asset_type: {
        label: t("Image"),
        value: "image",
      },
      category_type: { label: undefined, value: undefined },
      language:
        assetLanguages.length === 1
          ? assetLanguages[0]
          : { label: undefined, value: undefined },

      file: undefined,
      caption: undefined,
      size: { label: undefined, value: undefined },
    }),
    [assetLanguages, t]
  );

  const { control, errors, getValues, register, setValue, trigger, watch } =
    useFormContext();

  const { error: categoryTypeError } = useSWR<AssetCategory>(
    `${endpoints.v2_tenants_id_pim_assets_categories(
      tenant_id
    )}?asset_type=image`,
    {
      onSuccess: ({ asset_categories }) =>
        setAssetCategories(
          asset_categories.map((category) => ({
            label: category.name,
            value: category.id,
          }))
        ),
    }
  );

  useSWR<{
    languages: Language[];
  }>(endpoints.v1_storefronts_id_languages(storefront_id), {
    onSuccess: (languages) => {
      setAssetLanguages(
        languages.languages.map((item) => ({
          label: `${item.name} (${item.alpha_2.toUpperCase()})`,
          value: item.alpha_2,
        }))
      );
    },
    onError: () =>
      notifyError(t("could not load asset languages, Something went wrong.")),
  });

  const onSubmit = async (): Promise<void> => {
    const name = (name: string) => `${attribute.name}.new.${name}`;
    const result = await trigger([
      name("asset_name"),
      name("asset_type"),
      name("caption"),
      name("category_type"),
      name("file"),
      name("language"),
      name("size"),
    ]);
    if (!result) {
      return;
    }
    setSubmitting(true);
    const values = getValues();
    const {
      asset_name,
      asset_type,
      category_type,
      language,
      caption,
      size,
      file,
    } = values[`${attribute.name}`]?.["new"] as FormValues;
    const formData = new FormData();
    formData.append("file", file);

    const baseURL = endpoints.v2_storefronts_id_pim_assets(tenant_id);

    setSubmitting(true);

    const params = new URLSearchParams();
    params.append("name", asset_name);
    params.append("asset_type", asset_type.value);
    params.append("category_id", category_type.value);
    params.append("language", language.value);
    params.append("is_downloadable", "true");

    try {
      const { data: asset } = await axios.post<Assets>(
        `${baseURL}?${params}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setSubmitting(false);
      if (onComplete) {
        onComplete({
          caption,
          image_dimensions: size.value,
          asset_id: asset.id,
        });
      }
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not add image asset. Something went wrong."),
        {
          error,
        }
      );
      setSubmitting(false);
    }
  };

  const sizeOptions = useMemo<
    OptionType<ImageAttributeValue["image_dimensions"]>[]
  >(
    () => [
      { label: t("Small (Thumbnail)"), value: "small" },
      { label: t("Medium (Half-width)"), value: "medium" },
      { label: t("Large (Full-width)"), value: "large" },
    ],
    [t]
  );

  const formState = { submitCount: 1 };

  const watchCategoryType = watch(
    `${attribute.name}.new.category_type`
  ) as OptionType;
  const watchSelectedFile = watch(`${attribute.name}.new.file`) as File;
  const watchLanguage = watch(`${attribute.name}.new.language`) as OptionType;
  const watchSize = watch(`${attribute.name}.new.size`) as OptionType;
  const watchAssetName = watch(`${attribute.name}.new.asset_name`) as string;

  useEffect(() => {
    if (watchCategoryType?.value) {
      trigger(`${attribute.name}.new.category_type`);
    }
    if (watchSelectedFile) {
      trigger(`${attribute.name}.new.file`);
    }
    if (watchLanguage?.value) {
      trigger(`${attribute.name}.new.language`);
    }
    if (watchSize?.value) {
      trigger(`${attribute.name}.new.size`);
    }
    if (watchAssetName) {
      trigger(`${attribute.name}.new.asset_name`);
    }
  }, [
    attribute.name,
    trigger,
    watchAssetName,
    watchCategoryType?.value,
    watchLanguage?.value,
    watchSelectedFile,
    watchSize?.value,
  ]);

  if (categoryTypeError) {
    return (
      <ErrorPlaceholder
        message={t(
          "There was an error loading asset types and categories. Please try again later"
        )}
      />
    );
  }

  return (
    <>
      <H6 style={{ margin: "24px 0 16px" }}>{t("Asset details")}</H6>
      <FormWrapper>
        <Controller
          as={SelectBoxV2}
          control={control}
          name={`${attribute.name}.new.asset_type`}
          autoComplete={"asset_type"}
          placeholder={t("Asset Type")}
          rules={{
            required: true,
          }}
          isDisabled={true}
          errors={errors}
          formState={formState}
          defaultValue={defaultValues.asset_type}
          options={[{ label: t("Image"), value: "image" }]}
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name={`${attribute.name}.new.category_type`}
          autoComplete={"input_category_type"}
          placeholder={t("Category")}
          options={assetCategories}
          defaultValue={defaultValues.category_type}
          rules={{
            required: true,
            validate: (val) =>
              val.value !== undefined ? true : strings(t).thisIsARequiredField,
          }}
          errors={errors}
          error={errors?.[`${attribute.name}`]?.["new"]?.["category_type"]}
          formState={formState}
        />
        <FileUploader
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setValue={setValue}
          selectedAssetType={"image"}
          control={control}
          errors={errors}
          formState={formState as FormState<FormValues>}
          form_asset_name={`${attribute.name}.new.asset_name`}
          form_file_name={`${attribute.name}.new.file`}
        />
        <TextField
          name={`${attribute.name}.new.asset_name`}
          label={t("Asset Name")}
          autoComplete={"asset_name"}
          theref={register({
            required: true,
            validate: (val) =>
              val.trim().length > 0 ? true : strings(t).thisIsARequiredField,
          })}
          defaultValue={defaultValues.asset_name}
          formState={formState as FormState<FormValues>}
          errors={errors}
          error={errors?.[`${attribute.name}`]?.["new"]?.["asset_name"]}
          type="text"
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name={`${attribute.name}.new.language`}
          autoComplete={"language"}
          placeholder={t("Language")}
          defaultValue={defaultValues.language}
          options={assetLanguages}
          rules={{
            required: true,
            validate: (val) =>
              val.value !== undefined ? true : strings(t).thisIsARequiredField,
          }}
          errors={errors}
          error={errors?.[`${attribute.name}`]?.["new"]?.["language"]}
          formState={formState}
        />
        <TextField
          name={`${attribute.name}.new.caption`}
          label={t("Caption")}
          autoComplete={"caption"}
          theref={register({
            required: false,
          })}
          formState={formState as FormState<FormValues>}
          errors={errors}
          type="text"
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name={`${attribute.name}.new.size`}
          autoComplete={"size"}
          placeholder={t("Size")}
          defaultValue={defaultValues.size}
          options={sizeOptions}
          rules={{
            required: true,
            validate: (val) =>
              val.value !== undefined ? true : strings(t).thisIsARequiredField,
          }}
          errors={errors}
          error={errors?.[`${attribute.name}`]?.["new"]?.["size"]}
          formState={formState}
        />
        <ConfirmOrCancelButtonContainer
          confirmText={t("Save")}
          isConfirmLoading={submitting}
          onCancel={onCancel}
          onConfirm={onSubmit}
        />
      </FormWrapper>
    </>
  );
};
