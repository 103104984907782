import type { Dispatch } from "react";
import { useMemo, useState } from "react";
import type {
  OptionType,
  ProductUploadSchema,
} from "../../../../../../types/types";
import type {
  UploadAction,
  UploadState,
} from "./createProductFromUploads.util";
import {
  DownloadTemplateContainer,
  RegularTextBlock,
  TemplateButtonContainer,
} from "./createProductFromUploads.util";
import {
  H3,
  H6Bold,
  RegularParagraph,
} from "../../../../../../components/Typography/Typography";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../../../layout/FormLayout";
import { useForm } from "react-hook-form";
import { FilePickerUncontrolled } from "../../../../../../components/FilePicker/FilePickerUncontrolled";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  PrimaryButtonMedium,
  SecondaryButtonMedium,
} from "../../../../../../components/Buttons/Buttons";
import { useStoreState } from "../../../../../../util/util";
import type { AxiosError } from "axios";
import axios from "axios";
import { endpoints } from "../../../../../../endpoints";
import { useNotifications } from "../../../../../../components/Notifications/NotificationsContext";
import type { UploadNotification } from "../../../../../../types/types.PIM";
import { useLocation } from "react-router-dom";
import { useRoutePath } from "../../../../../../util/Routing";
import { UploadPopups } from "./UploadPopups";
import ReactTooltip from "react-tooltip";

const specialChar = "___";

const file_date = (() => {
  const date = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }); // MM/DD/YYYY
  const date_tuple = date.split("/"); // [MM, DD, YYYY]
  return `${date_tuple[2]}-${date_tuple[0]}-${date_tuple[1]}`;
})();

const replaceDotsWithSpecialCharacter = (name: string) => {
  return name.replace(/\./g, specialChar);
};

const revertSpecialCharacterReplace = (name: string) => {
  return name.replace(new RegExp(specialChar, "g"), ".");
};

export const UploadTemplateFiles = ({
  template,
  dispatch,
  collectionFileNames,
}: {
  template: OptionType<string>;
  dispatch: Dispatch<UploadAction>;
  collectionFileNames: Record<string, string>;
  export_id?: string;
}) => {
  const { t } = useTranslation();

  return (
    <DownloadTemplateContainer>
      <H3 style={{ margin: "0 0 4px" }}>{t("Upload Product Data")}</H3>
      <RegularParagraph style={{ margin: "0 0 32px" }}>
        {t(
          "Once you have updated your product data, upload the CSV files for Groups & Collections to create & update your products."
        )}
      </RegularParagraph>
      <UploadTemplateCSVFiles
        template={template}
        dispatch={dispatch}
        collectionFileNames={collectionFileNames}
      />
    </DownloadTemplateContainer>
  );
};

const UploadTemplateCSVFiles = ({
  template,
  dispatch,
  collectionFileNames,
}: {
  template: OptionType<string>;
  dispatch: Dispatch<UploadAction>;
  collectionFileNames: Record<string, string>;
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [uploadId, setUploadId] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] =
    useState<UploadNotification["status"]>("in_progress");
  const { t } = useTranslation();
  const { notifySuccess, notifyError, pollNotification } = useNotifications();
  const { tenant_id } = useStoreState();
  const { adminPath } = useRoutePath();
  const location = useLocation();

  const isInUploadPage =
    location.pathname === `${adminPath}/pim/products/new` &&
    location.search.includes("tab=2");

  const groupFileName = useMemo(
    () =>
      replaceDotsWithSpecialCharacter(
        `${template.label.toLowerCase()}_groups_${file_date}`
      ),
    [template.label]
  );

  const UploadSchema = useMemo(() => {
    const objectValues = Object.keys(collectionFileNames).reduce(
      (acc, collectionName) => {
        acc[replaceDotsWithSpecialCharacter(collectionName)] = z
          .instanceof(File)
          .nullable()
          .optional();
        return acc;
      },
      { [groupFileName]: z.instanceof(File).nullable().optional() }
    );
    return z.object(objectValues).superRefine((formValue, ctx) => {
      Object.entries(formValue).forEach(([expectedFileName, file]) => {
        if (
          file &&
          `${revertSpecialCharacterReplace(expectedFileName)}.csv` !== file.name
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t(
              "Uploaded file name is different from expected file name, {{expectedFileName}}",
              {
                expectedFileName: `${revertSpecialCharacterReplace(
                  expectedFileName
                )}.csv`,
              }
            ),
            path: [expectedFileName],
          });
        }
      });
    });
  }, [collectionFileNames, groupFileName, t]);

  type FormInputs = z.infer<typeof UploadSchema>;

  const methodsOfUseForm = useForm({
    resolver: zodResolver(UploadSchema),
  });
  const { handleSubmit } = methodsOfUseForm;

  const getUploadNotificationMessage = (
    status: UploadNotification["status"],
    uploadId: string
  ) => {
    switch (status) {
      case "successful":
        return t(
          "Your files have been processed successfully, and your products have been [created / updated]. You will receive an email confirmation shortly."
        );
      case "failed":
        return t(
          "There was an error processing your files and your product data could not be uploaded. Download the file corresponding to Upload ID {{uploadId}} on the next page.",
          { uploadId }
        );
      case "in_progress":
        return t(
          "Your files are currently being uploaded and you will receive an email notification once your data has been processed. You may exit the page and return once your files have been processed."
        );
      default:
        return "";
    }
  };

  const getPollFn = (uploadId: string) => {
    const pollFn = async () => {
      const {
        data: { status, upload_number, number_of_product },
      } = await axios.get<UploadNotification>(
        endpoints.v2_tenants_tenant_id_product_uploads_upload_id_notifications(
          tenant_id,
          uploadId
        )
      );
      const message = getUploadNotificationMessage(status, upload_number);
      return {
        shouldPoll: status === "in_progress",
        status,
        message,
        uploadId: upload_number,
        numberOfNewProducts: number_of_product,
      };
    };
    return pollFn;
  };

  const onCompletePolling = ({
    status,
    uploadId,
    message,
    numberOfNewProducts,
  }: {
    status: UploadNotification["status"];
    message: string;
    uploadId: string;
    numberOfNewProducts?: number;
  }) => {
    if (isInUploadPage) {
      setStatus(status);
      setDialogMessage(message);
      setUploadId(uploadId);
      setShowDialog(true);
    } else {
      switch (status) {
        case "failed":
          return notifyError(
            t("Upload #{{uploadId}}: Upload failed", { uploadId })
          );
        case "successful":
          return notifySuccess(
            t(
              "Upload #{{uploadId}}: {{numberOfNewProducts}} products were created",
              { uploadId, numberOfNewProducts }
            )
          );
      }
    }
  };

  const onSubmit = async (value: FormInputs) => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      Object.entries(value).forEach(([fileName, file]) => {
        if (file && file.name) {
          formData.append(revertSpecialCharacterReplace(fileName), file);
        }
      });
      const { data: uploads } = await axios.post<ProductUploadSchema[]>(
        endpoints.v2_tenants_tenant_id_pim_products_upload(tenant_id),
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (uploads.length > 0) {
        const uploadId = uploads[0].upload_number;
        setStatus("in_progress");
        setUploadId(uploadId);
        setDialogMessage(getUploadNotificationMessage("in_progress", uploadId));
        setShowDialog(true);
        pollNotification({
          pollFn: getPollFn(uploadId),
          onComplete: onCompletePolling,
        });
      }
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t(
              "There was an error submitting your uploads. Please try again later."
            ),
        {
          error,
        }
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  const onComplete = () => {
    dispatch({ type: "complete", payload: {} as UploadState });
  };

  const formHasSelectedFiles = () => {
    const values = Object.values(methodsOfUseForm.getValues()).filter(
      (val) => val !== undefined && val !== null
    );
    return values.length > 0;
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <H6Bold style={{ margin: 0 }}>{t("Groups")}</H6Bold>
        <RegularTextBlock style={{ marginBottom: "16px" }}>
          {t("This file is required to create product profiles")}
        </RegularTextBlock>
        <FilePickerUncontrolled
          methodsOfUseForm={methodsOfUseForm}
          placeHolderText={t("Drag and drop {{csv_file}}, OR", {
            csv_file: `${groupFileName}.csv`,
          })}
          name={groupFileName}
          accept={".csv"}
          required={false}
        />
        {Object.keys(collectionFileNames).length > 0 && (
          <>
            <H6Bold style={{ margin: 0 }}>{t("Collections")}</H6Bold>
            <RegularTextBlock style={{ marginBottom: "16px" }}>
              {t(
                "These files are used to update existing products with collection data"
              )}
            </RegularTextBlock>
          </>
        )}
        {Object.keys(collectionFileNames).map((collectionName) => (
          <div key={collectionName} style={{ marginBottom: "24px" }}>
            <RegularTextBlock>{`${collectionName}.csv`}</RegularTextBlock>
            <FilePickerUncontrolled
              methodsOfUseForm={methodsOfUseForm}
              placeHolderText={t("Drag and drop {{csv_file}}, OR", {
                csv_file: `${collectionName}.csv`,
              })}
              name={replaceDotsWithSpecialCharacter(collectionName)}
              accept={".csv"}
              required={false}
            />
          </div>
        ))}
        <TemplateButtonContainer>
          <SecondaryButtonMedium
            onClick={() =>
              dispatch({
                type: "move_back",
                payload: {
                  uploadStage: "upload",
                } as UploadState,
              })
            }
          >
            {t("Back")}
          </SecondaryButtonMedium>
          <PrimaryButtonMedium
            loading={isSubmitting}
            type="submit"
            disabled={!formHasSelectedFiles()}
            datafor="upload-tip"
            datatip={
              formHasSelectedFiles()
                ? ""
                : t("Select at least one file to upload")
            }
          >
            {t("Upload")}
          </PrimaryButtonMedium>
          <ReactTooltip id="upload-tip" />
        </TemplateButtonContainer>
      </Form>
      <UploadPopups
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        onComplete={onComplete}
        message={dialogMessage}
        status={status}
        uploadId={uploadId ?? ""}
      />
    </>
  );
};
