import styled from "styled-components/macro";
import {
  Card,
  ContentWrapper,
  FullWidthHorizontalSeparator,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../../layout/portalPageLayout";
import {
  CancelButton,
  EditButton,
  GoBackButton,
} from "../../../../components/Buttons/Buttons";
import {
  providePrivatePageProps,
  useRoutePath,
} from "../../../../util/Routing";
import { useContext, useState } from "react";
import { Auth } from "../../../../components/Auth";
import {
  fetcher,
  formatDateTime,
  makeUrlWithParams,
  useStoreState,
} from "../../../../util/util";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import useSWR from "swr";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import type { AxiosError } from "axios";
import Axios from "axios";
import {
  ClickableLink,
  H3,
  InnerTitleText,
  SubheaderContentText,
} from "../../../../components/Typography/Typography";
import { InfoBlockExtraSmall } from "../../../../components/InfoBlocks/InfoBlocks";
import { formateInternationalPhoneNumber } from "../../../../util/phone";
import { Modal } from "../../../../components/Modal/Modal";
import {
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
  SubheaderInformationContainer,
} from "../../../../components/Layout/Layout";
import { SampleRequestStatus } from "../../../../components/SampleRequestStatus/SampleRequestStatus";
import { useTranslation } from "react-i18next";
import { ErrorPlaceholder } from "../../../../components/Error";
import { DelayedSpinner } from "../../../../components/DelayedSpinner/DelayedSpinner";
import { IDDesktop } from "../../../../components/IDs/IDs";
import { WideDetails } from "../../../../layout/shared/DetailPageLayout/DetailPageLayout";
import type {
  LeadRejectionArgsSchema,
  LocationState,
  SampleRequestStageTwo,
  Tenant,
  UserTypeChip,
} from "../../../../types/types";
import { MultiLineBlock } from "../../../../components/LocationsList/LocationsList";
import { SampleRequestItem } from "../../../../components/TransactionItem/SampleRequestItem";
import { CreateNewTenantFormContainer } from "../../../../components/CreateNewTenantForm/CreateNewTenantFormContainer";
import { NumberParam, useQueryParams } from "use-query-params";
import { WarningMessageBox } from "../../../../components/Form/Form";
import { WarningIcon } from "../../../../components/Icons/Icons";
import { CreateNewUser } from "../../../../components/CreateNewUser/CreateNewUser";
import { endpoints } from "../../../../endpoints";
import { ContactInfoBlockSmall } from "../../../../components/ContactInfoBlockSmall/ContactInfoBlockSmall";
import { LeadNotes } from "../../../SharedPages/LeadsPage/LeadNotes";
import type { HandleLeadNoteSubmitProps } from "../../../SharedPages/LeadsPage/LeadNotes";
import { LeadRejectionCard } from "../../../SharedPages/LeadsPage/LeadRejectionCard";
import { LeadRejectionModal } from "../../../SharedPages/LeadsPage/LeadRejectionModal";
import theme from "../../../../theme";
import { SlideOut } from "../../../../components/SlideOut/SlideOut";
import { AssignLeadToUser } from "../../../SharedPages/LeadsPage/AssignLeadToUser";
import { RightButtonContainer } from "../../../SharedPages/LeadsPage/QuoteRequestLeadDetailPage/QuoteRequestLeadDetailPage";
import { DropDownButton } from "../../../../components/DropDownButton/DropDownButton";
import ReactTooltip from "react-tooltip";

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NameAndIcon = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 2px;
`;

/**
 * This is for sample requests from guest users (new leads).
 */
export const SellerAdminSampleRequestLeadDetail = providePrivatePageProps(
  ({ user }) => {
    // The role can be seller admin or seller standard (for now).
    const { roleIsSomeKindOfSeller } = useContext(Auth);
    const { storefront_id } = useStoreState();

    const { sample_request_id } = useParams<{ sample_request_id: string }>();
    const {
      data: sampleRequest,
      error: sampleRequestError,
      mutate: mutateLead,
    } = useSWR<SampleRequestStageTwo, AxiosError>(
      `/v1/storefronts/${storefront_id}/sample-requests/${sample_request_id}`,
      fetcher
    );
    const history = useHistory();
    const location = useLocation();
    const [query] = useQueryParams({
      offset: NumberParam,
      perPage: NumberParam,
    });

    const { data: convertedToUser } = useSWR<Tenant>(
      sampleRequest?.status === "Added as User"
        ? endpoints.v1_storefronts_id_tenants_id(
            storefront_id,
            sampleRequest.parent_customer_id
          )
        : null
    );
    const { adminPath } = useRoutePath();
    const { notifySuccess, notifyError } = useContext(Notifications);
    const { t } = useTranslation();

    const [tenantTypeToBeCreated, setTenantTypeToBeCreated] =
      useState<"Buyer" | "Distributor">("Buyer");

    const [showAddToCustomerModal, setShowAddToCustomerModal] = useState(false);
    const [showCreateCustomerModal, setShowCreateCustomerModal] =
      useState(false);
    const closeCreateCustomerModal = () => setShowCreateCustomerModal(false);
    const openCreateCustomerModal = () => setShowCreateCustomerModal(true);

    const openAddUserToCustomerModal = () => setShowAddToCustomerModal(true);
    const closeAddUserToCustomerModal = () => setShowAddToCustomerModal(false);

    const [showLeadRejectionModal, setShowLeadRejectionModal] = useState(false);
    const closeLeadRejectionModal = () => setShowLeadRejectionModal(false);
    const openLeadRejectionModal = () => setShowLeadRejectionModal(true);

    const [showLeadAssignmentModal, setShowLeadAssignmentModal] =
      useState(false);
    const closeLeadAssignmentModal = () => setShowLeadAssignmentModal(false);
    const openLeadAssignmentModal = () => setShowLeadAssignmentModal(true);

    const onConvertToCustomer = (tenantId: string) => {
      closeCreateCustomerModal();
      history.push(
        `${adminPath}/${
          tenantTypeToBeCreated === "Buyer" ? "tenants" : "distributors"
        }/${tenantId}`
      );
    };

    const onLeadNoteSubmit = async ({
      note,
      onSuccess,
    }: HandleLeadNoteSubmitProps) => {
      try {
        await Axios.post(
          `/v1/storefronts/${storefront_id}/leads/${sample_request_id}/notes`,
          {
            type: "sample_request",
            message: note,
          }
        );
        onSuccess();
        mutateLead();
        notifySuccess(t("note added successfully"));
      } catch (error) {
        notifyError(t("There was an error adding the note"));
      }
    };

    const handleRejectSampleRequest = async (
      messages: LeadRejectionArgsSchema
    ) => {
      try {
        const response = await Axios.patch(
          `/v1/storefronts/${storefront_id}/sample-requests/${sample_request_id}/reject`,
          messages
        );
        if (response.status === 201) {
          notifySuccess(t("Lead rejected successfully"));
          mutateLead();
          closeLeadRejectionModal();
        }
      } catch (error) {
        notifyError(
          "There was an error rejecting the quote, please try again.",
          {
            error,
          }
        );
      }
    };

    const isLoadingSampleRequest = !sampleRequest && !sampleRequestError;

    const maxEmailLength = 38;
    const isEmailTooLong =
      sampleRequest && sampleRequest.buyer_email.length > maxEmailLength;

    if (sampleRequestError) {
      return (
        // TODO error.response.data.message?
        <ErrorPlaceholder
          message={t("There was an error loading the sample request")}
        />
      );
    }

    if (isLoadingSampleRequest) {
      return <DelayedSpinner />;
    }

    const onLeadAssignmentSuccess = (state: "unassigned" | "assigned") => {
      state === "assigned"
        ? notifySuccess(t("Lead assigned successfully"))
        : notifySuccess(t("Lead unassigned successfully"));
      closeLeadAssignmentModal();
      mutateLead();
    };

    const onLeadAssignmentFailure = (error: unknown) => {
      notifyError(t("There was an error assigning the lead"), { error });
    };

    const leadAssignmentUrl = `/v2/storefronts/${storefront_id}/sample-requests/${sample_request_id}/assign`;
    const leadAsigneeName = `${sampleRequest?.assignee?.firstname} ${sampleRequest?.assignee?.lastname}`;

    const getBackLinkState = () => {
      const listState = (location.state as LocationState)?.listState;
      return listState ? { listState } : undefined;
    };

    const getBackLinkParams = () => {
      const { tabIndex, offset, perPage } =
        (location.state as LocationState)?.listState || {};
      return {
        tab: tabIndex !== undefined ? tabIndex : undefined,
        offset: offset !== undefined ? offset : undefined,
        perPage: perPage !== undefined ? perPage : undefined,
      };
    };

    const handleBackClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const state = getBackLinkState();
      const params = getBackLinkParams();
      const search = makeUrlWithParams("", params);
      history.push({
        pathname: `${adminPath}/leads`,
        search,
        state,
      });
    };
    const userTypeChips: UserTypeChip[] =
      tenantTypeToBeCreated === "Buyer"
        ? [{ name: t("Admin"), id: "Buyer Admin" }]
        : [{ name: t("Distributor"), id: "Distributor Admin" }];

    // Ensures that sample request has valid data
    if (sampleRequest?.buyer_address) {
      return (
        <PageWrapper style={{ maxWidth: "932px" }}>
          <Link
            to={makeUrlWithParams(`${adminPath}/leads`, {
              offset: query.offset,
              perPage: query.perPage,
            })}
            onClick={handleBackClick}
          >
            <GoBackButton text={t("Leads")}></GoBackButton>
          </Link>
          <SlideOut
            show={showLeadAssignmentModal}
            closeFlyout={closeLeadAssignmentModal}
          >
            <AssignLeadToUser
              url={leadAssignmentUrl}
              onSuccess={onLeadAssignmentSuccess}
              onError={onLeadAssignmentFailure}
              prefilledUser={sampleRequest?.assignee}
            />
          </SlideOut>

          <>
            <PageHeader>
              <HeaderLeft>
                <PageTitle>{t("Sample Request")}</PageTitle>
                {sampleRequest.number && (
                  <IDDesktop>{sampleRequest.number}</IDDesktop>
                )}
              </HeaderLeft>
              <HeaderRight>
                <SampleRequestStatus
                  position="right"
                  status={sampleRequest.status}
                  id={sampleRequest.id}
                  t={t}
                />
              </HeaderRight>
            </PageHeader>
            <HeaderContainer>
              <SubheaderInformationContainer>
                <InnerTitleText>{t("Assigned to: ")}</InnerTitleText>
                <SubheaderContentText>
                  {sampleRequest.assignee ? (
                    <NameAndIcon>
                      <div>{leadAsigneeName}</div>
                      <div>
                        <EditButton
                          style={{
                            padding: "0",
                            position: "absolute",
                            top: "-7px",
                          }}
                          width={16}
                          height={19}
                          onClick={openLeadAssignmentModal}
                        />
                      </div>
                    </NameAndIcon>
                  ) : (
                    <ClickableLink
                      style={{ color: theme.primaryButtonBorder }}
                      onClick={openLeadAssignmentModal}
                    >
                      {t("Add")}
                    </ClickableLink>
                  )}
                </SubheaderContentText>
              </SubheaderInformationContainer>
              {sampleRequest.assignment_date && sampleRequest.assignee && (
                <SubheaderInformationContainer>
                  <InnerTitleText>{t("Assigned on: ")}</InnerTitleText>
                  <SubheaderContentText>
                    {formatDateTime(sampleRequest.assignment_date)}
                  </SubheaderContentText>
                </SubheaderInformationContainer>
              )}
            </HeaderContainer>
            <FullWidthHorizontalSeparator margin={"20px 0 0"} />
            <ContentWrapper style={{ margin: "45px 0 20px 0" }}>
              <Card>
                <H3>{t("Customer Details")}</H3>
                <WideDetails>
                  <InfoBlockExtraSmall
                    header={t("Requested By")}
                    content={
                      <MultiLineBlock style={{ wordWrap: "break-word" }}>
                        {/* All of the property names are different for every lead.. 
                        Not much point in having 4 different utility functions just to export them and use them once. */}
                        <div>
                          {`${sampleRequest.buyer_first_name} ${sampleRequest.buyer_last_name}`}
                        </div>
                        <div>{sampleRequest.buyer_company_name}</div>
                        <div>
                          {isEmailTooLong ? (
                            <>
                              <span data-tip={sampleRequest.buyer_email}>
                                {sampleRequest.buyer_email.slice(
                                  0,
                                  maxEmailLength
                                )}
                                ...
                              </span>
                              <ReactTooltip effect="solid" />
                            </>
                          ) : (
                            sampleRequest.buyer_email
                          )}
                        </div>

                        <div>
                          <a href={`tel:${sampleRequest.buyer_phone}`}>
                            {formateInternationalPhoneNumber(
                              sampleRequest.buyer_phone
                            )}
                          </a>
                        </div>
                      </MultiLineBlock>
                    }
                  />
                  <div>
                    <InfoBlockExtraSmall
                      header={t("Date Received")}
                      content={formatDateTime(sampleRequest.created_at)}
                    />
                    {sampleRequest.status === "Added as User" &&
                      convertedToUser && (
                        <div style={{ marginTop: "5px" }}>
                          <InfoBlockExtraSmall
                            header={t("User Added to")}
                            content={convertedToUser.name}
                          />
                        </div>
                      )}
                  </div>
                </WideDetails>

                <H3 style={{ marginTop: "0px" }}>
                  {t("Sample Request Details")}
                </H3>
                <WideDetails>
                  <ContactInfoBlockSmall
                    address={sampleRequest.buyer_address}
                    header={t("Ship to")}
                    testid={"shipping-address"}
                  />
                  {sampleRequest.billing_address ? (
                    <ContactInfoBlockSmall
                      address={sampleRequest!.billing_address}
                      header={t("Bill to")}
                      testid={"billing-address"}
                    />
                  ) : (
                    <></>
                  )}
                </WideDetails>
                {sampleRequest.items.map((item, index) => (
                  <SampleRequestItem item={item} index={index} />
                ))}
                <H3 style={{ marginTop: "30px" }}>{t("Other Details")}</H3>
                <WideDetails>
                  <InfoBlockExtraSmall
                    header={t("Decision Maker")}
                    content={
                      sampleRequest.is_decision_maker ? t("Yes") : t("No")
                    }
                  />
                  <InfoBlockExtraSmall
                    header={t("Decision Timeline")}
                    content={sampleRequest.decision_timeline}
                  />
                  <InfoBlockExtraSmall
                    header={t("Requested Documents")}
                    content={
                      <MultiLineBlock>
                        {sampleRequest.requested_documents.map((doc) => (
                          <div>{doc}</div>
                        ))}
                      </MultiLineBlock>
                    }
                  />
                </WideDetails>
                {roleIsSomeKindOfSeller &&
                  (sampleRequest.status === "requested" ||
                    sampleRequest.status === "pending_activation") && (
                    <>
                      {sampleRequest.is_existing_customer && (
                        <WarningMessageBox>
                          <WarningIcon width={18} />
                          {t(
                            `This lead may be a user of an existing customer, {{companyName}}. Click Add to Existing Customer to add this lead as a user to {{companyName}}.`,
                            {
                              companyName: sampleRequest.company_name,
                            }
                          )}
                        </WarningMessageBox>
                      )}
                      <ButtonSection style={{ marginBottom: "32px" }}>
                        <CancelButton
                          style={{ paddingLeft: "0px" }}
                          onClick={openLeadRejectionModal}
                        >
                          {t("Reject This Lead")}
                        </CancelButton>
                        <div>
                          <RightButtonContainer>
                            <DropDownButton
                              items={["Customer", "Distributor"]}
                              textLeft={t("Convert to new")}
                              clickHandler={(item: string) => {
                                if (item === "Customer") {
                                  setTenantTypeToBeCreated("Buyer");
                                  openCreateCustomerModal();
                                } else if (item === "Distributor") {
                                  setTenantTypeToBeCreated("Distributor");
                                  openCreateCustomerModal();
                                }
                              }}
                            />
                            <DropDownButton
                              items={["Customer", "Distributor"]}
                              textLeft={t("Add to existing")}
                              clickHandler={(item: string) => {
                                if (item === "Customer") {
                                  setTenantTypeToBeCreated("Buyer");
                                  openAddUserToCustomerModal();
                                } else if (item === "Distributor") {
                                  setTenantTypeToBeCreated("Distributor");
                                  openAddUserToCustomerModal();
                                }
                              }}
                            />
                          </RightButtonContainer>
                        </div>
                      </ButtonSection>
                    </>
                  )}

                <Modal
                  closeModal={closeAddUserToCustomerModal}
                  show={showAddToCustomerModal}
                >
                  <CreateNewUser
                    tenantId={user.tenant_id}
                    userTypeChips={userTypeChips}
                    defaultUserType={userTypeChips[0].id}
                    tenantTypeToFetch={tenantTypeToBeCreated}
                    leadToUserData={{
                      firstname: sampleRequest.buyer_first_name,
                      lastname: sampleRequest.buyer_last_name,
                      email_address: sampleRequest.buyer_email,
                      phone_number: sampleRequest.buyer_phone,
                      type: "sample_request",
                      lead_id: sampleRequest.id,
                      existing_customer: {
                        company_name: sampleRequest.company_name,
                        customer_id: sampleRequest.customer_id,
                      },
                      address: {
                        address1: sampleRequest.buyer_address.address1,
                        address2: sampleRequest.buyer_address.address2,
                        city: sampleRequest.buyer_address.city,
                        country: sampleRequest.buyer_address.country,
                        postal_code: sampleRequest.buyer_address.postal_code,
                        state: sampleRequest.buyer_address.state,
                      },
                    }}
                    userFormType="add_to_Existing_customer"
                    onSuccess={() => {
                      closeAddUserToCustomerModal();
                    }}
                    showShippingAddress={true}
                  />
                </Modal>
                <Modal
                  closeModal={closeCreateCustomerModal}
                  show={showCreateCustomerModal}
                >
                  <CreateNewTenantFormContainer
                    onSuccess={onConvertToCustomer}
                    lead={sampleRequest}
                    sellerTenantId={user.tenant_id}
                    sampleRequestMode
                    type={tenantTypeToBeCreated}
                  />
                </Modal>
              </Card>
              <LeadRejectionModal
                show={showLeadRejectionModal}
                closeModal={closeLeadRejectionModal}
                onFormSubmit={handleRejectSampleRequest}
                titleString={t(
                  "Are you sure you want to reject this Sample Request?"
                )}
              />
              {sampleRequest.rejection_note && (
                <LeadRejectionCard
                  rejection_note={sampleRequest.rejection_note}
                />
              )}
              <LeadNotes
                internal_notes={sampleRequest.internal_notes}
                handleSubmit={onLeadNoteSubmit}
              />
            </ContentWrapper>
          </>
        </PageWrapper>
      );
    } else return null;
  }
);
