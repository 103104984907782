import styled from "styled-components/macro";
import { Row } from "../../../../../../components/Layout/Layout";
import {
  H3,
  H6Bold,
  SmallSectionHeaderRegular,
} from "../../../../../../components/Typography/Typography";
import { useTranslation } from "react-i18next";
import { useReducer, useState } from "react";
import { SearchSelectInfiniteScroll } from "../../../../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import { endpoints } from "../../../../../../endpoints";
import { useStoreState } from "../../../../../../util/util";
import type { AttributeTemplateSummarySchema } from "../../../../../../types/types.PIM";
import type { OptionType } from "../../../../../../types/types";
import {
  PrimaryButtonSmall,
  PrimaryCancelButton,
  XSmallButtonRow,
} from "../../../../../../components/Buttons/Buttons";
import { InfoIcon } from "../../../../../../components/Icons/Icons";
import { PreviousUploadsTable } from "./PreviousUploadsTable";
import { HelpDialog } from "./HelpDialog";
import type {
  UploadAction,
  UploadStages,
  UploadState,
} from "./createProductFromUploads.util";
import { DownloadTemplateFiles } from "./DownloadTemplateFiles";
import { UploadTemplateFiles } from "./UploadTemplateFiles";
import type { TFunction } from "react-i18next";
import { StringParam, useQueryParams } from "use-query-params";

const ProductUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
`;

const guidelineOverview = (t: TFunction) => [
  t(
    "Each CSV will come preloaded with the attributes and attribute types for the corresponding groups and collections of the selected template. You can download either blank template files, which only contain the attributes & attribute types, or prefilled template files, which will include existing product data."
  ),
  t(
    "The Groups CSV is used to create new product profiles and update existing products, and will be processed first. The Collections CSVs are used for updating existing products with collection data."
  ),
];

const csvGuidelines = (t: TFunction) => [
  t(
    "Attributes in the headers must match the attribute display names on the template - do not modify the header row."
  ),
  t("Do not change the file name of the CSVs."),
  t(
    "All attributes marked with an asterisk (*) in the header row require a value."
  ),
  t(
    "Do not modify any values in any file within the Product ID column & leave Product ID blank when creating a new product."
  ),
  t(
    "Do not modify any values in the UUID column in the Collections CSVs & leave UUID blank when adding a new collection item."
  ),
  t("Product Name is required for each row in any CSV."),
  t(
    "The Groups CSV cannot contain duplicate product names and must only include unique product names."
  ),
  t(
    "Any changes made to existing values will overwrite the product data upon upload."
  ),
  t("Product names in the Collections CSVs must already exist."),
  t(
    "Add existing list values to single / multi-select attributes or create new values. Any values that do not match with the attribute’s chosen list will be created as new values."
  ),
  t("Enter only numbers for numeric attributes."),
  t(`Boolean attributes must be either “Yes” or “No”.`),
  t("Enter dates in the following format: YYYY-MM-DD"),
  t(
    "When adding values for Product Reference attributes, use the Product ID of the product you would like to reference instead of the Product Name"
  ),
  t(
    `Enter links in the following json format: {"url": "https://wikipedia.org/", "display_text": "Wikipedia"}`
  ),
  t("Add a new line at the end of the CSV."),
  t("Do not upload an empty group or collection file."),
];

const getStageAndConfirmButtonState = (
  stage?: UploadStages
): Omit<UploadState, "selectedTemplate" | "collectionFileNames"> => {
  switch (stage) {
    case "download":
      return {
        showConfirmTemplateSelectionButton: true,
        uploadStage: "table",
      };
    case "upload":
      return {
        showConfirmTemplateSelectionButton: false,
        uploadStage: "download",
      };
    default:
      return {
        showConfirmTemplateSelectionButton: false,
        uploadStage: "table",
      };
  }
};

const uploadReducer = (
  uploadState: UploadState,
  {
    type,
    payload: {
      uploadStage,
      selectedTemplate,
      collectionFileNames,
      export_id,
      selected_products,
    },
  }: UploadAction
): UploadState => {
  switch (type) {
    case "select_template": {
      if (selectedTemplate) {
        return {
          ...uploadState,
          selectedTemplate,
          showConfirmTemplateSelectionButton: true,
          uploadStage: "table",
          collectionFileNames: {},
          export_id: undefined,
          selected_products: undefined,
        };
      }
      return uploadState;
    }
    case "comfirm_template": {
      return {
        ...uploadState,
        showConfirmTemplateSelectionButton: false,
        uploadStage: "download",
        collectionFileNames: {},
      };
    }
    case "discard_template": {
      return {
        ...uploadState,
        selectedTemplate: undefined,
        showConfirmTemplateSelectionButton: false,
        collectionFileNames: {},
        export_id: undefined,
        selected_products: undefined,
      };
    }
    case "move_back": {
      return { ...uploadState, ...getStageAndConfirmButtonState(uploadStage) };
    }
    case "set_export_id": {
      return { ...uploadState, export_id };
    }
    case "set_selected_products": {
      return { ...uploadState, selected_products };
    }
    case "continue_from_download": {
      return {
        ...uploadState,
        uploadStage: "upload",
        collectionFileNames: collectionFileNames!,
      };
    }
    case "complete": {
      return {
        ...uploadState,
        selectedTemplate: undefined,
        showConfirmTemplateSelectionButton: false,
        uploadStage: "table",
        collectionFileNames: {},
        export_id: undefined,
        selected_products: undefined,
      };
    }
    default:
      return uploadState;
  }
};

export const CreatePimProductFromUploads = () => {
  const [uploadState, dispatch] = useReducer(uploadReducer, {
    uploadStage: "table",
    showConfirmTemplateSelectionButton: false,
    collectionFileNames: {},
  });
  const [showGuide, setShowGuide] = useState(false);
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const [, setQuery] = useQueryParams({
    q: StringParam,
    template: StringParam,
  });

  const handleTemplateSelected = (data: OptionType<string>) => {
    if (uploadState.selectedTemplate?.value !== data.value) {
      dispatch({
        type: "select_template",
        payload: { ...uploadState, selectedTemplate: data },
      });
      setQuery({ q: undefined, template: data.label });
    }
  };

  const onCancel = () =>
    dispatch({ type: "discard_template", payload: uploadState });

  const onContinue = () =>
    dispatch({ type: "comfirm_template", payload: uploadState });

  const getUploadStagesScreens = () => {
    switch (uploadState.uploadStage) {
      case "table":
        return <PreviousUploadsTable />;
      case "download":
        return (
          <DownloadTemplateFiles
            template={uploadState.selectedTemplate!}
            export_id={uploadState.export_id}
            selected_products={uploadState.selected_products}
            dispatch={dispatch}
          />
        );
      case "upload":
        return (
          <UploadTemplateFiles
            template={uploadState.selectedTemplate!}
            dispatch={dispatch}
            collectionFileNames={uploadState.collectionFileNames}
            export_id={uploadState.export_id}
          />
        );
    }
  };

  return (
    <ProductUploadWrapper>
      <Row style={{ margin: "44px 0" }}>
        <Section style={{ flex: "0.5 0 0" }}>
          <H3 style={{ margin: 0 }}>{t("Upload Products")}</H3>
          <SmallSectionHeaderRegular style={{ margin: "4px 0 0" }}>
            {t("Select a template for adding products")}
          </SmallSectionHeaderRegular>
          <div style={{ marginTop: "16px" }}>
            <SearchSelectInfiniteScroll
              name={"template_search"}
              errors={{}}
              isDisabled={uploadState.uploadStage !== "table"}
              formState={{ submitCount: 0 }}
              placeholder={t("Template name")}
              baseUrl={endpoints.v2_storefronts_id_pim_templates_summary(
                tenant_id
              )}
              params={(() => {
                const params = new URLSearchParams();
                params.append("order_by", "asc");
                return params;
              })()}
              getOptions={(response: AttributeTemplateSummarySchema[]) =>
                response.map(({ template_id, template_name }) => ({
                  label: template_name,
                  value: template_id,
                }))
              }
              onChange={handleTemplateSelected}
              value={uploadState.selectedTemplate ?? { value: "", label: "" }}
              testid={"template-search"}
            />
          </div>

          {uploadState.showConfirmTemplateSelectionButton && (
            <XSmallButtonRow style={{ marginTop: "32px" }}>
              <PrimaryCancelButton onClick={onCancel} type="reset">
                {t("Cancel")}
              </PrimaryCancelButton>
              <PrimaryButtonSmall onClick={onContinue}>
                {t("Continue")}
              </PrimaryButtonSmall>
            </XSmallButtonRow>
          )}
        </Section>
        <Section style={{ alignSelf: "flex-start", gap: "4px" }}>
          <HelpDialog
            show={showGuide}
            setShow={setShowGuide}
            overview={guidelineOverview}
            guidelines={csvGuidelines}
          />
          <SmallSectionHeaderRegular style={{ margin: 0 }}>
            {t("Need help getting started?")}
          </SmallSectionHeaderRegular>
          <div
            onClick={() => setShowGuide(true)}
            style={{
              display: "flex",
              gap: "4px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <InfoIcon />
            <H6Bold>{t("View Guide")}</H6Bold>
          </div>
        </Section>
      </Row>
      {getUploadStagesScreens()}
    </ProductUploadWrapper>
  );
};
