import React, { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components/macro";
import {
  PrimaryButtonFitContainer,
  SecondaryButtonFitContainer,
} from "../Buttons/Buttons";
import { Title } from "../Typography/Typography";
import { StatusBox, WarningMessageBox } from "../Form/Form";
import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import type {
  OptionType,
  Product,
  ProductSKU,
  Tenant,
  IPriceTierPaginatedOutput,
  LastPurchaseResponse,
  PaymentTermPaginatedOutput,
  DeliveryTermPaginatedOutput,
  User,
  IAddress,
  IPaymentTerm,
  IDeliveryTerm,
  CurrencyCode,
  ProductApplication,
  SkuNoPreference,
  UnifiedCartArg,
  IPackagingUnit,
  IPackagingType,
} from "../../types/types";
import { Form } from "../../layout/FormLayout";
import { strings } from "../../util/strings";
import { positiveIntegerRegex } from "../../util/regexes";
import {
  convertProductSKUToOption,
  addressToOption,
  makeUrlWithParams,
  formatLastPurchasePrice,
  convertPaymentTermToOption,
  useCurrencySymbol,
  convertDeliveryTermToOption,
  useFormWrapper,
  makeDeliveryTermsGetEndpoint,
  makePaymentTermsGetEndpoint,
  isAxiosError,
  convertApplicationToOption,
  markDefaultTerm,
} from "../../util/util";
import { Notifications } from "../Notifications/NotificationsContext";
import { HeaderSection } from "../QuoteAndOrderFormShared/QuoteAndOrderFormShared";
import type { AxiosError } from "axios";
import useSWR from "swr";
import { useNotifyErrorOnce } from "../Notifications/useNotifyErrorOnce";
import { endpoints } from "../../endpoints";
import { PriceTiersTable } from "../PriceTiersTable/PriceTiersTable";
import {
  calculatePriceTiersPricePerUom,
  calculatePriceForQuantity,
} from "../../util/QuotesAndOrders";
import { formatPrice } from "../../util/util-components";
import { KeyValueDisplay } from "../KeyValueDisplay";
import { useRoutePath } from "../../util/Routing";
import { useHistory, useLocation } from "react-router-dom";
import { Store } from "../../Store";
import {
  QuantityFormPartProductSku,
  QuantityFormPartNoPackaging,
  QuantityFormPartCustomSku,
  customPackagingOption,
  noPackagingOption,
} from "./BuyerQuoteItemFormParts";
import {
  packagingTypeOrUnitToOption,
  useStorefrontPackagingTypes,
  usePackagingUnits,
  useProductSkuListPrice,
} from "../../util/SkuUtils";
import {
  productApplicationSchema,
  ProductApplicationSelect,
} from "../ProductApplicationSelect/ProductApplicationSelect";
import { useQuantityInputFieldClearer } from "./useQuantityInputFieldClearer";
import { useTranslation } from "react-i18next";
import { WarningIcon } from "../Icons/Icons";
import { newShippingAddress } from "./cartUtils";
import { DelayedSpinner } from "../DelayedSpinner/DelayedSpinner";

const StatusBoxInnerContainer = styled.div`
  width: 100%;
  & > * {
    margin-bottom: 15px;
  }
`;

type BuyerQuoteItemFormDetailsProps = BuyerQuoteItemFormProps & {
  buyerTenantData?: Tenant;
  fetchedDeliveryTerms?: DeliveryTermPaginatedOutput;
  fetchedPaymentTerms?: PaymentTermPaginatedOutput;
  packagingUnits?: IPackagingUnit[];
  packagingTypes?: IPackagingType[];
};

export const PriceOffersHeader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.large};
  margin-top: 30px;
`;

/**
 * Calculate the "total" quantity based on the SKU and number of units.
 * In case of trouble, return `0` or null.
 */
export const calculateTotalQuantity = (
  skuPackageVolume: number | string | null | undefined,
  numberOfUnits: string | null | undefined
): number | null => {
  if (!skuPackageVolume || !numberOfUnits) {
    return null;
  }
  const packageVolume =
    typeof skuPackageVolume === "number"
      ? skuPackageVolume
      : parseFloat(skuPackageVolume); // package volume can be floats.

  const units = parseInt(numberOfUnits); // number of units should always be integer. (There's a validation that ensures that)
  if (isNaN(packageVolume) || isNaN(units)) {
    return null;
  }
  return Number((packageVolume * units).toFixed(2));
};

/**
 * Format the "total" string. For example, returns "160 US Gallon". In case of
 * null SKU, return just the total (e.g. "160").
 */
export const formatTotalQuantityString = (
  packagingUnitName: string | null | undefined,
  total: number | string
): string => {
  if (!packagingUnitName) {
    return String(total);
  }
  return `${total} ${packagingUnitName}`;
};

/**
 * Remove the "quote=1" param from a url search params string.
 */
const removeQuoteParam = (params: string): string => {
  const urlSearchParams = new URLSearchParams(params);
  urlSearchParams.delete("quote");
  const newParams = urlSearchParams.toString();

  return newParams.length > 0 ? `?${newParams}` : "";
};

export type PackagingOption =
  | OptionType<ProductSKU>
  | OptionType<"custom_packaging">
  | OptionType<"no_packaging">;

interface FormInputs {
  destination: OptionType<string>;
  delivery_term: OptionType<string>;
  payment_term: OptionType<string>;
  // If user does not select an application then it is undefined.
  application?: OptionType<string> | OptionType<null>;
  custom_application?: string;
  // The actual inputs in the form depend on what the user selects for packaging.
  // We put all of them here, some as optional, and then enforce things with
  // form validation. (I tried creating a stricter type but it led to too many
  // type checking errors related to react-hook-form, e.g. for default arguments.)
  packaging: PackagingOption;

  total_quantity: string;
  no_of_units?: string;
  unit_of_measure?: OptionType<string>;
  custom_packaging_type?: OptionType<string>;
  custom_packaging_quantity?: string;
}

export type ISubmitQuoteItemForm = (
  unifiedCartArg: UnifiedCartArg
) => Promise<void>;

export type BuyerQuoteItemFormProps = {
  editingExistingItem?: boolean;
  showProceedToCheckoutButton?: boolean;
  product: Product;
  submitQuoteItemForm: ISubmitQuoteItemForm;
  buyerUser: User;
  product_application?: ProductApplication;
  productSku?: ProductSKU | SkuNoPreference;
  no_of_units?: string;
  total_quantity?: string;
  custom_packaging_quantity?: string;
  product_name?: string;
  // paymentTerm, shippingTerm, and shippingAddress are "transaction values".
  // They are stored on the cart or quote request (transaction) and not the item
  // in the cart or quote request. They affect price tiers.
  // The shipping_address, delivery_term, and payment_term may not be editable.
  // We disable editing them when that would affect the availability of
  // price tiers for other items that are already in the cart.
  shippingAddress?: IAddress;
  paymentTerm?: IPaymentTerm;
  deliveryTerm?: IDeliveryTerm;
  allowEditingTransactionValues: boolean;
  /**
   * Display an optional warning message about the product to be added to the cart
   */
  warning?: string | null;
  // Currency code is also set on the transaction, but it is not directly user
  // editable.
  // - The currency will match the currency of any other items already in
  //   the cart or quote.
  // - If we are editing an existing item, the currency will not change.
  // - If we are adding a new item to the cart, and it's the first
  //   item in the cart, then the currency comes from the buyer tenant
  //   settings (which are accessible on the buyer user object).
  // - If all else fails, default to "USD".
  currencyCode: CurrencyCode;
};

/**
 * This form is for logged in buyers and lets them:
 * - add new items to the unified cart, from the portfolio or my products page.
 * - edit existing items that are already in the cart, from the portfolio
 *   or cart pages
 * - edit items in a quote request, from quote detail pages
 *
 * behavior of this component depends on presence of a quote vs quote items and
 * whether there are
 *
 * Because submitting this form can be different in the various places it is
 * used, a function is passed in that handles submitting the form.
 */
// TODO: rename this
export const BuyerQuoteItemForm = (props: BuyerQuoteItemFormProps) => {
  const {
    buyerUser,
    // TODO: rename productSku to just itemSku or something to avoid confusion
    // it could be a product sku or a custom sku.
    paymentTerm,
    deliveryTerm,
    allowEditingTransactionValues,
  } = props;

  const { notifyError } = useContext(Notifications);

  const {
    storeState: { storefront_id },
  } = useContext(Store);

  const { packagingUnits, isFetchingPackagingUnits } = usePackagingUnits();

  const { packagingTypes, isFetchingPackagingTypes } =
    useStorefrontPackagingTypes();

  const buyerTenantId = buyerUser.tenant_id;

  const deliveryTermsDisabled =
    !allowEditingTransactionValues && !!deliveryTerm;

  const paymentTermsDisabled = !allowEditingTransactionValues && !!paymentTerm;

  /**
   * Possibly fetch the buyer tenant data to get shipping addresses.
   */
  const {
    data: buyerTenantData,
    error: buyerTenantError,
    isValidating: isFetchingBuyerTenantData,
  } = useSWR<Tenant, AxiosError>(
    `/v1/storefronts/${storefront_id}/tenants/${buyerTenantId}`,
    { revalidateOnFocus: false }
  );

  useNotifyErrorOnce("Error fetching destinations", buyerTenantError);

  /**
   * Possibly fetch delivery terms.
   */
  const { data: fetchedDeliveryTerms, isValidating: isFetchingDeliveryTerms } =
    useSWR<DeliveryTermPaginatedOutput>(
      deliveryTermsDisabled
        ? null
        : makeDeliveryTermsGetEndpoint(storefront_id),
      {
        onError: (error) => {
          notifyError("There was an error fetching the shipping terms.", {
            error,
          });
        },
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  /**
   * Possibly fetch payment terms.
   */
  const { data: fetchedPaymentTerms, isValidating: isFetchingPaymentTerms } =
    useSWR<PaymentTermPaginatedOutput>(
      paymentTermsDisabled ? null : makePaymentTermsGetEndpoint(storefront_id),
      {
        onError: (error) => {
          notifyError("There was an error fetching the payment terms.", {
            error,
          });
        },
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const isLoading =
    isFetchingBuyerTenantData ||
    isFetchingDeliveryTerms ||
    isFetchingPackagingTypes ||
    isFetchingPackagingUnits ||
    isFetchingPaymentTerms;

  return isLoading ? (
    <DelayedSpinner />
  ) : (
    <BuyerQuoteItemFormDetails
      {...props}
      buyerTenantData={buyerTenantData}
      fetchedDeliveryTerms={fetchedDeliveryTerms}
      fetchedPaymentTerms={fetchedPaymentTerms}
      packagingUnits={packagingUnits}
      packagingTypes={packagingTypes}
    />
  );
};

const BuyerQuoteItemFormDetails = ({
  editingExistingItem,
  showProceedToCheckoutButton,
  product,
  submitQuoteItemForm,
  buyerUser,
  product_application,
  // TODO: rename productSku to just itemSku or something to avoid confusion
  // it could be a product sku or a custom sku.
  productSku,
  no_of_units,
  total_quantity,
  custom_packaging_quantity,
  shippingAddress,
  paymentTerm,
  deliveryTerm,
  allowEditingTransactionValues,
  currencyCode,
  warning,
  buyerTenantData,
  fetchedDeliveryTerms,
  fetchedPaymentTerms,
  packagingUnits,
  packagingTypes,
}: BuyerQuoteItemFormDetailsProps) => {
  const [updatedPackagingOption, setUpdatedPackagingOption] =
    useState<PackagingOption | null>(null);

  const [isSaving, setIsSaving] = useState(false);
  const [isProceedingToCheckout, setIsProceedingToCheckout] = useState(false);

  const { notifySuccess, notifyError } = useContext(Notifications);

  const {
    storeDispatch,
    storeState: { tenant_id },
  } = useContext(Store);

  const { storePath } = useRoutePath();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  // shipping address, delivery terms and payment terms are set on the 'parent'
  // transaction (cart or quote request) not on the 'child' item.
  // - If they are not set yet, then they can and *must* be selected before
  //   submitting this form. (This allows us to show price tiers in this form.)
  //   (The buyer will have default values for delivery terms and payment terms,
  //   that should be pre-filled in the input in this case, unless there is
  //   another source for pre-filling the value (e.g. a re-order from
  //   "My Products").)
  // - If they are already set, then they are not editable in this form and we
  //   disable the inputs.
  const destinationDisabled =
    !allowEditingTransactionValues && !!shippingAddress;
  const deliveryTermsDisabled =
    !allowEditingTransactionValues && !!deliveryTerm;
  const paymentTermsDisabled = !allowEditingTransactionValues && !!paymentTerm;

  const currencySymbol = useCurrencySymbol(currencyCode);

  const buyerTenantId = buyerUser.tenant_id;

  const allShippingAddresses =
    buyerTenantData?.addresses.filter((a) => a.type === "Warehouse") ?? [];

  const prefilledDestinationOption =
    shippingAddress && addressToOption(shippingAddress);

  // Use delivery term or payment term from the cart or quote if available,
  // otherwise use the buyer user's default terms.
  const defaultDeliveryTerm =
    deliveryTerm ?? buyerUser.settings?.default_delivery_term;
  const userDefaultDeliveryTerm = buyerUser.settings?.default_delivery_term;

  const defaultPaymentTerm =
    paymentTerm ?? buyerUser.settings?.default_payment_term;
  const userDefaultPaymentTerm = buyerUser.settings?.default_payment_term;

  const prefilledDeliveryTermOption =
    defaultDeliveryTerm && convertDeliveryTermToOption(defaultDeliveryTerm);

  const prefilledPaymentTermOption =
    defaultPaymentTerm && convertPaymentTermToOption(defaultPaymentTerm);

  const prefilledApplicationOption = product_application
    ? convertApplicationToOption(product_application)
    : undefined;

  const destinationOptions =
    prefilledDestinationOption && destinationDisabled
      ? [prefilledDestinationOption]
      : [
          ...allShippingAddresses.map(addressToOption),
          newShippingAddress(t, t("New address on checkout")),
        ];

  const deliveryTermsOptions =
    prefilledDeliveryTermOption && deliveryTermsDisabled
      ? [prefilledDeliveryTermOption]
      : fetchedDeliveryTerms?.data.map(convertDeliveryTermToOption) ?? [];

  const paymentTermsOptions =
    prefilledPaymentTermOption && paymentTermsDisabled
      ? [prefilledPaymentTermOption]
      : fetchedPaymentTerms?.data.map(convertPaymentTermToOption) ?? [];

  const productSkuOptions = product.product_skus
    .filter((sku) => !sku.is_sample)
    .map(convertProductSKUToOption);

  const packagingOptions = product.allow_custom_sku
    ? [noPackagingOption, ...productSkuOptions, customPackagingOption]
    : [noPackagingOption, ...productSkuOptions];

  const prefilledPackagingOption = (() => {
    // Checking for the existence of a packaging_type ensures (for TypeScript)
    // that we have a full product or custom SKU, and not a "no preference" SKU.
    if (productSku && productSku.packaging_type) {
      if (productSku.buyer_id) {
        return customPackagingOption;
      }
      let foundSku;
      // handle case where SKU may have been removed since product was last ordered
      if (
        (foundSku = product.product_skus.find(
          (sku) => sku.number === productSku.number
        ))
      ) {
        return convertProductSKUToOption(foundSku);
      }
    }
    return noPackagingOption;
  })();

  const packagingUnitOptions =
    packagingUnits?.map(packagingTypeOrUnitToOption) || [];

  const packagingTypeOptions =
    packagingTypes?.map(packagingTypeOrUnitToOption) || [];

  const prefilledUnitOfMeasure = (() => {
    const id = productSku?.packaging_unit?.id;
    if (id && packagingUnits && updatedPackagingOption) {
      const packagingUnit = packagingUnits.find((unit) => unit.id === id);
      if (packagingUnit) {
        return packagingTypeOrUnitToOption(packagingUnit);
      }
    }
    return undefined;
  })();

  const prefilledCustomPackagingType = (() => {
    if (productSku?.buyer_id) {
      const id = productSku?.packaging_type?.id;
      if (id && packagingTypes) {
        const packagingType = packagingTypes.find((type) => type.id === id);
        if (packagingType) {
          return packagingTypeOrUnitToOption(packagingType);
        }
      }
    }
    return undefined;
  })();

  const yupSharedSchema = {
    destination: yup
      .object()
      .nullable()
      .required(strings(t).thisIsARequiredField),
    delivery_term: yup
      .object()
      .nullable()
      .required(strings(t).thisIsARequiredField),
    payment_term: yup.object().required(strings(t).thisIsARequiredField),
    ...productApplicationSchema(t),
  };

  const mustBeIntegerErrorMessage = t("Must be an integer value");

  const yupSchemaNoPackaging = yup
    .object()
    .shape({
      ...yupSharedSchema,
      packaging: yup
        .object()
        .shape({ value: yup.string() })
        .required(strings(t).thisIsARequiredField),
      unit_of_measure: yup.object().required(strings(t).thisIsARequiredField),
      total_quantity: yup.string().required(strings(t).thisIsARequiredField),
    })
    .defined();

  const yupSchemaCustomPackaging = yup
    .object()
    .shape({
      ...yupSharedSchema,
      packaging: yup
        .object()
        .shape({ value: yup.string() })
        .required(strings(t).thisIsARequiredField),
      total_quantity: yup.string().required(strings(t).thisIsARequiredField),
      no_of_units: yup
        .string()
        .required(strings(t).thisIsARequiredField)
        // Use a regex to validate because it's a string.
        .matches(positiveIntegerRegex, mustBeIntegerErrorMessage),
      unit_of_measure: yup.object().required(strings(t).thisIsARequiredField),
      custom_packaging_type: yup
        .object()
        .required(strings(t).thisIsARequiredField),
      custom_packaging_quantity: yup
        .string()
        .required(strings(t).thisIsARequiredField),
    })
    .defined();

  const yupSchemaProductSkuPackaging = yup
    .object()
    .shape({
      ...yupSharedSchema,
      packaging: yup
        .object()
        .shape({ value: yup.object() })
        .required(strings(t).thisIsARequiredField),
      total_quantity: yup.string().required(strings(t).thisIsARequiredField),
      no_of_units: yup
        .string()
        .required(strings(t).thisIsARequiredField)
        // Use a regex to validate because it's a string.
        .matches(positiveIntegerRegex, mustBeIntegerErrorMessage),
    })
    .defined();

  type FormInputsProductSkuPackaging = yup.InferType<
    typeof yupSchemaProductSkuPackaging
  >;
  type FormInputsNoPackaging = yup.InferType<typeof yupSchemaNoPackaging>;
  type FormInputsCustomPackaging = yup.InferType<
    typeof yupSchemaCustomPackaging
  >;

  type FormInputsFromSchemas =
    | FormInputsProductSkuPackaging
    | FormInputsNoPackaging
    | FormInputsCustomPackaging;

  // We use yup.lazy to choose a schema to validate against based on what the
  // user selects for `packaging`. So dynamic!
  const lazyYupSchema = yup.lazy<FormInputsFromSchemas>((inputs) => {
    if (
      (inputs as { packaging: { value: string } }).packaging.value ===
      "no_packaging"
    ) {
      return yupSchemaNoPackaging;
    }
    if (
      (inputs as { packaging: { value: string } }).packaging.value ===
      "custom_packaging"
    ) {
      return yupSchemaCustomPackaging;
    }
    return yupSchemaProductSkuPackaging;
  });

  const methodsOfUseForm = useFormWrapper<FormInputs>({
    resolver: yupResolver(lazyYupSchema),
    defaultValues: {
      destination: prefilledDestinationOption,
      delivery_term: prefilledDeliveryTermOption,
      payment_term: prefilledPaymentTermOption,
      application: prefilledApplicationOption,
      packaging: prefilledPackagingOption,
      unit_of_measure: prefilledUnitOfMeasure,
      total_quantity: total_quantity || "",
      no_of_units: no_of_units || "",
      custom_packaging_type: prefilledCustomPackagingType,
      custom_packaging_quantity: custom_packaging_quantity || "",
    },
  });

  const { handleSubmit, control, formState, errors, watch } = methodsOfUseForm;

  const {
    destination: destinationOption,
    packaging: packagingOption,
    no_of_units: numberOfUnits,
    delivery_term: deliveryTermOption,
    payment_term: paymentTermOption,
  } = watch([
    "destination",
    "packaging",
    "no_of_units",
    "delivery_term",
    "payment_term",
  ]);

  // If the user selects a product SKU then packagingSkuOption won't be null.
  const packagingSkuOption =
    packagingOption &&
    packagingOption.value !== "no_packaging" &&
    packagingOption.value !== "custom_packaging"
      ? packagingOption
      : null;

  useQuantityInputFieldClearer(methodsOfUseForm);

  useEffect(
    () => setUpdatedPackagingOption(packagingOption),
    [packagingOption]
  );

  // use the storefront tenant ID.
  const seller_id = tenant_id;

  const sku_id_for_tiers_data =
    productSku?.id ??
    (updatedPackagingOption?.value as ProductSKU | undefined)?.id;

  const { data: tiersData } = useSWR<IPriceTierPaginatedOutput>(
    sku_id_for_tiers_data &&
      destinationOption?.value &&
      destinationOption.value !== "new_address" &&
      deliveryTermOption?.value &&
      paymentTermOption?.value
      ? makeUrlWithParams(endpoints.v1_priceTiers(), {
          seller_id,
          buyer_id: buyerTenantId,
          destination_id: destinationOption.value,
          product_sku_id: sku_id_for_tiers_data,
          delivery_term_id: deliveryTermOption.value,
          payment_term_id: paymentTermOption.value,
          currency: currencyCode,
        })
      : null,
    {
      onError: (error) => {
        notifyError("There was an error checking for price tiers", { error });
      },
    }
  );

  const { list_prices_exist, list_prices_as_price_tiers } =
    useProductSkuListPrice(product.id, sku_id_for_tiers_data);

  const priceTiers = tiersData?.data;
  const priceTiersExist = !!priceTiers && priceTiers.length > 0;

  const pricePerUom =
    (priceTiersExist || list_prices_exist) && numberOfUnits !== undefined
      ? calculatePriceTiersPricePerUom(
          [...(priceTiers ?? []), ...list_prices_as_price_tiers],
          parseFloat(numberOfUnits)
        )
      : null;

  const pricePerUomString =
    pricePerUom === null ? "--" : formatPrice(pricePerUom, currencyCode);

  const totalQuantity = calculateTotalQuantity(
    packagingSkuOption?.value?.package_volume,
    numberOfUnits
  );

  const totalPrice =
    totalQuantity === null || pricePerUom === null
      ? null
      : calculatePriceForQuantity(pricePerUom, totalQuantity);

  const totalPriceString =
    totalPrice === null ? "--" : formatPrice(totalPrice, currencyCode);

  const { data: lastPriceData } = useSWR<LastPurchaseResponse>(
    destinationOption && productSku
      ? makeUrlWithParams(endpoints.v1_lastPurchase(), {
          seller_id,
          buyer_id: buyerTenantId,
          destination_id: destinationOption.value,
          product_sku_id: productSku?.id,
        })
      : null
  );
  const lastPriceCurrencySymbol = useCurrencySymbol(
    lastPriceData?.currency || "USD"
  );

  // Because there are two buttons that can submit this form we use the
  // following click handlers to call the true `onSubmit` function below.

  const handleSaveClick = async (formInputs: FormInputs) => {
    if (!isSaving) {
      setIsSaving(true);
      await onSubmit(formInputs);
      setIsSaving(false);
    }
  };

  const handleProceedToCheckoutClick = async (formInputs: FormInputs) => {
    if (!isProceedingToCheckout) {
      setIsProceedingToCheckout(true);
      await onSubmit(formInputs);

      const params = removeQuoteParam(location.search);
      const payload = `${location.pathname}${params}${location.hash}`;

      storeDispatch({ type: "SET_STORED_LOCATION", payload });
      setIsProceedingToCheckout(false);
      history.push(`${storePath}/cart`);
    }
  };

  /**
   * Because this form is reused so frequently this handler actually calls the
   * true submit handler which is passed in as a prop. Thus the true submit
   * handler is defined in the parent component that renders this component.
   */
  const onSubmit = async (formInputs: FormInputs) => {
    try {
      const {
        packaging,
        application,
        custom_application,
        destination,
        delivery_term,
        payment_term,
        total_quantity,
        no_of_units,
        unit_of_measure,
        custom_packaging_type,
        custom_packaging_quantity,
      } = formInputs;

      if (!buyerUser.seller_id) {
        // Buyer seller_id should always be a string, but the type checker
        // doesn't know that.
        const message = "There was an error submitting the form";
        notifyError(message);
        console.error(message, "seller_id is null", buyerUser.seller_id);
        return;
      }

      // Different things go in the request for each case:
      // 1. "no_packaging" (no product sku, no custom sku)
      //    - total_quantity
      //    - packaging_unit_id
      // 2. "custom_packaging" (no product sku, custom sku)
      //    - total_quantity
      //    - packaging_unit_id
      //    - custom_packaging_type_id
      //    - custom_packaging_quantity
      // 3. product sku
      //    - total_quantity
      //    - sku_id
      //    - no_of_units

      const sharedInputs = {
        applications: application?.value ? [application.value] : [],
        ...(custom_application ? { custom_application } : {}),
        currency: currencyCode,
        delivery_term_id: delivery_term.value,
        payment_term_id: payment_term.value,
        product_id: product.id,
        seller_id: buyerUser.seller_id,
        shipping_address_id:
          destination.value === "new_address" ? undefined : destination.value,
      };

      let unifiedCartArg: UnifiedCartArg | null = null;

      if (packaging.value === "no_packaging") {
        if (unit_of_measure?.value) {
          unifiedCartArg = {
            total_quantity,
            packaging_unit_id: unit_of_measure.value,
            ...sharedInputs,
          };
        }
      } else if (packaging.value === "custom_packaging") {
        if (
          no_of_units &&
          unit_of_measure?.value &&
          custom_packaging_type?.value &&
          custom_packaging_quantity
        ) {
          unifiedCartArg = {
            total_quantity,
            no_of_units,
            packaging_unit_id: unit_of_measure.value,
            custom_packaging_type_id: custom_packaging_type.value,
            custom_packaging_quantity,
            buyer_id: buyerUser.tenant_id,
            ...sharedInputs,
          };
        }
      } else {
        // Product SKU packaging.
        if (packagingSkuOption?.value.id && no_of_units) {
          unifiedCartArg = {
            total_quantity,
            sku_id: packagingSkuOption.value.id,
            no_of_units,
            ...sharedInputs,
          };
        }
      }

      if (!unifiedCartArg) {
        // Should never happen thanks to form validation.
        notifyError(
          t(`There was a problem with the form inputs, please try again`),
          { error: new Error("Form validation failed") }
        );
        return;
      }

      await submitQuoteItemForm(unifiedCartArg);

      notifySuccess(t("The cart has been updated"));
    } catch (error) {
      if (isAxiosError(error) && error?.response?.status === 409) {
        notifyError(t(`Cart is already in progress`));
        return;
      }
      notifyError(t("An error occurred while updating the cart"), {
        error,
      });
    }
  };

  const submitButtonText = editingExistingItem ? t("Save") : t("Add to cart");

  const proceedToCheckoutButtonText = editingExistingItem
    ? t("Save and Proceed to Checkout")
    : t("Proceed to Checkout");

  return (
    <div>
      <Title>{editingExistingItem ? t("Edit item") : t("Add to cart")}</Title>
      <Form noValidate>
        <StatusBox>
          <StatusBoxInnerContainer>
            {warning && (
              <WarningMessageBox>
                <div style={{ marginRight: "4px" }}>
                  <WarningIcon width={18} />
                </div>
                {warning}
              </WarningMessageBox>
            )}
            <HeaderSection product={product} />
            <div>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="destination"
                placeholder={t("Shipping Address")}
                options={destinationOptions}
                defaultValue={prefilledDestinationOption}
                isDisabled={destinationDisabled}
                rules={{ required: true }}
                errors={errors}
                formState={formState}
              />
            </div>
            <div>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="delivery_term"
                placeholder={t("Shipping Terms")}
                options={markDefaultTerm(
                  deliveryTermsOptions,
                  userDefaultDeliveryTerm
                    ? convertDeliveryTermToOption(userDefaultDeliveryTerm)
                    : undefined
                )}
                isDisabled={deliveryTermsDisabled}
                rules={{ required: true }}
                errors={errors}
                formState={formState}
                setDefaultTerm={!!userDefaultDeliveryTerm}
              />
            </div>
            <div>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="payment_term"
                placeholder={t("Requested Payment Terms")}
                options={markDefaultTerm(
                  paymentTermsOptions,
                  userDefaultPaymentTerm
                    ? convertPaymentTermToOption(userDefaultPaymentTerm)
                    : undefined
                )}
                isDisabled={paymentTermsDisabled}
                rules={{ required: true }}
                errors={errors}
                formState={formState}
                setDefaultTerm={!!userDefaultPaymentTerm}
              />
            </div>
            <div>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="packaging"
                placeholder={t("Preferred SKU")}
                options={packagingOptions}
                rules={{ required: true }}
                errors={errors}
                formState={formState}
              />
            </div>
            {packagingOption?.value === "no_packaging" && (
              <>
                <QuantityFormPartNoPackaging
                  methodsOfUseForm={methodsOfUseForm}
                  unitOfMeasureOptions={packagingUnitOptions}
                  uomValue={prefilledUnitOfMeasure}
                />
              </>
            )}
            {packagingOption?.value === "custom_packaging" && (
              <>
                <QuantityFormPartCustomSku
                  methodsOfUseForm={methodsOfUseForm}
                  unitOfMeasureOptions={packagingUnitOptions}
                  packagingTypeOptions={packagingTypeOptions}
                  uomValue={prefilledUnitOfMeasure}
                />
              </>
            )}
            {packagingSkuOption && (
              <>
                <QuantityFormPartProductSku
                  methodsOfUseForm={methodsOfUseForm}
                  unitOfMeasure={packagingSkuOption.value.packaging_unit?.name}
                  skuPackageVolume={packagingSkuOption.value.package_volume}
                />
              </>
            )}
            {lastPriceData &&
              lastPriceData.number_of_units &&
              lastPriceData.price_per_unit &&
              lastPriceData.sku && (
                <KeyValueDisplay
                  data={[
                    {
                      key: t("Last Purchase"),
                      value: formatLastPurchasePrice({
                        numberOfUnits: lastPriceData.number_of_units,
                        pricePerUnit: lastPriceData.price_per_unit,
                        sku: lastPriceData.sku,
                        currencySymbol: lastPriceCurrencySymbol,
                        t,
                      }),
                    },
                  ]}
                />
              )}
            {priceTiersExist && (
              <>
                <PriceOffersHeader>
                  {t("You have price offers from the seller:")}
                </PriceOffersHeader>
                <PriceTiersTable
                  tiers={[...priceTiers, ...list_prices_as_price_tiers]}
                />
              </>
            )}
            {!priceTiersExist && list_prices_exist && (
              <>
                <PriceOffersHeader>
                  {t("You have price offers from the seller:")}
                </PriceOffersHeader>
                <PriceTiersTable tiers={list_prices_as_price_tiers} />
              </>
            )}
            {priceTiersExist && (
              <KeyValueDisplay
                data={[
                  {
                    key: t(`Price ({{currencySymbol}}/UoM)`, {
                      currencySymbol,
                    }),
                    value: pricePerUomString,
                  },
                  {
                    key: t("Total Price"),
                    value: totalPriceString,
                  },
                ]}
              />
            )}
          </StatusBoxInnerContainer>
        </StatusBox>
        <ProductApplicationSelect
          methodsOfUseForm={methodsOfUseForm}
          applications={product?.product_applications ?? []}
        />
        <PrimaryButtonFitContainer
          onClick={handleSubmit(handleSaveClick)}
          loading={isSaving}
          disabled={isProceedingToCheckout}
        >
          {submitButtonText}
        </PrimaryButtonFitContainer>

        {showProceedToCheckoutButton && (
          <SecondaryButtonFitContainer
            onClick={handleSubmit(handleProceedToCheckoutClick)}
            loading={isProceedingToCheckout}
            disabled={isSaving}
          >
            {proceedToCheckoutButtonText}
          </SecondaryButtonFitContainer>
        )}
      </Form>
    </div>
  );
};
