import { formateInternationalPhoneNumber } from "../../util/phone";
import type { FunctionComponent } from "react";
import React from "react";
import styled from "styled-components/macro";
import type { IAddress, LeadAddress, User } from "../../types/types";
import { LocationIcon, UserIcon } from "../Icons/Icons";
import { InfoBlockSmall } from "../InfoBlocks/InfoBlocks";
import { EditButton } from "../Buttons/Buttons";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../Auth";
import ReactTooltip from "react-tooltip";

const LocationBlockRow = styled.div`
  margin-bottom: 2px;
  display: grid;
  grid-template-columns: 49% 49%;
  padding-top: 27px;
  padding-bottom: 27px;
  grid-gap: 2%;
  background-color: ${({ theme }) => theme.primaryBG};
  padding-left: 21px;
  align-items: start;
  margin-right: 35px;
  position: relative;
`;

const EditLocationButton = styled(EditButton)`
  position: absolute;
  top: 10px;
  right: 5px;
`;

export const MultiLineBlock = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  display: inline-block;
`;

export function AddressBlock({
  address,
  is_transaction = false,
}: {
  address?: Partial<IAddress>;
  is_transaction?: boolean;
}) {
  const { t } = useTranslation();
  const { roleIsSomeKindOfBuyer } = useAuthContext();
  return address ? (
    <MultiLineBlock>
      {address?.company_name && <div>{address.company_name}</div>}
      <div>
        {address?.address1} {address?.address2}
      </div>
      <div>
        {address?.city ? `${address?.city}, ` : ""}{" "}
        {address?.county ? `${address.county} ` : ""}
        {address?.state_display_name ? address?.state_display_name + " " : "--"}
        {address?.postal_code ?? ""}
      </div>
      <div>
        {address?.country && address?.country !== "" ? address?.country : "--"}
      </div>
      {address?.tax_id && address?.tax_id !== "" && (
        <div>
          {t("Tax ID")}: {address?.tax_id}
        </div>
      )}
      {!roleIsSomeKindOfBuyer &&
        !is_transaction &&
        address.type === "Warehouse" &&
        address?.crm_id &&
        address?.crm_id !== "" && (
          <div>
            {t("CRM ID")}: {address?.crm_id ?? "--"}
          </div>
        )}
      {address?.external_identity && (
        <div>
          {t("Location ID")}: {address?.external_identity.external_id}
        </div>
      )}
    </MultiLineBlock>
  ) : (
    <MultiLineBlock>{`--`}</MultiLineBlock>
  );
}

export function ContactBlock({ address }: { address: IAddress }) {
  return (
    <MultiLineBlock>
      <div>{address.contact_name}</div>
      <div>{address.email_address}</div>
      <div>
        <a href={`tel:${address.phone_number}`}>
          {formateInternationalPhoneNumber(address.phone_number)}
        </a>
      </div>
    </MultiLineBlock>
  );
}

export type LeadAddressWithContact = {
  company_name: string;
  email_address: string;
  first_name: string;
  last_name: string;
  phone_number: string;
} & Partial<LeadAddress>;

export function ContactBlockForLead({
  address,
}: {
  address: LeadAddressWithContact;
}) {
  const maxEmailLength = 38;
  const isEmailTooLong = address.email_address.length > maxEmailLength;

  return (
    <MultiLineBlock>
      <div>{address?.first_name + " " + address?.last_name}</div>
      <div>{address.company_name}</div>
      <div>
        {isEmailTooLong ? (
          <>
            <span data-tip={address.email_address}>
              {address.email_address.slice(0, maxEmailLength)}...
            </span>
            <ReactTooltip effect="solid" />
          </>
        ) : (
          address.email_address
        )}
      </div>
      <div>
        <a href={`tel:${address.phone_number}`}>
          {formateInternationalPhoneNumber(address.phone_number)}
        </a>
      </div>
    </MultiLineBlock>
  );
}

/**
 * Given a user object, returns a contact block showing that user's name,
 * email, and phone number. If the user is not defined, returns placeholders.
 */
export function ContactBlockForUser({ user }: { user: User | undefined }) {
  const fullname =
    !user || (!user.firstname && !user.lastname)
      ? "--"
      : `${user.firstname || "--"} ${user.lastname || "--"}`;

  return (
    <MultiLineBlock>
      <div>{fullname}</div>
      <div>{user?.email_address || "--"}</div>
      {user?.phone_number && (
        <div>
          {(
            <a href={`tel:${user?.phone_number}`}>
              {formateInternationalPhoneNumber(user?.phone_number)}
            </a>
          ) || "--"}
        </div>
      )}
    </MultiLineBlock>
  );
}

interface LocationsListProps {
  addresses: IAddress[];
  onEditAddress?: (address: IAddress) => void;
  onDeleteAddress?: (id: string) => void;
}

/**
 * A list of locations (which include address and contact info). For example,
 * used for "Company Info" pages.
 */
export const LocationsList: FunctionComponent<LocationsListProps> = ({
  addresses,
  onEditAddress,
  onDeleteAddress,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {addresses
        .filter((address) => address.type === "Warehouse")
        .map((address, index) => {
          return (
            <LocationBlockRow key={address.id + index}>
              <InfoBlockSmall
                header={t("Address")}
                content={<AddressBlock address={address} />}
                icon={<LocationIcon width={16} />}
              />
              <InfoBlockSmall
                header={t("Point of Contact")}
                content={<ContactBlock address={address} />}
                icon={<UserIcon width={16} />}
              />
              {onEditAddress && (
                <EditLocationButton
                  onClick={() => onEditAddress(address)}
                  testid={`location-edit-button-${address.email_address}`}
                />
              )}
              {/* {onDeleteAddress && (
                //  ID is for testing deletion after creation.
                <DeleteButton
                  id={address.id}
                  onClick={() => onDeleteAddress(address.id)}
                  testid={`location-delete-button-${address.email_address}`}
                />
              )} */}
            </LocationBlockRow>
          );
        })}
    </>
  );
};
