import {
  ContentWrapper,
  PageHeader,
  PageTitle,
  Card,
  FullWidthHorizontalSeparator,
} from "../../../layout/portalPageLayout";
import { PageWrapper } from "../../../layout/publicPageLayout";
import type { AxiosError } from "axios";
import Axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components/macro";
import useSWR from "swr";
import {
  CancelButton,
  EditButton,
  GoBackButton,
} from "../../../components/Buttons/Buttons";
import { CreateNewTenantFormContainer } from "../../../components/CreateNewTenantForm/CreateNewTenantFormContainer";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../components/Error";
import { IDDesktop } from "../../../components/IDs/IDs";
import { InfoBlockExtraSmall } from "../../../components/InfoBlocks/InfoBlocks";
import {
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
  SubheaderInformationContainer,
} from "../../../components/Layout/Layout";
import {
  AddressBlock,
  ContactBlockForLead,
} from "../../../components/LocationsList/LocationsList";
import { Modal } from "../../../components/Modal/Modal";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { StatusRight } from "../../../components/Status/Status";
import {
  ClickableLink,
  H3,
  InnerTitleText,
  SubheaderContentText,
} from "../../../components/Typography/Typography";
import { WideDetails } from "../../../layout/shared/DetailPageLayout/DetailPageLayout";
import type {
  Lead,
  LeadRejectionArgsSchema,
  LocationState,
  Tenant,
  UserTypeChip,
  UUID,
} from "../../../types/types";
import { providePrivatePageProps, useRoutePath } from "../../../util/Routing";
import {
  formatDateTime,
  makeUrlWithParams,
  useInAppNotifications,
  useStoreState,
} from "../../../util/util";
import { getLeadStatusDetails } from "./leadsUtilities";
import { WarningMessageBox } from "../../../components/Form/Form";
import { WarningIcon } from "../../../components/Icons/Icons";
import { CreateNewUser } from "../../../components/CreateNewUser/CreateNewUser";
import { endpoints } from "../../../endpoints";
import { LeadNotes } from "./LeadNotes";
import type { HandleLeadNoteSubmitProps } from "./LeadNotes";
import { LeadRejectionCard } from "./LeadRejectionCard";
import { LeadRejectionModal } from "./LeadRejectionModal";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { AssignLeadToUser } from "./AssignLeadToUser";
import { DropDownButton } from "../../../components/DropDownButton/DropDownButton";
import { RightButtonContainer } from "./QuoteRequestLeadDetailPage/QuoteRequestLeadDetailPage";

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

function displayCustomChoices(custom_choices: Record<string, string | null>) {
  return Object.entries(custom_choices).reduce<JSX.Element[]>(
    (acc, [key, value]) =>
      value
        ? [
            ...acc,
            <InfoBlockExtraSmall
              header={key}
              key={key}
              content={value ?? "--"}
            />,
          ]
        : acc,
    []
  );
}

const NameAndIcon = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 2px;
`;
/**
 * A shared page for viewing contact us/registration leads as a seller.
 *
 * /store/{slug}/admin/leads/contact-requests/{id}
 * /store/{slug}/admin/leads/registration/{id}
 *
 * This page loads a lead id from the URL to fetch a lead.
 */
export const LeadDetailPage = providePrivatePageProps(({ user }) => {
  const { adminPath } = useRoutePath();
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const location = useLocation();
  const { lead_id } = useParams<{ lead_id: UUID }>();
  const leadType = location.pathname.includes("registration")
    ? "registration"
    : "contact_form";
  const history = useHistory();
  const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(false);
  const [showAddToCustomerModal, setShowAddToCustomerModal] = useState(false);
  const [showLeadRejectionModal, setShowLeadRejectionModal] = useState(false);
  const [showLeadAssignmentModal, setShowLeadAssignmentModal] = useState(false);
  const { notifyError, notifySuccess } = useContext(Notifications);
  const theme = useTheme();
  const { mutateNotifications } = useInAppNotifications(storefront_id, user);

  const [tenantTypeToBeCreated, setTenantTypeToBeCreated] =
    useState<"Buyer" | "Distributor">("Buyer");

  const closeLeadRejectionModal = () => setShowLeadRejectionModal(false);
  const openLeadRejectionModal = () => setShowLeadRejectionModal(true);
  const closeCreateCustomerModal = () => setShowCreateCustomerModal(false);
  const openCreateCustomerModal = () => setShowCreateCustomerModal(true);
  const openAddUserToCustomerModal = () => setShowAddToCustomerModal(true);
  const closeAddUserToCustomerModal = () => setShowAddToCustomerModal(false);
  const closeLeadAssignmentModal = () => setShowLeadAssignmentModal(false);
  const openLeadAssignmentModal = () => setShowLeadAssignmentModal(true);
  const handleLeadRejection = async (messages: LeadRejectionArgsSchema) => {
    try {
      await Axios.post(
        `/v1/tenants/${user.tenant_id}/leads/${lead_id}/reject`,
        messages
      );
      notifySuccess("Lead rejected successfully");
      mutateLead();
      closeLeadRejectionModal();
    } catch (error) {
      notifyError(t("There was an error rejecting lead"), { error });
    }
  };

  const onConvertToCustomer = (tenantId: string) => {
    mutateLead();
    closeCreateCustomerModal();
    history.push(
      `${adminPath}/${
        tenantTypeToBeCreated === "Buyer" ? "tenants" : "distributors"
      }/${tenantId}`
    );
  };

  const onLeadNoteSubmit = async ({
    note,
    onSuccess,
  }: HandleLeadNoteSubmitProps) => {
    try {
      await Axios.post(
        `/v1/storefronts/${storefront_id}/leads/${lead_id}/notes`,
        {
          type: leadType,
          message: note,
        }
      );
      onSuccess();
      mutateLead();
      notifySuccess(t("note added successfully"));
    } catch (error) {
      notifyError(t("There was an error adding the note"));
    }
  };

  const {
    data: lead,
    error: leadError,
    mutate: mutateLead,
  } = useSWR<Lead, AxiosError>(
    `/v1/storefronts/${storefront_id}/leads/${lead_id}`
  );

  const { data: convertedToUser } = useSWR<Tenant>(
    lead?.status === "Added as User"
      ? endpoints.v1_storefronts_id_tenants_id(
          storefront_id,
          lead.parent_customer_id
        )
      : null
  );

  useEffect(() => {
    if (lead) {
      mutateNotifications();
    }
  }, [lead, mutateNotifications]);

  const isLoadingLead = !lead && !leadError;

  if (isLoadingLead) {
    return <DelayedSpinner />;
  }

  if (leadError) {
    return (
      <ErrorPlaceholder message={t("There was an error loading the lead")} />
    );
  }

  if (lead) {
    const contactRequestOrRegistration =
      lead.source === "registration" ? t("Registration") : t("Contact Us");

    const rejectionTitleString =
      lead.source === "registration"
        ? t("Are you sure you want to reject this Registration Request?")
        : t("Are you sure you want to reject this Contact Request?");

    const address = "address" in lead &&
      lead.address !== null && {
        city: lead?.address?.city,
        state: lead?.address?.state,
        state_display_name: lead?.address?.state_display_name,
        country: lead?.address?.country,
        address1: lead.address?.address1,
        address2: lead.address?.address2,
        postal_code: lead.address?.postal_code,
        email_address: lead.buyer_email,
        first_name: lead.buyer_first_name,
        last_name: lead.buyer_last_name,
        phone_number: lead.buyer_phone_number,
      };

    const buyerInfo = {
      company_name: lead.buyer_company_name,
      email_address: lead.buyer_email,
      first_name: lead.buyer_first_name,
      last_name: lead.buyer_last_name,
      phone_number: lead.buyer_phone_number,
    };

    const onLeadAssignmentSuccess = (state: "unassigned" | "assigned") => {
      state === "assigned"
        ? notifySuccess(t("Lead assigned successfully"))
        : notifySuccess(t("Lead unassigned successfully"));

      closeLeadAssignmentModal();
      mutateLead();
    };

    const onLeadAssignmentFailure = (error: unknown) => {
      notifyError(t("There was an error assigning the lead"), { error });
    };

    const leadAssignmentUrl = `/v2/storefronts/${storefront_id}/leads/${lead_id}/assign`;
    const leadAsigneeName = `${lead?.assignee?.firstname} ${lead?.assignee?.lastname}`;

    const getBackLinkState = () => {
      const listState = (location.state as LocationState)?.listState;
      return listState ? { listState } : undefined;
    };

    const getBackLinkParams = () => {
      const { tabIndex, offset, perPage } =
        (location.state as LocationState)?.listState || {};
      return {
        tab: tabIndex !== undefined ? tabIndex : undefined,
        offset: offset !== undefined ? offset : undefined,
        perPage: perPage !== undefined ? perPage : undefined,
      };
    };

    const handleBackClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const state = getBackLinkState();
      const params = getBackLinkParams();
      const search = makeUrlWithParams("", params);
      history.push({
        pathname: `${adminPath}/leads`,
        search,
        state,
      });
    };
    const userTypeChips: UserTypeChip[] =
      tenantTypeToBeCreated === "Buyer"
        ? [{ name: t("Admin"), id: "Buyer Admin" }]
        : [{ name: t("Distributor"), id: "Distributor Admin" }];

    return (
      <PageWrapper style={{ maxWidth: "932px" }}>
        <Link to={`${adminPath}/leads`} onClick={handleBackClick}>
          <GoBackButton text={t("Leads")} />
        </Link>
        <SlideOut
          show={showLeadAssignmentModal}
          closeFlyout={closeLeadAssignmentModal}
        >
          <AssignLeadToUser
            url={leadAssignmentUrl}
            onSuccess={onLeadAssignmentSuccess}
            onError={onLeadAssignmentFailure}
            prefilledUser={lead?.assignee}
          />
        </SlideOut>
        <PageHeader>
          <HeaderLeft>
            <PageTitle>{contactRequestOrRegistration}</PageTitle>
            {lead.number && <IDDesktop>{lead.number}</IDDesktop>}
          </HeaderLeft>
          <HeaderRight>
            <StatusRight {...getLeadStatusDetails(lead.status, t)} />
          </HeaderRight>
        </PageHeader>
        <HeaderContainer>
          <SubheaderInformationContainer>
            <InnerTitleText>{t("Assigned to: ")}</InnerTitleText>
            <SubheaderContentText>
              {lead.assignee ? (
                <NameAndIcon>
                  <div>{leadAsigneeName}</div>
                  <div>
                    <EditButton
                      style={{
                        padding: "0",
                        position: "absolute",
                        top: "-7px",
                      }}
                      width={16}
                      height={19}
                      onClick={openLeadAssignmentModal}
                    />
                  </div>
                </NameAndIcon>
              ) : (
                <ClickableLink
                  style={{ color: theme.primaryButtonBorder }}
                  onClick={openLeadAssignmentModal}
                >
                  {t("Add")}
                </ClickableLink>
              )}
            </SubheaderContentText>
          </SubheaderInformationContainer>
          {lead.assignment_date && lead.assignee && (
            <SubheaderInformationContainer>
              <InnerTitleText>{t("Assigned on: ")}</InnerTitleText>
              <SubheaderContentText>
                {formatDateTime(lead.assignment_date)}
              </SubheaderContentText>
            </SubheaderInformationContainer>
          )}
        </HeaderContainer>
        <FullWidthHorizontalSeparator margin={"20px 0 0"} />

        <ContentWrapper style={{ margin: "45px 0 20px 0" }}>
          <Card>
            <H3>{t("Customer Details")}</H3>
            <WideDetails>
              <InfoBlockExtraSmall
                header={t("Requested By")}
                content={<ContactBlockForLead address={buyerInfo} />}
              />
              {"address" in lead && address && (
                <InfoBlockExtraSmall
                  header={t("Address")}
                  content={<AddressBlock address={address} />}
                />
              )}
              <div>
                <InfoBlockExtraSmall
                  header={t("Date Received")}
                  content={formatDateTime(lead.created_at)}
                />
                {lead.status === "Added as User" && convertedToUser && (
                  <div style={{ marginTop: "5px" }}>
                    <InfoBlockExtraSmall
                      header={t("User Added to")}
                      content={convertedToUser.name}
                    />
                  </div>
                )}
              </div>
            </WideDetails>

            {lead?.extra?.message && (
              <div style={{ marginBottom: "20px" }}>
                <InfoBlockExtraSmall
                  header={t("How can we help you?")}
                  content={lead.extra.message}
                />
              </div>
            )}
            {lead.extra?.custom_choices && (
              <div>{displayCustomChoices(lead.extra.custom_choices)}</div>
            )}

            {lead.status === "New" && lead.is_existing_customer && (
              <WarningMessageBox>
                <WarningIcon width={18} />
                {t(
                  `This lead may be a user of an existing customer, {{companyName}}. Click Add to Existing Customer to add this lead as a user to {{companyName}}.`,
                  {
                    companyName: lead.company_name,
                  }
                )}
              </WarningMessageBox>
            )}
            <ButtonSection>
              {lead.status === "New" && (
                <CancelButton
                  data-testid="reject-lead-button"
                  onClick={openLeadRejectionModal}
                >
                  {t("Reject this Lead")}
                </CancelButton>
              )}
              {lead.status === "New" && (
                <div>
                  <RightButtonContainer>
                    <DropDownButton
                      items={[t("Customer"), t("Distributor")]}
                      textLeft={t("Convert to new")}
                      clickHandler={(item: string) => {
                        if (item === t("Customer")) {
                          setTenantTypeToBeCreated("Buyer");
                          openCreateCustomerModal();
                        } else if (item === t("Distributor")) {
                          setTenantTypeToBeCreated("Distributor");
                          openCreateCustomerModal();
                        }
                      }}
                    />
                    <DropDownButton
                      items={[t("Customer"), t("Distributor")]}
                      textLeft={t("Add to existing")}
                      clickHandler={(item: string) => {
                        if (item === t("Customer")) {
                          setTenantTypeToBeCreated("Buyer");
                          openAddUserToCustomerModal();
                        } else if (item === t("Distributor")) {
                          setTenantTypeToBeCreated("Distributor");
                          openAddUserToCustomerModal();
                        }
                      }}
                    />
                  </RightButtonContainer>
                </div>
              )}
            </ButtonSection>
          </Card>
          {lead.rejection_note && (
            <LeadRejectionCard rejection_note={lead.rejection_note} />
          )}
          <LeadRejectionModal
            show={showLeadRejectionModal}
            closeModal={closeLeadRejectionModal}
            onFormSubmit={handleLeadRejection}
            titleString={rejectionTitleString}
          />
          <LeadNotes
            internal_notes={lead.internal_notes}
            handleSubmit={onLeadNoteSubmit}
          />
        </ContentWrapper>
        <Modal
          closeModal={closeAddUserToCustomerModal}
          show={showAddToCustomerModal}
        >
          <CreateNewUser
            tenantId={user.tenant_id}
            userTypeChips={userTypeChips}
            defaultUserType={userTypeChips[0].id}
            leadToUserData={{
              firstname: lead.buyer_first_name,
              lastname: lead.buyer_last_name,
              email_address: lead.buyer_email,
              phone_number: lead.buyer_phone_number,
              lead_id: lead.id,
              existing_customer: {
                company_name: lead.company_name,
                customer_id: lead.customer_id,
              },
              address: lead.address,
            }}
            tenantTypeToFetch={tenantTypeToBeCreated}
            userFormType="add_to_Existing_customer"
            onSuccess={() => {
              mutateLead();
              closeAddUserToCustomerModal();
            }}
            showShippingAddress={true}
          />
        </Modal>
        <Modal
          closeModal={closeCreateCustomerModal}
          show={showCreateCustomerModal}
        >
          <CreateNewTenantFormContainer
            onSuccess={onConvertToCustomer}
            lead={lead}
            sellerTenantId={user.tenant_id}
            type={tenantTypeToBeCreated}
          />
        </Modal>
      </PageWrapper>
    );
  } else return null;
});
