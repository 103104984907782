import styled from "styled-components/macro";
import { RadioButtonContainer } from "../../../../layout/FormLayout";
import {
  H3,
  SmallSectionHeaderRegular,
} from "../../../../components/Typography/Typography";
import { useTranslation } from "react-i18next";
import { RadioButton } from "../../../../components/RadioButton/RadioButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConfirmDialog } from "../../../../components/ConfirmDialog/ConfirmDialog";
import { Row } from "../../../../components/Layout/Layout";
import { DropDown } from "../../../../components/DropDown/DropDown";
import { ContentWrapper } from "../../../../layout/publicPageLayout";
import { Table } from "../../../../components/Table/Table";
import type { Row as RowType, SortingRule } from "react-table";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  EditButton,
  SecondaryButtonWithPlusIcon,
} from "../../../../components/Buttons/Buttons";
import { Pagination } from "../../../../components/Pagination/Pagination";
import useSWR from "swr";
import type { WithPagination } from "../../../../types/types";
import type {
  PIMProduct,
  ProductListPriceSchema,
} from "../../../../types/types.PIM";
import { endpoints } from "../../../../endpoints";
import {
  defaultHandleSort,
  formatDate,
  TablePlaceholder,
  useStoreState,
} from "../../../../util/util";
import { SlideOut } from "../../../../components/SlideOut/SlideOut";
import { AddOrEditProductSkuListPrice } from "./AddOrEditProductSkuListPrice";

const HeaderSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 24px;
`;

const RadioSection = styled(RadioButtonContainer)`
  margin-left: -5px;
`;

type TableData = ProductListPriceSchema & { sku_name: string };

export const ProductPricingTab = ({ product }: { product: PIMProduct }) => {
  const [pricing_visibility, set_pricing_visibility] =
    useState<"everywhere" | "internal_users_and_customers" | "internal_users">(
      "everywhere"
    );
  const [transient_pricing_visibility, set_transient_pricing_visibility] =
    useState<
      "everywhere" | "internal_users_and_customers" | "internal_users"
    >();
  const [visibility_dialog_message, set_manage_visibility_dialog_message] =
    useState<string>("");
  const [show_visibility_dialog, set_show_visibility_dialog] =
    useState<boolean>(false);
  const [per_page, set_per_page] = useState(10);
  const [offset, set_offset] = useState(0);
  const [sorting_rules, set_sorting_rules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({ orderBy: "asc" });
  const [table_data, set_table_data] = useState<TableData[]>([]);
  const [table_pagination, set_table_pagination] = useState({
    perPage: per_page,
    pageCount: 0,
    pageIndex: 0,
  });
  const [list_price_to_edit, set_list_price_to_edit] =
    useState<ProductListPriceSchema>();
  const [show_add_or_edit_product_list, set_show_add_or_edit_product_list] =
    useState(false);
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();

  const {
    data: product_list_prices,
    error: product_list_prices_error,
    mutate: mutate_product_list_prices,
  } = useSWR<WithPagination<{ data: ProductListPriceSchema[] }>>(
    endpoints.v2_tenants_tenant_id_products_product_id_list_prices(
      tenant_id,
      product.id
    )
  );

  const on_delete_list_price = useCallback(
    (list_price: ProductListPriceSchema) => {
      const on_delete = async () => {
        try {
        } catch (error) {
        } finally {
          await mutate_product_list_prices();
        }
      };
      on_delete();
    },
    [mutate_product_list_prices]
  );

  const confirm_product_list_pricing_visibility = async (
    visibility: "everywhere" | "internal_users_and_customers" | "internal_users"
  ) => {};

  const handle_sort = async (rules: SortingRule<ProductListPriceSchema>[]) => {
    defaultHandleSort(rules, sorting_rules, set_sorting_rules, set_table_data);
  };

  const change_per_page = (perPage: number) => {
    set_per_page(perPage);
    if (perPage > offset) {
      set_offset(0);
    }
  };

  const change_page = (offset: number) => {
    set_offset(offset);
    set_table_data([]);
  };

  const handle_add_list_price = () => {
    set_show_add_or_edit_product_list(true);
  };

  const on_close_slideout = () => {
    set_list_price_to_edit(undefined);
    set_show_add_or_edit_product_list(false);
  };

  const on_complete_add_or_edit = async () => {
    on_close_slideout();
    await mutate_product_list_prices();
  };

  const table_columns = useMemo(
    () => [
      {
        Header: t("SKU"),
        accessor: "sku_name",
      },
      {
        Header: t("Min. No. of Units"),
        accessor: "minimum_units",
      },
      {
        Header: t("Min. Order Quantity"),
        accessor: "quantity",
      },
      {
        Header: t("Price ($/UoM)"),
        accessor: "price",
      },
      {
        Header: t("Valid Until"),
        accessor: "valid_until",
      },
      {
        Header: "",
        accessor: " ",
        disableSortBy: true,
        Cell: ({ row: { original } }: { row: RowType<TableData> }) => (
          <div style={{ display: "flex", gap: "8px" }}>
            <EditButton
              onClick={() => {
                set_list_price_to_edit(original);
                set_show_add_or_edit_product_list(true);
              }}
            />
            <ButtonWithConfirmDialog
              Button={DeleteButton}
              testid={"remove-list-price-from-product"}
              handleConfirm={() => on_delete_list_price(original)}
              confirmMessage={t(
                "Are you sure you want to remove this list price?"
              )}
            />
          </div>
        ),
      },
    ],
    [on_delete_list_price, t]
  );

  useEffect(() => {
    const handle_pricing_data = ({
      data,
      pagination,
    }: WithPagination<{ data: ProductListPriceSchema[] }>) => {
      set_table_data(
        (data ?? []).reduce<TableData[]>(
          (acc, { valid_until, sku, ...rest }) => [
            ...acc,
            {
              ...rest,
              sku,
              valid_until: formatDate(valid_until),
              sku_name: `${sku.packaging_type.name} (${sku.package_volume} ${sku.packaging_unit.name})`,
            },
          ],
          []
        )
      );
      set_table_pagination({
        perPage: per_page,
        pageCount: Math.ceil((pagination?.total ?? 0) / per_page),
        pageIndex: (pagination?.offset ?? 0) / per_page + 1,
      });
    };
    if (product_list_prices) {
      handle_pricing_data(product_list_prices);
    }
  }, [per_page, product_list_prices]);

  const is_loading = !product_list_prices && !product_list_prices_error;

  return (
    <>
      <HeaderSection>
        <H3 style={{ marginBottom: 0 }}>{t("Manage Visibility")}</H3>
        <SmallSectionHeaderRegular style={{ margin: 0 }}>
          {t("Control visibility of your product pricing")}
        </SmallSectionHeaderRegular>
      </HeaderSection>
      <RadioSection>
        <RadioButton
          name={"manage_visibility"}
          value="everyone"
          checked={pricing_visibility === "everywhere"}
          optionTitle={t("Available Everywhere")}
          handleChange={() => {
            set_manage_visibility_dialog_message(
              t("Are you sure you want all users to see this product pricing?")
            );
            set_transient_pricing_visibility("everywhere");
            set_show_visibility_dialog(true);
          }}
        />
        <RadioButton
          name={"manage_visibility"}
          value="internal_users_and_customers"
          checked={pricing_visibility === "internal_users_and_customers"}
          optionTitle={t("Internal Users and Customers")}
          handleChange={() => {
            set_manage_visibility_dialog_message(
              t(
                "Are you sure you want only customers and internal users to see this product pricing?"
              )
            );
            set_transient_pricing_visibility("internal_users_and_customers");
            set_show_visibility_dialog(true);
          }}
        />
        <RadioButton
          name={"manage_visibility"}
          value="internal_users"
          checked={pricing_visibility === "internal_users"}
          optionTitle={t("Internal Users")}
          handleChange={() => {
            set_manage_visibility_dialog_message(
              t(
                "Are you sure you want only internal users to see this product pricing?"
              )
            );
            set_transient_pricing_visibility("internal_users");
            set_show_visibility_dialog(true);
          }}
        />
      </RadioSection>
      <Row style={{ alignItems: "flex-end", margin: "16px 0" }}>
        <HeaderSection style={{ marginBottom: 0 }}>
          <H3 style={{ marginBottom: 0 }}>{t("List Prices")}</H3>
          <SmallSectionHeaderRegular style={{ margin: 0 }}>
            {t("Set List Price for all customers")}
          </SmallSectionHeaderRegular>
        </HeaderSection>
        <DropDown
          items={[10, 25, 50]}
          activeItem={per_page}
          textLeft={t("items: ")}
          textRight={t("Per Page")}
          direction={"right"}
          className={"per_Page"}
          clickHandler={change_per_page}
        />
      </Row>
      <ContentWrapper>
        <Table
          columns={table_columns}
          data={table_data}
          isLoading={is_loading}
          error={product_list_prices_error}
          handleSort={handle_sort}
          Placeholder={
            <TablePlaceholder
              message={
                !!product_list_prices_error
                  ? t(
                      "There was an error fetching list prices. Please try again later."
                    )
                  : t(
                      "No items to show. Please click the add button to add list prices."
                    )
              }
            />
          }
        />
        <Row style={{ justifyContent: "flex-end", marginTop: "16px" }}>
          <SecondaryButtonWithPlusIcon onClick={handle_add_list_price}>
            <span style={{ fontSize: "15px" }}>{t("New list price")}</span>
          </SecondaryButtonWithPlusIcon>
        </Row>
        <Pagination
          pagination={table_pagination}
          offset={offset}
          handlePageClick={change_page}
        />
      </ContentWrapper>
      <ConfirmDialog
        show={show_visibility_dialog}
        closeDialog={() => {
          set_show_visibility_dialog(false);
          set_transient_pricing_visibility(undefined);
        }}
        confirmMessage={visibility_dialog_message}
        handleConfirm={() => {
          set_pricing_visibility(transient_pricing_visibility!);
          set_show_visibility_dialog(false);
          confirm_product_list_pricing_visibility(
            transient_pricing_visibility!
          );
          set_transient_pricing_visibility(undefined);
        }}
      />
      <SlideOut
        show={show_add_or_edit_product_list}
        closeFlyout={on_close_slideout}
      >
        <AddOrEditProductSkuListPrice
          on_complete={on_complete_add_or_edit}
          list_price={list_price_to_edit}
          product={product}
        />
      </SlideOut>
    </>
  );
};
