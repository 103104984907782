import type { TFunction } from "react-i18next";
import type {
  IPackagingType,
  IPackagingUnit,
  Product,
  ProductApplication,
  SupportedLanguage,
} from "../../types/types";

// For non-logged-in users the UI says "get a quote", but we distinguish
// between full "quote request" data and a smaller set of "lead" data that is
// collected for non-logged-in users.

// Subset of the LeadSchema, e.g. received by GET on the `/cart` endpoint.
export interface Lead {
  id: string;
  items: ICartItem[];
}

// This is the data sent to the server when a user submits a cart, a
// subset of the LeadSchema.
export interface CartSubmission {
  seller_id_or_slug: string;
  cart_id: string;
  buyer_first_name: string;
  buyer_last_name: string;
  buyer_email: string;
  buyer_phone_number: string;
  buyer_company_name: string;
  ulp_id?: string;
  language: SupportedLanguage;
  captcha_token?: string;
  recaptcha_key?: string;
  required_eta?: string;
  shipping_address?: {
    country: string;
    state: string;
    city: string;
    postal_code: string;
    address1: string;
    address2?: string;
    county?: string;
  };
  billing_address?: {
    country: string;
    state: string;
    city: string;
    postal_code: string;
    address1: string;
    address2?: string;
    county?: string;
  };
}

// The cart item data that is received from the server.
export interface ICartItem {
  product: Product;
  // total_volume is a string representing a number, and it can have decimal
  // places.  It is converted to a number type on the server.
  total_volume: string;
  packaging_type: IPackagingType;
  packaging_unit: IPackagingUnit;
  product_applications: ProductApplication[];
  custom_application?: string;
}

// The cart item data that is sent to the server.
export interface CartItemToSend {
  product_id: string;
  packaging_type_id: string;
  packaging_unit_id: string;
  // total_volume is a string representing a number, and it can have decimal
  // places.  It is converted to a number type on the server.
  total_volume: string;
  applications: string[];
  custom_application?: string;
}

/**
 * Convert a cart item received from the server into a cart item to send back.
 * @param item  Cart item from the server.
 * @return  Cart item to send back to the server.
 */
export const prepareCartItemToSend = (item: ICartItem): CartItemToSend => {
  return {
    product_id: item.product.id,
    packaging_type_id: item.packaging_type.id,
    packaging_unit_id: item.packaging_unit.id,
    total_volume: item.total_volume,
    // Convert the product applications to their IDs.
    // Be defensive to avoid any `undefined` IDs.
    applications: item.product_applications
      .map((app) => app.id)
      .filter((id) => id),
    custom_application: item.custom_application,
  };
};

export const newShippingAddress = (t: TFunction, customLabel?: string) => ({
  label: customLabel ? customLabel : t("New Address"),
  value: "new_address",
});
