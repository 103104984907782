import styled, { keyframes, useTheme } from "styled-components/macro";
import type { Assets, GridItemProps } from "../../../../../../types/types.PIM";
import { Fragment, useState } from "react";
import {
  GetDocumentIcon,
  TrimmedName,
  get_content_type,
} from "../util/AssetsUtil";
import {
  H4Normal,
  H5Normal,
  H6,
  SoftHeader,
} from "../../../../../../components/Typography/Typography";
import {
  InvisibleButton,
  SecondaryButtonMedium,
  TertiaryButtonSmall,
} from "../../../../../../components/Buttons/Buttons";
import {
  CaretLeft,
  CaretRight,
  DownloadIcon,
  EditIcon,
  InfoIcon,
  LockIcon,
  NonVisibleIcon,
  UnLockIcon,
  VisibleIcon,
  XIcon,
} from "../../../../../../components/Icons/Icons";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom";
import { useCallback } from "react";
import { useEffect } from "react";
import { AssetDocViewer } from "./AssetDocViewer";
import { SectionTitle } from "../../../../../../components/Form/Form";
import { formatDate, toTitleCase } from "../../../../../../util/util";
import { SlideOut } from "../../../../../../components/SlideOut/SlideOut";

const ModalAnimation = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;

const CloseIconAnimation = keyframes`
 0% { opacity: 0; transform: rotate(270deg)}
 100% { opacity: 1; transform: rotate(0deg)}
 `;

const CloseButton = styled(InvisibleButton)`
  animation-name: ${CloseIconAnimation};
  animation-duration: 0.4s;
`;

const GridModalOverlay = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.overlayColor};
  animation-name: ${ModalAnimation};
  animation-duration: 0.3s;
  z-index: 900;
  overflow: auto;
  display: flex;
`;

const GridModalHeader = styled.div`
  height: 70px;
  width: 100%;
  padding: 16px;
  padding-left: 30px;
  background: ${({ theme }) => theme.primaryBG};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GridModalBody = styled.div`
  height: calc(100% - 70px);
  width: 75%;
  margin-top: 10px;
  background: ${({ theme }) => theme.primaryBG};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const ButtonWrapper = styled(TertiaryButtonSmall)`
  position: absolute;
  top: 50%;
  border-radius: 50%;
  background: ${({ theme }) => theme.primaryBG};
  border: none;
`;

const PreviousButtonWrapper = styled(ButtonWrapper)`
  left: 0;
  margin-left: 20px;
`;

const NextButtonWrapper = styled(ButtonWrapper)`
  right: 0;
  margin-right: 20px;
`;

export const DocumentPreview = ({
  assets,
  onCancelPreview,
  onEdit,
  onDownload,
  selected_asset_idx = 0,
}: {
  assets: (Assets & GridItemProps)[];
  onCancelPreview: () => void;
  onEdit?: (asset: Assets) => void;
  onDownload?: (asset: Assets) => void;
  selected_asset_idx?: number;
}) => {
  const { t } = useTranslation();
  const [current_asset_idx, set_current_asset_idx] =
    useState(selected_asset_idx);
  const [current_asset, set_current_asset] = useState(
    assets[selected_asset_idx]
  );
  const [show_asset_detail, set_show_asset_detail] = useState(false);

  const on_previous = useCallback(() => {
    if (current_asset_idx > 0) {
      set_current_asset_idx(current_asset_idx - 1);
      set_current_asset(assets[current_asset_idx - 1]);
    }
  }, [assets, current_asset_idx]);

  const on_next = useCallback(() => {
    if (current_asset_idx < assets.length - 1) {
      set_current_asset_idx(current_asset_idx + 1);
      set_current_asset(assets[current_asset_idx + 1]);
    }
  }, [assets, current_asset_idx]);

  const keyupFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        // escape button
        onCancelPreview();
      }
      if (event.keyCode === 37) {
        // left arrow button
        on_previous();
      }
      if (event.keyCode === 39) {
        // right arrow button
        on_next();
      }
    },
    [onCancelPreview, on_next, on_previous]
  );

  useEffect(() => {
    document.addEventListener("keyup", keyupFunction);

    return () => {
      document.removeEventListener("keyup", keyupFunction);
    };
  }, [keyupFunction]);

  useEffect(() => {
    set_current_asset(assets[current_asset_idx]);
  }, [assets, current_asset_idx, selected_asset_idx]);

  const action_items = [
    ...(!!onEdit
      ? [
          <SecondaryButtonMedium onClick={() => onEdit(current_asset)}>
            <ActionButtonContainer>
              <EditIcon />
              {t("Edit")}
            </ActionButtonContainer>
          </SecondaryButtonMedium>,
        ]
      : []),
    ...(!!onDownload && current_asset.asset_type !== "image"
      ? [
          <SecondaryButtonMedium onClick={() => onDownload(current_asset)}>
            <ActionButtonContainer>
              <DownloadIcon />
              {t("Download")}
            </ActionButtonContainer>
          </SecondaryButtonMedium>,
        ]
      : []),
    ...(current_asset.can_view_details
      ? [
          <SecondaryButtonMedium
            onClick={() => {
              set_show_asset_detail(true);
            }}
          >
            <ActionButtonContainer>
              <InfoIcon />
              {t("Details")}
            </ActionButtonContainer>
          </SecondaryButtonMedium>,
        ]
      : []),
  ];

  return ReactDOM.createPortal(
    <GridModalOverlay>
      <GridModalHeader>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            maxWidth:
              action_items.length === 3
                ? "70%"
                : action_items.length === 2
                ? "78%"
                : "88%",
          }}
        >
          <GetDocumentIcon
            asset_type={current_asset.asset_type}
            file_type={get_content_type(
              current_asset.content_type ?? "application/pdf"
            )}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              maxWidth: "95%",
            }}
          >
            <TrimmedName text={current_asset.name} Content={H4Normal} />
            <H5Normal>{current_asset.asset_category}</H5Normal>
          </div>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          {action_items.map((item, idx) => (
            <Fragment key={idx}>{item}</Fragment>
          ))}
          <CloseButton onClick={onCancelPreview}>
            <XIcon />
          </CloseButton>
        </div>
      </GridModalHeader>
      <GridModalBody>
        <AssetDocViewer mode="preview" asset={current_asset} />
      </GridModalBody>
      <PreviousButtonWrapper
        onClick={on_previous}
        disabled={current_asset_idx === 0}
      >
        <CaretLeft />
      </PreviousButtonWrapper>
      <NextButtonWrapper
        onClick={on_next}
        disabled={current_asset_idx === assets.length - 1}
      >
        <CaretRight />
      </NextButtonWrapper>
      <SlideOut
        show={show_asset_detail}
        closeFlyout={() => set_show_asset_detail(false)}
      >
        <ItemDetail asset={current_asset} />
      </SlideOut>
    </GridModalOverlay>,
    document.body
  );
};

const ItemDetail = ({ asset }: { asset: Assets }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <SectionTitle>{t("Details")}</SectionTitle>
      <div>
        <SoftHeader>{t("Asset Name")}</SoftHeader>
        <H6>{asset.name}</H6>
      </div>
      <div>
        <SoftHeader>{t("Asset Type")}</SoftHeader>
        <H6>{toTitleCase(asset.asset_type)}</H6>
      </div>
      <div>
        <SoftHeader>{t("Category")}</SoftHeader>
        <H6>{asset.asset_category}</H6>
      </div>
      <div>
        <SoftHeader>{t("Language")}</SoftHeader>
        <H6>{asset.language?.toLocaleUpperCase()}</H6>
      </div>
      <div>
        <SoftHeader>{t("Last Modified")}</SoftHeader>
        <H6>{formatDate(asset.modified_at)}</H6>
      </div>
      <div>
        <SoftHeader>{t("Last Modified By")}</SoftHeader>
        <H6>{asset.modified_by}</H6>
      </div>
      <div>
        <SoftHeader>{t("Accessibility")}</SoftHeader>
        <div
          style={{
            display: "flex",
            gap: "8px",
            paddingTop: "4px",
            alignItems: "center",
          }}
        >
          {asset.asset_type === "document" ? (
            <>{asset.is_downloadable ? <UnLockIcon /> : <LockIcon />}</>
          ) : (
            <>
              {asset.is_downloadable ? (
                <VisibleIcon fill={theme.successIconColor} />
              ) : (
                <NonVisibleIcon />
              )}
            </>
          )}
          <H6>
            {asset.is_downloadable
              ? t("Can be downloaded by external users")
              : t("Cannot be downloaded by external users")}
          </H6>
        </div>
      </div>
    </div>
  );
};
