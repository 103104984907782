import { Link, useLocation, useParams } from "react-router-dom";
import { PageWrapper } from "../../../../../../layout/portalPageLayout";
import { useRoutePath } from "../../../../../../util/Routing";
import {
  EditButton,
  GoBackButtonSmall,
  PrimaryButtonWithPlusIcon,
} from "../../../../../../components/Buttons/Buttons";
import { TemplatesNav } from "../TemplatesNav";
import {
  TablePlaceholder,
  toTitleCase,
  useStoreState,
} from "../../../../../../util/util";
import useSWR from "swr";
import type {
  AttributeTemplateSchema,
  TDSGeneratedAssetRequestSchema,
  TdsGeneratedAssetSchema,
} from "../../../../../../types/types.PIM";
import type { AxiosError } from "axios";
import { SlideOut } from "../../../../../../components/SlideOut/SlideOut";
import { ContentWrapper } from "../../../../../../layout/publicPageLayout";
import { Table } from "../../../../../../components/Table/Table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToggleSwitch } from "../../../../../../components/ToggleSwitch/ToggleSwitch";
import { ConfigureGeneratedAssets } from "./ConfigureGeneratedAssets";
import { endpoints } from "../../../../../../endpoints";
import { DelayedSpinner } from "../../../../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../../../../components/Error";
import { useAuthContext } from "../../../../../../components/Auth";
import axios from "axios";
import { useNotifications } from "../../../../../../components/Notifications/NotificationsContext";
import { LoadingIcon } from "../../../../../../components/Icons/Icons";

export const GeneratedAssetsSettingsPage = () => {
  const { adminPath } = useRoutePath();
  const { template_id } = useParams<{ template_id: string }>();
  const { tenant_id } = useStoreState();
  const { t } = useTranslation();
  const location = useLocation();
  const { hasPermission } = useAuthContext();
  const { notifyError } = useNotifications();

  const [showSlideout, setShowSlideout] = useState(false);
  const [loadingId, setLoadingId] = useState<string>();

  const [tableData, setTableData] = useState<TdsGeneratedAssetSchema[]>([]);

  const [generatedAsset, setGeneratedAsset] =
    useState<TdsGeneratedAssetSchema>();

  const { data: template, error: templateFetchError } = useSWR<
    AttributeTemplateSchema,
    AxiosError
  >(`/v2/tenants/${tenant_id}/pim/templates/${template_id}`);

  const {
    data: tdsGeneratedAsset,
    error: tdsGeneratedAssetError,
    mutate,
  } = useSWR<TdsGeneratedAssetSchema[], AxiosError>(
    endpoints.v2_tenants_tenant_id_templates_template_id_generated_assets(
      tenant_id,
      template_id
    )
  );

  const updateGenerateAsset = useCallback(
    (is_active: boolean, generatedAsset: TdsGeneratedAssetSchema) => {
      const updateAsset = async () => {
        const { language, footer_content, title } = generatedAsset;
        const requestBody: TDSGeneratedAssetRequestSchema = {
          language,
          footer_content: footer_content ?? "",
          title,
          is_active,
        };
        try {
          setLoadingId(generatedAsset.id);
          await axios.patch<TdsGeneratedAssetSchema>(
            endpoints.v2_tenants_tenant_id_templates_template_id_generated_assets_id(
              tenant_id,
              template_id,
              generatedAsset.id
            ),
            requestBody
          );
          await mutate();
        } catch (error) {
          const errorMessage = (error as AxiosError)?.response?.data?.message;
          notifyError(
            errorMessage
              ? errorMessage
              : t("There was an error configuring the generated assets"),
            {
              error,
            }
          );
        } finally {
          setLoadingId(undefined);
        }
      };
      updateAsset();
    },
    [mutate, notifyError, t, template_id, tenant_id]
  );

  const tableColumns = useMemo(
    () => [
      {
        Header: t("Asset Type"),
        accessor: "asset_type",
        Cell: ({ value }: { value: string }) => <div>{toTitleCase(value)}</div>,
      },
      {
        Header: t("Category"),
        accessor: "asset_category",
        Cell: ({ value }: { value: string }) => (
          <div>{(value ?? "tds").toUpperCase()}</div>
        ),
      },
      {
        Header: t("No. of assets"),
        accessor: "number_of_assets",
      },
      {
        Header: `${t("Enable")}/${t("Disable")}`,
        accessor: "is_active",
        Cell: ({
          value,
          row: { original },
        }: {
          value: boolean;
          row: { original: TdsGeneratedAssetSchema };
        }) =>
          loadingId === original.id ? (
            <LoadingIcon width={16} height={18} />
          ) : (
            <ToggleSwitch
              isChecked={value}
              onClick={() => {
                updateGenerateAsset(!value, original);
              }}
            />
          ),
      },
      {
        Header: "",
        accessor: " ",
        Cell: ({
          row: { original },
        }: {
          row: { original: TdsGeneratedAssetSchema };
        }) => (
          <EditButton
            onClick={() => {
              setGeneratedAsset(original);
              setShowSlideout(true);
            }}
          />
        ),
      },
    ],
    [loadingId, t, updateGenerateAsset]
  );

  useEffect(() => {
    if (tdsGeneratedAsset && tdsGeneratedAsset.length) {
      setTableData(
        tdsGeneratedAsset.map((generatedAsset) => ({
          ...generatedAsset,
          number_of_assets: generatedAsset?.number_of_assets ?? 0,
        }))
      );
    }
  }, [tdsGeneratedAsset]);

  const isLoading =
    (!template && !templateFetchError) ||
    (!tdsGeneratedAsset && !tdsGeneratedAssetError);

  if (templateFetchError || tdsGeneratedAssetError) {
    return (
      <ErrorPlaceholder
        message={t(
          "There was an error fetching generated assets. Please try again later."
        )}
      />
    );
  }
  return isLoading ? (
    <DelayedSpinner />
  ) : (
    <PageWrapper>
      <div style={{ width: "fit-content" }}>
        <Link
          to={{
            pathname: `${adminPath}/pim/templates`,
            state: location.state, // Preserve the state when going back
          }}
        >
          <GoBackButtonSmall text={t("Back")} />
        </Link>
      </div>
      <TemplatesNav
        pageTitle={template?.template_name ?? t("Template")}
        tabIndex={2}
        templateID={template_id}
      />
      <SlideOut closeFlyout={() => setShowSlideout(false)} show={showSlideout}>
        <ConfigureGeneratedAssets
          template={template!}
          generatedAsset={generatedAsset}
          onSuccess={async () => {
            await mutate();
            setShowSlideout(false);
          }}
        />
      </SlideOut>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          isLoading={false}
          error={templateFetchError}
          Placeholder={
            <TablePlaceholder
              message={t(
                "No items to show. Please click the button below to add items."
              )}
            />
          }
          data={tableData}
          lastChildleftAlign
        />
        {hasPermission("modify_templates") && tableData.length === 0 && (
          <PrimaryButtonWithPlusIcon
            style={{ marginTop: "24px" }}
            onClick={() => {
              setGeneratedAsset(undefined);
              setShowSlideout(true);
            }}
            title={t("Add Document Generation Configuration")}
          >
            {t("Add Document Generation Configuration")}
          </PrimaryButtonWithPlusIcon>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};
