import React, { useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import { Table } from "../../../components/Table/Table";
import type { IPagination, Lead, User } from "../../../types/types";
import { Pagination } from "../../../components/Pagination/Pagination";
import { useHistory, useLocation } from "react-router-dom";
import {
  convertStringArrayToObj,
  fetcher,
  formatDate,
  makeUrlWithParams,
  rowHover,
  useInAppNotifications,
  useStoreState,
} from "../../../util/util";
import { StatusRight } from "../../../components/Status/Status";
import { useRoutePath } from "../../../util/Routing";
import { formateInternationalPhoneNumber } from "../../../util/phone";
import type { StatusCellProps } from "./leadsUtilities";
import { LeadStatusFilters, getLeadStatusDetails } from "./leadsUtilities";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import { useTranslation } from "react-i18next";
import { FiltersDropDown } from "../../../components/FiltersDropDown/FiltersDropDown";
import type { FiltersDropDownItem } from "../../../components/FiltersDropDown/FiltersDropDown";
import { FiltersWrapper } from "../../../layout/portalPageLayout";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import { useDebounce } from "../../../util/hooks";

type RegistrationListProps = {
  perPage: number;
  user: User;
  tabIndex: number;
};

type LeadsAPIResponse = {
  data: Lead[];
  pagination: IPagination;
};

type LeadsRegistrationTableData = {
  id: string;
  companyName: string;
  fullName: string;
  email: string;
  phone: string;
  received: string;
};

type ApplyFiltersToURL = {
  params: URLSearchParams;
};

function applyFiltersToURL({ params }: ApplyFiltersToURL): URLSearchParams {
  return params;
}

export const RegistrationList = ({
  perPage,
  user,
  tabIndex,
}: RegistrationListProps) => {
  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    status: ArrayParam,
    q: StringParam,
  });
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const { adminPath } = useRoutePath();
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const { leadsNotifications } = useInAppNotifications(
    storefront_id,
    user as User
  );

  const [selectedStatus, setSelectedStatus] = useState<FiltersDropDownItem[]>(
    []
  );
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [selectedStatusList, setSelectedStatusList] = useState<string[]>(
    (query?.status?.filter((status) => !!status) as string[]) ?? []
  );

  function constructURL() {
    const BASE_URL = `/v1/storefronts/${storefront_id}/leads/register?`;

    const params = new URLSearchParams(
      `offset=${offset as number}&limit=${perPage}&q=${debouncedSearchQuery}`
    );

    if (selectedStatusList.length) {
      selectedStatusList.forEach((param) => params.append("status", param));
    }

    return (
      BASE_URL +
      applyFiltersToURL({
        params: params,
      })
    );
  }

  const { data: leadsResponse, error: leadsError } = useSWR<LeadsAPIResponse>(
    constructURL(),
    fetcher
  );

  const isLoading = !leadsResponse && !leadsError;

  //table
  const [tableData, setTableData] = useState<LeadsRegistrationTableData[]>([]);

  // Placeholder condition for showing 'Region' column
  const [showRegion, setShowRegion] = useState(false);
  useEffect(() => {
    if (leadsResponse) {
      // Check if 'region' is present in any of the sample requests
      const hasRegion = leadsResponse.data.some((lead) => "region" in lead);
      setShowRegion(hasRegion);
    }
  }, [leadsResponse]);
  const tableColumns = React.useMemo(
    () => [
      {
        Header: t("Customer Name"),
        accessor: "fullName",
      },
      {
        Header: t("Company Name"),
        accessor: "companyName",
      },
      {
        Header: t("Email"),
        accessor: "email",
      },
      ...(showRegion
        ? [
            {
              Header: t("Region"),
              accessor: "region",
            },
          ]
        : []),
      {
        Header: t("Date Received"),
        accessor: "received",
      },
      {
        Header: t("Assignee"),
        accessor: "assignee",
      },
      {
        Header: t("Status"),
        accessor: "status",
        align: "right",
        Cell: ({ value }: { value: StatusCellProps }) => {
          return <StatusRight color={value?.color} text={value?.text} />;
        },
      },
    ],
    [t, showRegion]
  );

  const handleLeadsData = useCallback(
    ({ data, pagination }: LeadsAPIResponse) => {
      const getUnread = (registration: Lead): boolean => {
        return leadsNotifications?.registrations.ids
          ? leadsNotifications?.registrations.ids.includes(registration?.id)
          : false;
      };
      setTableData(
        data.map((lead: Lead) => ({
          id: lead.id,
          companyName: lead.buyer_company_name || "--",
          fullName: `${lead.buyer_first_name} ${lead.buyer_last_name}` || "--",
          assignee: lead.assignee
            ? `${lead.assignee?.firstname} ${lead.assignee?.lastname}`
            : "--",
          email: lead.buyer_email || "--",
          phone: lead.buyer_phone_number
            ? formateInternationalPhoneNumber(lead.buyer_phone_number)
            : "--",
          received: formatDate(lead.created_at),
          status: {
            ...getLeadStatusDetails(lead.status, t),
            lead: lead,
          },
          unread: getUnread(lead),
          region: lead?.region || "--",
        }))
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    },
    [perPage, leadsNotifications?.registrations.ids, t]
  );

  useEffect(() => {
    if (leadsResponse) {
      handleLeadsData(leadsResponse);
    }
  }, [leadsResponse, handleLeadsData]);

  useEffect(() => {
    setQuery({ offset, status: selectedStatusList });
    const statusObj = convertStringArrayToObj(selectedStatusList);
    setSelectedStatus(
      LeadStatusFilters(t).filter((item) => !!statusObj[item.value])
    );
  }, [offset, perPage, selectedStatusList, setQuery, t]);

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const applyStatus = (items: FiltersDropDownItem[]) => {
    setSelectedStatus(items);
    setSelectedStatusList(items.map((item) => item.value));
    changePage(0);
  };

  const handleRowClick = (e: React.MouseEvent) => {
    history.push(
      makeUrlWithParams(
        `${adminPath}/leads/registration/${e.currentTarget.id}`,
        { offset, perPage }
      ),
      {
        listState: {
          offset,
          perPage,
          selectedStatusList,
          searchQuery,
          tabIndex,
        },
      }
    );
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  useEffect(() => {
    const listState = (location.state as any)?.listState;
    if (listState) {
      setOffset(listState.offset ?? 0);
      setSelectedStatusList(listState.selectedStatusList ?? []);
      setSearchQuery(listState.searchQuery ?? "");

      // Clear the state after restoring it
      history.replace({ ...location, state: undefined });
    }
  }, [location, history]);

  return (
    <>
      <FiltersWrapper>
        <FiltersDropDown
          activeItems={selectedStatus}
          applyStatus={applyStatus}
          list={LeadStatusFilters(t)}
        />
        <SearchBar
          query={searchQuery}
          placeHolder={t("Search by Company Name, Customer or Assignee")}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
      </FiltersWrapper>

      <Table
        columns={tableColumns}
        data={tableData}
        isLoading={isLoading}
        error={leadsError}
        rowClick={handleRowClick}
        rowHover={rowHover}
      />
      <Pagination
        pagination={tablePagination}
        offset={offset}
        handlePageClick={changePage}
      />
    </>
  );
};
