import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import {
  PrimaryButtonWithShareIcon,
  ShareDialogButton,
} from "../../../components/Buttons/Buttons";
import type { ShareOption } from "../../../components/Buttons/Buttons";
import { useTranslation } from "react-i18next";
import {
  FacebookIcon,
  LinkedinIcon,
  LinkIcon,
  TwitterIcon,
  WhatsappIcon,
} from "../../../components/Icons/Icons";
import type { LinkType, Platform, ProductInfo } from "../../../types/types";
import { useRoutePath } from "../../../util/Routing";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { Helmet } from "react-helmet";
import { useStoreState } from "../../../util/util";

const PaddedPrimaryShareButton = styled(PrimaryButtonWithShareIcon)`
  padding: 8px 16px;
  white-space: nowrap;
  svg {
    vertical-align: bottom;
  }
  margin-bottom: 5px;
`;

export const ProductShareButton = ({
  productId,
  slug,
  name,
  produced_by,
  isLinkDisabled,
  isConnectionOn,
  productImage,
}: {
  productId: string;
  slug: string;
  name: string;
  produced_by: string;
  isLinkDisabled: boolean;
  isConnectionOn: boolean | undefined;
  productImage?: string;
}) => {
  const { t } = useTranslation();

  const theme = useContext(ThemeContext);

  const { adminPath, storePath } = useRoutePath();

  const { storefront_metadata } = useStoreState();

  const { notifyError, notifySuccess } = useContext(Notifications);

  const [isDialogOpen, setDialogOpen] = useState(false);

  const productInfo: ProductInfo = {
    internalLink: `${window.location.origin}${adminPath}/pim/products/${productId}`,
    externalLink: `${window.location.origin}${storePath}/product/${slug}`,
    title: `${name}`,
    description: `Check out ${name} ${produced_by ? `by ${produced_by}` : ``} 
    on the ${storefront_metadata.tenant_name} portfolio`,
    productImage: productImage,
  };

  const handleShare = (platform: Platform) => {
    let url: string | undefined;

    switch (platform) {
      case "linkedin":
        url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          productInfo.externalLink
        )}&title=${encodeURIComponent(
          productInfo.title
        )}&summary=${encodeURIComponent(productInfo.description)}`;
        break;
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          productInfo.externalLink
        )}`;
        break;
      case "whatsapp":
        url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          productInfo.title
        )}%0A${encodeURIComponent(productInfo.externalLink)}`;
        break;
      case "x":
        url = `https://x.com/intent/tweet?url=${encodeURIComponent(
          productInfo.externalLink
        )}&text=${encodeURIComponent(productInfo.title)}`;
        break;
      default:
        return;
    }

    if (url) {
      window.open(url, "_blank");
    }
  };

  const handleCopy = (linkType: LinkType) => {
    const linkToCopy =
      linkType === "internal"
        ? productInfo.internalLink
        : productInfo.externalLink;

    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        notifySuccess(t("Link copied"));
      })
      .catch(() => {
        notifyError(t("Failed to copy"));
      });
  };

  const internalItems: ShareOption[] = [
    {
      label: t("Copy internal product link"),
      icon: <LinkIcon fill={theme.disabledButtonTextColor} />,
      onClick: () => handleCopy("internal"),
    },
  ];
  const externalItems: ShareOption[] = [
    {
      label: t("Copy external product link"),
      icon: <LinkIcon fill={theme.disabledButtonTextColor} />,
      onClick: () => handleCopy("external"),
      disabled: isLinkDisabled || !isConnectionOn,
    },
    {
      label: t("Share on LinkedIn"),
      icon: (
        <LinkedinIcon
          fill={isLinkDisabled ? theme.disabledButtonTextColor : "#0A66C2"}
        />
      ),
      onClick: () => handleShare("linkedin"),
      disabled: isLinkDisabled,
    },
    {
      label: t("Share on Facebook"),
      icon: (
        <FacebookIcon
          fill={isLinkDisabled ? theme.disabledButtonTextColor : "#0A66C2"}
        />
      ),
      onClick: () => handleShare("facebook"),
      disabled: isLinkDisabled,
    },
    {
      label: t("Share on WhatsApp"),
      icon: (
        <WhatsappIcon
          fill={isLinkDisabled ? theme.disabledButtonTextColor : "#000000"}
        />
      ),
      onClick: () => handleShare("whatsapp"),
      disabled: isLinkDisabled,
    },
    {
      label: t("Share on X"),
      icon: (
        <TwitterIcon
          fill={isLinkDisabled ? theme.disabledButtonTextColor : "#000000"}
        />
      ),
      onClick: () => handleShare("x"),
      disabled: isLinkDisabled,
    },
  ];

  return (
    <div>
      <Helmet>
        <meta property="og:title" content={name} />
        {productImage && <meta property="og:image" content={productImage} />}
        <meta property="og:description" content={productInfo.description} />
      </Helmet>
      <PaddedPrimaryShareButton
        data-alignright
        onClick={() => setDialogOpen(true)}
      >
        {t("Share")}
      </PaddedPrimaryShareButton>

      <ShareDialogButton
        isOpen={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        heading={t("Share this product")}
        internalItems={internalItems}
        externalItems={externalItems}
        isConnectionOn={isConnectionOn}
        isLinkDisabled={isLinkDisabled}
      />
    </div>
  );
};
