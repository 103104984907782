import { useTranslation } from "react-i18next";
import { SectionTitle } from "../../../../../../components/Form/Form";
import type { MethodsOfUseForm } from "../../../../../../types/types";
import type {
  Assets,
  AttributeSchema,
  ImageAttributeValue,
} from "../../../../../../types/types.PIM";
import { RadioButtonContainer } from "../../../../../../layout/FormLayout";
import { RadioButton } from "../../../../../../components/RadioButton/RadioButton";
import { useState } from "react";
import {
  H6,
  SoftHeader,
} from "../../../../../../components/Typography/Typography";
import { ExistingImageAttribute } from "./ExistingImageAttributes";
import { NewImageAttributeForm } from "./NewImageAttributeForm";
import { HeaderLeft } from "../../../../../../components/Layout/Layout";
import { FormProvider, useForm } from "react-hook-form";

export const AddOrEditImageAttribute = ({
  attribute,
  onComplete,
  type,
  onCancel,
  methodsOfUseForm,
  existing_asset,
  image_attribute_value,
}: {
  attribute: AttributeSchema;
  methodsOfUseForm?: MethodsOfUseForm;
  onComplete: (image_attr: ImageAttributeValue | null) => void;
  onCancel: () => void;
  type: "add" | "edit";
  existing_asset?: Assets;
  image_attribute_value?: ImageAttributeValue;
}) => {
  const [is_existing_media, set_is_existing_media] = useState<boolean>(true);
  const { t } = useTranslation();

  const use_form = useForm();

  const handle_radio_change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (value === "search" && checked) {
      set_is_existing_media(true);
    } else if (value === "upload" && checked) {
      set_is_existing_media(false);
    }
  };

  return (
    <>
      <HeaderLeft>
        <SectionTitle>
          {type === "add" ? t("Add Image") : t("Edit Image")}
        </SectionTitle>
      </HeaderLeft>
      <SoftHeader>{t("Attribute Name")}</SoftHeader>
      <H6 style={{ marginBottom: "24px" }}>{attribute.name}</H6>
      <RadioButtonContainer>
        <RadioButton
          name="media"
          value="search"
          optionTitle={t(`Search from existing media`)}
          handleChange={handle_radio_change}
          checked={is_existing_media}
        />
        <RadioButton
          name="media"
          value="upload"
          optionTitle={t(`Upload new media`)}
          handleChange={handle_radio_change}
          checked={!is_existing_media}
        />
      </RadioButtonContainer>
      <FormProvider {...(methodsOfUseForm ? methodsOfUseForm : use_form)}>
        {is_existing_media ? (
          <ExistingImageAttribute
            attribute={attribute}
            onComplete={onComplete}
            onCancel={onCancel}
            existing_asset={existing_asset}
            image_attribute_value={image_attribute_value}
          />
        ) : (
          <NewImageAttributeForm
            attribute={attribute}
            onComplete={onComplete}
            onCancel={onCancel}
          />
        )}
      </FormProvider>
    </>
  );
};
