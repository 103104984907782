import type { AxiosError } from "axios";
import React, { useContext, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  DeleteButton,
  PrimaryButtonFitContainer,
  SecondaryButtonWithPlusIcon,
} from "../../../../../../components/Buttons/Buttons";
import { SectionTitle } from "../../../../../../components/Form/Form";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import { Form } from "../../../../../../layout/FormLayout";
import type { OptionType } from "../../../../../../types/types";
import type {
  AttributeSchema,
  CollectionColumnCreationSchema,
  CollectionPatchArgsSchema,
  CollectionRowCreationSchema,
  CollectionRowSchema,
  CollectionSchema,
} from "../../../../../../types/types.PIM";
import { useFormWrapper, useStoreState } from "../../../../../../util/util";
import {
  MarginBottomH6,
  MarginBottomHeaderLeft,
} from "../../SellerAdminPIMAttributes/CreateAttribute";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Axios from "axios";
import { endpoints } from "../../../../../../endpoints";
import { SelectWithDeleteButton } from "../../../../../../layout/shared";
import { getNameFromLabel } from "../../SellerAdminPIMAttributeGroups/AddAttributeToExistingGroup";
import { findNonEmptyDuplicateIndexes } from "../../../../../../util/form.utils";
import { AsyncSearchSelect } from "../../../../../../components/AsyncSearchSelect/AsyncSearchSelect";
import { useHandleAttributesAsyncSearch, zodSelectAttribute } from "../util";
import { ConfirmDialog } from "../../../../../../components/ConfirmDialog/ConfirmDialog";

const AddAttributeSchema = z.object({
  first_attribute: z.object({
    value: z.string().min(1),
    label: z.string().min(1),
    display_name: z.string(),
  }),
  collection: z
    .object({
      attribute: z.object({
        value: z.string().min(1),
        label: z.string().min(1),
        display_name: z.string(),
      }),
    })
    .array()
    .optional(),
});

const AddAttributeSchemaFn = (t: (s: string) => string, attributes: string[]) =>
  z
    .object({
      first_attribute: zodSelectAttribute(t).refine(
        ({ label }) =>
          findNonEmptyDuplicateIndexes([getNameFromLabel(label), ...attributes])
            .length === 0,
        t("Duplicate attributes not allowed")
      ),
      collection: z
        .object({
          attribute: zodSelectAttribute(t),
        })
        .array()
        .optional(),
    })
    .superRefine(({ first_attribute, collection }, ctx) => {
      const duplicateIdxs = collection
        ? findNonEmptyDuplicateIndexes([
            getNameFromLabel(first_attribute.label),
            ...attributes,
            ...collection.map(({ attribute: { label } }) =>
              getNameFromLabel(label)
            ),
          ])
        : [];
      if (duplicateIdxs.length > 0) {
        duplicateIdxs.forEach((idx) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("Duplicate attributes not allowed"),
            path: [`collection[${idx - (attributes.length + 1)}].attribute`],
          });
        });
      }
    });

type FormOutput = z.infer<typeof AddAttributeSchema>;

export const attributeToCollectionColumnCreation = ({
  id,
  display_name,
  name,
}: AttributeSchema): CollectionColumnCreationSchema => ({
  attribute_id: id,
  display_name: display_name ? display_name : name,
  is_required: true,
  is_editable: true,
});

export const collectionRowToCollectionRowCreation = ({
  id,
}: CollectionRowSchema): CollectionRowCreationSchema => ({
  attribute_id: id,
  default_values: [],
});

const addAttributesPatchBody = (
  attributes: (OptionType<string> & { display_name: string })[],
  collection: CollectionSchema
): CollectionPatchArgsSchema => {
  const { id, ...rest } = collection;
  return {
    ...rest,
    description: rest.description ?? "",
    columns: [
      ...rest.columns.map(attributeToCollectionColumnCreation),
      ...attributes.map(({ value: id, display_name }) => ({
        attribute_id: id,
        display_name,
        is_required: false,
        is_editable: true,
      })),
    ],
    rows: rest.rows
      ? [...rest.rows.map(collectionRowToCollectionRowCreation)]
      : [],
  };
};

export const AddAttributeToExistingCollectionForm = ({
  collection,
  onSuccess,
  isTemplateInUse = false,
}: {
  collection: CollectionSchema;
  onSuccess: () => void;
  isTemplateInUse?: boolean;
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [formValue, setFormValue] = useState<FormOutput>();
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { handleAttributesSearchNoMultiline } =
    useHandleAttributesAsyncSearch("collection");

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(
      AddAttributeSchemaFn(
        t,
        collection.columns.map((column) => column.name)
      )
    ),
  });
  const { handleSubmit, control, formState, errors } = methodsOfUseForm;
  const { notifyError, notifySuccess } = useContext(Notifications);

  const { fields, remove, append } = useFieldArray({
    control,
    name: "collection",
  });

  const createFields = (): JSX.Element[] => {
    return fields.map((field, index: number) => (
      <SelectWithDeleteButton key={field.id}>
        <Controller
          key={field.id}
          as={AsyncSearchSelect}
          control={control}
          name={`collection[${index}].attribute`}
          defaultValue={{ label: "", value: "" }}
          placeholder={t("Select Attribute")}
          searchFunction={handleAttributesSearchNoMultiline}
          errors={{
            [`collection[${index}].attribute`]:
              errors?.collection?.[index]?.attribute?.value ??
              errors?.collection?.[index]?.attribute ??
              undefined,
          }}
          formState={formState}
          defaultOptions
        />
        <DeleteButton
          testid={`delete-button-${index}`}
          onClick={() => remove(index)}
          type="button"
          height={20}
          width={20}
        />
      </SelectWithDeleteButton>
    ));
  };

  const processForm = async ({
    first_attribute,
    collection: collectionArr,
  }: FormOutput) => {
    const attrs = collectionArr
      ? [first_attribute, ...collectionArr.map(({ attribute }) => attribute)]
      : [first_attribute];
    try {
      await Axios.patch(
        endpoints.v2_tenants_id_pim_collections_id(tenant_id, collection.id),
        addAttributesPatchBody(attrs, collection)
      );
      notifySuccess(t("Attribute(s) added successfully"));
      onSuccess();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error modifying the collection"),
        {
          error,
        }
      );
    }
  };

  const onSubmit = async (formOutput: FormOutput) => {
    if (isTemplateInUse) {
      setShowDialog(true);
      setFormValue(formOutput);
    } else {
      await processForm(formOutput);
    }
  };

  return (
    <>
      <MarginBottomHeaderLeft>
        <SectionTitle>{t("Add Attributes")}</SectionTitle>
      </MarginBottomHeaderLeft>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <MarginBottomH6>
          {t("Select from existing list of attributes")}
        </MarginBottomH6>
        <div style={{ width: "420px" }}>
          <Controller
            as={AsyncSearchSelect}
            control={control}
            name="first_attribute"
            placeholder={t("Select Attribute")}
            defaultValue={{ label: "", value: "" }}
            searchFunction={handleAttributesSearchNoMultiline}
            errors={errors}
            formState={formState}
            defaultOptions
          />
        </div>
        {createFields()}
        <SecondaryButtonWithPlusIcon
          type="button"
          style={{ marginBottom: "40px" }}
          onClick={() => append({ name: "" })}
        >
          {t("Add attribute")}
        </SecondaryButtonWithPlusIcon>
        <PrimaryButtonFitContainer type="submit">
          {t("Add")}
        </PrimaryButtonFitContainer>
      </Form>
      <ConfirmDialog
        show={showDialog}
        closeDialog={() => {
          setFormValue(undefined);
          setShowDialog(false);
        }}
        confirmMessage={t(
          "Any changes will be pushed down to all products using this template. Do you want to continue?"
        )}
        handleConfirm={async () => {
          await processForm(formValue!);
          setShowDialog(false);
        }}
      />
    </>
  );
};
