import { PrimaryButtonFitContainer } from "../Buttons/Buttons";
import type { ChipType } from "../Chips/Chips";
import { Chips } from "../Chips/Chips";
import { HeaderLeft } from "../Layout/Layout";
import { TextField } from "../TextFields/TextFields";
import {
  Flex,
  Flex1,
  Flex2,
  Form,
  FormGrid2x2,
  RadioButtonContainer,
} from "../../layout/FormLayout";
import type {
  ITenantPaginatedOutputResponse,
  OptionType,
  SupportedLanguage,
  UserType,
  UserTypeChip,
  UUID,
  TenantType,
  AgilisUsersPaginatedOutput,
  LeadAddress,
} from "../../types/types";
import * as yup from "yup";
import "yup-phone";
import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "../../layout/portalPageLayout";
import type { AxiosError } from "axios";
import Axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import type { ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import { Notifications } from "../Notifications/NotificationsContext";
import { strings } from "../../util/strings";
import {
  useStoreState,
  useFormWrapper,
  useSupportedLanguages,
  makeUrlWithParams,
  TEMPORARY_HIGH_LIMIT,
  convertUserToOption,
} from "../../util/util";
import { endpoints } from "../../endpoints";
import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import {
  countryCodeMap,
  getCountryCode,
  getPhoneCodeOption,
  getPhoneCodesOptions,
  getPhoneNumber,
} from "../../util/phone";
import { CheckBoxNoLabel } from "../CheckBoxes/CheckBoxes";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
  SectionTitle,
} from "../Form/Form";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../Auth";
import useSWR from "swr";
import type { RoleSummary } from "../../types/types.PIM";
import { H3 } from "../Typography/Typography";
import { AsyncSearchSelect } from "../AsyncSearchSelect/AsyncSearchSelect";
import debounce from "lodash/debounce";
import { useRoutePath } from "../../util/Routing";
import { useHistory } from "react-router-dom";
import { ToggleSwitch, ToggleSwitchV2 } from "../ToggleSwitch/ToggleSwitch";
import { Card } from "../../layout/publicPageLayout";
import { useCountriesList, useStatesList } from "../../util/Locations";
import {
  getCountryOption,
  getStateOption,
  getStatesInputLabel,
  getZipCodeInputLabel,
} from "../../util/location";
import { RadioButton } from "../RadioButton/RadioButton";

interface FormInputs {
  customer_search: OptionType;
  firstname: string;
  lastname: string;
  email_address: string;
  crm_id?: string;
  phone_number: string;
  preferred_language: OptionType<SupportedLanguage>;
  country_code: OptionType;
  user_type_chip: UserTypeChip;
  role?: OptionType<UUID>;
  quote_threshold_usd: number;
  order_threshold_usd: number;
  quote_threshold_local: number;
  order_threshold_local: number;
  quote_threshold_usd_unlimited: boolean;
  quote_threshold_default_unlimited: boolean;
  order_threshold_usd_unlimited: boolean;
  order_threshold_default_unlimited: boolean;
  leads_notification: boolean;
  leads_enable_quote_request: boolean;
  leads_enable_sample_request: boolean;
  leads_enable_registration: boolean;
  leads_enable_contact_us: boolean;
  transactions_notification: boolean;
  transactions_enable_order: boolean;
  transactions_enable_sample_request: boolean;
  transactions_enable_quote_request: boolean;
  userCheckbox: boolean;
  shippingCheckbox: boolean;
  country: OptionType;
  street_name: string;
  address2: string | null;
  state: OptionType;
  city: string;
  postal_code: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_email_address: string;
  contact_country_code: OptionType;
  contact_phone_number: string;
  point_of_contact_id: OptionType;
}

type LeadData = {
  firstname: string;
  lastname: string;
  email_address: string;
  phone_number: string;
  type?: "sample_request" | "lead";
  lead_id: string;
  existing_customer: {
    company_name: string;
    customer_id: string;
  };
  address?: LeadAddress | null;
};

type CreateNewUserProps = {
  tenantId: string;
  userTypeChips: UserTypeChip[];
  defaultUserType: UserType;
  userFormType: "tenant" | "customer_distributor" | "add_to_Existing_customer";
  leadToUserData?: LeadData;
  onSuccess: () => void;
  tenantTypeToFetch: Extract<TenantType, "Buyer" | "Distributor">;
  showShippingAddress?: boolean;
};

const CheckBoxAndTextField = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
`;

const ToggleSwitchWrapper = styled.div`
  margin: 20px 0;
`;
/**
 * if the storefront is not pim-only edition and the user is not admin
 */

export function roleSummaryToOption(roles: RoleSummary): OptionType<UUID>[] {
  return roles.map((role) => ({ value: role.id, label: role.name }));
}

function getUserType(role: string | undefined) {
  if (!role) {
    return null;
  } else if (role === "Admin") {
    return "Seller Admin";
  } else return "Seller Standard";
}

export function CreateNewUser({
  tenantId,
  userTypeChips,
  defaultUserType,
  leadToUserData,
  userFormType,
  onSuccess,
  tenantTypeToFetch,
  showShippingAddress = false,
}: CreateNewUserProps) {
  const {
    storefront_id,
    storefront_metadata: { default_currency, edition },
  } = useStoreState();
  const [loading, setLoading] = useState(false);
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { roleIsSomeKindOfBuyer, roleIsSellerAdmin, user } = useAuthContext();
  const [isRequiredQuoteUSD, setIsRequiredQuoteUSD] = useState(false);
  const [isRequiredQuoteDefault, setIsRequiredQuoteDefault] = useState(false);
  const [isRequiredOrderUSD, setIsRequiredOrderUSD] = useState(false);
  const [isRequiredOrderDefault, setIsRequiredOrderDefault] = useState(false);
  const [isRequiredUserCheckbox, setIsRequiredUserCheckbox] = useState(false);
  const [isRequiredShippingCheckbox, setIsRequiredShippingCheckbox] =
    useState(false);
  const [isRequiredExistingUser, setIsRequiredExistingUser] = useState(false);
  const [isRequiredManualUser, setIsRequiredManualUser] = useState(false);
  const [userNotifications, setUserNotifications] = useState({
    leads_enable_quote_request: true,
    leads_enable_sample_request: true,
    leads_enable_registration: true,
    leads_enable_contact_us: true,
  });
  const { supportedLanguageOptions } = useSupportedLanguages();
  const { adminPath } = useRoutePath();
  const history = useHistory();
  const [customersList, setCustomersList] = useState<OptionType[]>([]);
  const [pointOfContactType, setPointOfContactType] =
    useState<string>("existing");
  const [useHqAddress, setUseHqAddress] = useState(false);
  const [isSwitchDisabled, setSwitchDisabled] = useState(false);
  const [storedCountry, setStoredCountry] = useState(
    leadToUserData?.address?.country === "USA"
      ? "US"
      : leadToUserData?.address?.country
  );
  const countries = useCountriesList();

  const foundDefaultUserChip = userTypeChips.find(
    (chip) => chip.id === defaultUserType
  );
  if (!foundDefaultUserChip) {
    console.warn("Did not find the default user type for creating a new user");
  }
  const defaultUserChip = foundDefaultUserChip || userTypeChips[0];

  // buyers don't have roles.. yet.
  const roleSelectShouldBeDisplayed =
    userFormType === "tenant" && roleIsSellerAdmin;

  const { t } = useTranslation();

  function generatePOSTBody(inputs: FormInputs) {
    const maybeUserType = getUserType(inputs?.role?.label);
    const postBody = {
      email_address: inputs.email_address,
      crm_id: inputs.crm_id,
      firstname: inputs.firstname,
      preferred_language: inputs.preferred_language.value,
      lastname: inputs.lastname,
      phone_number: `${inputs.country_code?.value}${inputs.phone_number}`,
      user_type: maybeUserType ? maybeUserType : inputs.user_type_chip.id,
      ...(inputs?.role?.value && { role_id: inputs.role.value }),
      // The following is safe to do because while 0 is a valid value these
      // values will come in as "0". We are treating null here as "unlimited".
      // (which is the default is nothing is ever set for existing users.)
      quote_threshold_usd: inputs.quote_threshold_usd || null,
      order_threshold_usd: inputs.order_threshold_usd || null,
      quote_threshold_local: inputs.quote_threshold_local || null,
      order_threshold_local: inputs.quote_threshold_local || null,
      email_notifications: roleSelectShouldBeDisplayed
        ? {
            storefront_id: storefront_id,
            // leads
            leads: inputs.leads_notification,
            leads_enable_quote_request:
              userNotifications.leads_enable_quote_request,
            leads_enable_sample_request:
              userNotifications.leads_enable_sample_request,
            leads_enable_registration:
              userNotifications.leads_enable_registration,
            leads_enable_contact_us: userNotifications.leads_enable_contact_us,
          }
        : undefined,
      userCheckbox: inputs.userCheckbox,
      shippingCheckbox: inputs.shippingCheckbox,
    };

    if (leadToUserData) {
      return leadToUserData.type === "sample_request"
        ? { ...postBody, sample_request_id: leadToUserData.lead_id }
        : { ...postBody, lead_id: leadToUserData.lead_id };
    }
    return postBody;
  }

  /**
   * Lazy evaluation below, we don't know until form submit time which fields
   * are required, or what the country code is for the phone_number.
   * For the textfields they are required if the corresponding "unlimited"
   * checkbox is unchecked.
   *
   * The calls to `typeError` handle the NaN case which is coerced from an
   * empty string. We are treating this as a required field. Even though in
   * javascript land `typeof NaN === number` and you might think this would
   * fall through to the required condition, yup feels differently.
   */
  // TODO: get this to type check without the `any`. Maybe use zod instead?
  // const CreateNewTenantUserSchema = yup.lazy<FormInputs>((formValues) => {
  const CreateNewTenantUserSchema = yup.lazy((formValues: any) => {
    return yup.object().shape({
      customer_search: leadToUserData
        ? yup
            .object()
            .shape({
              value: yup.string().required(strings(t).thisIsARequiredField),
              label: yup.string(),
            })
            .required(strings(t).thisIsARequiredField)
        : yup
            .object()
            .shape({ value: yup.string(), label: yup.string() })
            .notRequired(),

      firstname: isRequiredUserCheckbox
        ? yup.string().required(strings(t).thisIsARequiredField)
        : yup.string().notRequired(),
      lastname: isRequiredUserCheckbox
        ? yup.string().required(strings(t).thisIsARequiredField)
        : yup.string().notRequired(),
      email_address: isRequiredUserCheckbox
        ? yup.string().email().required(strings(t).thisIsARequiredField)
        : yup.string().notRequired(),

      preferred_language: yup
        .object()
        .shape({ value: yup.string(), label: yup.string() })
        .required(strings(t).thisIsARequiredField),

      phone_number: isRequiredUserCheckbox
        ? yup
            .string()
            .phone(
              countryCodeMap.get(formValues.country_code.value),
              false,
              strings(t).phoneNumberMustBeValid
            )
            .required(strings(t).thisIsARequiredField)
        : yup.string().notRequired(),

      country_code: isRequiredUserCheckbox
        ? yup
            .object()
            .shape({
              value: yup.string().required(strings(t).thisIsARequiredField),
              label: yup.string().required(strings(t).thisIsARequiredField),
            })
            .required(strings(t).thisIsARequiredField)
        : yup.string().notRequired(),

      user_type_chip: yup
        .object()
        .shape({ name: yup.string(), id: yup.string() })
        .required(strings(t).thisIsARequiredField),

      quote_threshold_usd: isRequiredQuoteUSD
        ? yup
            .number()
            .typeError(strings(t).thisIsARequiredField)
            .min(0, t("Value cannot be negative") as string)
            .required(strings(t).thisIsARequiredField)
        : yup.number().notRequired(),

      order_threshold_usd: isRequiredOrderUSD
        ? yup
            .number()
            .typeError(strings(t).thisIsARequiredField)
            .min(0, t("Value cannot be negative") as string)
            .required(strings(t).thisIsARequiredField)
        : yup.number().notRequired(),

      quote_threshold_local: isRequiredQuoteDefault
        ? yup
            .number()
            .typeError(strings(t).thisIsARequiredField)
            .min(0, t("Value cannot be negative") as string)
            .required(strings(t).thisIsARequiredField)
        : yup.number().notRequired(),

      order_threshold_local: isRequiredOrderDefault
        ? yup
            .number()
            .typeError(strings(t).thisIsARequiredField)
            .min(0, t("Value cannot be negative") as string)
            .required(strings(t).thisIsARequiredField)
        : yup.number().notRequired(),

      quote_threshold_usd_unlimited: yup.boolean().notRequired(),
      quote_threshold_default_unlimited: yup.boolean().notRequired(),
      order_threshold_usd_unlimited: yup.boolean().notRequired(),
      order_threshold_default_unlimited: yup.boolean().notRequired(),

      country: isRequiredShippingCheckbox
        ? yup
            .object()
            .shape({
              value: yup.string().required(strings(t).thisIsARequiredField),
              label: yup.string().required(strings(t).thisIsARequiredField),
            })
            .required(strings(t).thisIsARequiredField)
        : yup.string().notRequired(),

      street_name: isRequiredShippingCheckbox
        ? yup.string().required(strings(t).thisIsARequiredField)
        : yup.string().notRequired(),

      city: isRequiredShippingCheckbox
        ? yup.string().required(strings(t).thisIsARequiredField)
        : yup.string().notRequired(),

      postal_code: isRequiredShippingCheckbox
        ? yup.string().required(strings(t).thisIsARequiredField)
        : yup.string().notRequired(),

      state: isRequiredShippingCheckbox
        ? yup
            .object()
            .shape({
              value: yup.string().required(strings(t).thisIsARequiredField),
              label: yup.string().required(strings(t).thisIsARequiredField),
            })
            .required(strings(t).thisIsARequiredField)
        : yup.string().notRequired(),

      point_of_contact_id:
        isRequiredExistingUser && isRequiredShippingCheckbox
          ? yup
              .object()
              .shape({
                value: yup.string().required(strings(t).thisIsARequiredField),
                label: yup.string().required(strings(t).thisIsARequiredField),
              })
              .required(strings(t).thisIsARequiredField)
          : yup.string().notRequired(),

      contact_first_name:
        isRequiredManualUser && isRequiredShippingCheckbox
          ? yup.string().required(strings(t).thisIsARequiredField)
          : yup.string().notRequired(),

      contact_last_name:
        isRequiredManualUser && isRequiredShippingCheckbox
          ? yup.string().required(strings(t).thisIsARequiredField)
          : yup.string().notRequired(),

      contact_email_address:
        isRequiredManualUser && isRequiredShippingCheckbox
          ? yup.string().email().required(strings(t).thisIsARequiredField)
          : yup.string().notRequired(),

      contact_phone_number:
        isRequiredManualUser && isRequiredShippingCheckbox && !useHqAddress
          ? yup
              .string()
              .phone(
                countryCodeMap.get(formValues.contact_country_code?.value),
                false,
                strings(t).phoneNumberMustBeValid
              )
              .required(strings(t).thisIsARequiredField)
          : yup.string().notRequired(),

      contact_country_code:
        isRequiredManualUser && isRequiredShippingCheckbox
          ? yup
              .object()
              .shape({
                value: yup.string().required(strings(t).thisIsARequiredField),
                label: yup.string().required(strings(t).thisIsARequiredField),
              })
              .required(strings(t).thisIsARequiredField)
          : yup.string().notRequired(),
    });
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState,
    errors,
    clearErrors,
    watch,
  } = useFormWrapper<FormInputs>({
    resolver: yupResolver(CreateNewTenantUserSchema),
    defaultValues: {
      customer_search: undefined,
      email_address: leadToUserData?.email_address,
      firstname: leadToUserData?.firstname,
      lastname: leadToUserData?.lastname,
      phone_number: getPhoneNumber(leadToUserData?.phone_number)?.toString(),
      preferred_language: { value: "en", label: "English" },
      user_type_chip: defaultUserChip,
      quote_threshold_usd_unlimited: true,
      quote_threshold_default_unlimited: true,
      order_threshold_usd_unlimited: true,
      order_threshold_default_unlimited: true,
      leads_notification: true,
      country_code: getPhoneCodeOption(
        getCountryCode(leadToUserData?.phone_number)
      ),
      userCheckbox: true,
      shippingCheckbox: leadToUserData?.address ? true : false,
      country: { label: "", value: "" },
      street_name: leadToUserData?.address?.address1,
      address2: leadToUserData?.address?.address2,
      state: {
        label: "",
        value: leadToUserData?.address?.state,
      },
      city: leadToUserData?.address?.city,
      postal_code: leadToUserData?.address?.postal_code,
      contact_first_name: "",
      contact_last_name: "",
      contact_email_address: "",
      contact_country_code: { value: "", label: "" },
      contact_phone_number: "",
      point_of_contact_id: { value: "", label: "" },
    },
  });
  const selectedCountry = watch("country");
  const states = useStatesList(selectedCountry?.value);
  const { data, error: rolesError } = useSWR<{ data: RoleSummary }, AxiosError>(
    `/v1/storefronts/${storefront_id}/roles`
  );

  const roles = data?.data;
  const roleOptions = roles && !rolesError ? roleSummaryToOption(roles) : [];

  const watchedUserRole = watch(["role"]);

  const watchedValues = watch([
    "quote_threshold_usd_unlimited",
    "quote_threshold_default_unlimited",
    "order_threshold_usd_unlimited",
    "order_threshold_default_unlimited",
    "customer_search",
    "leads_notification",
    "leads_enable_quote_request",
    "leads_enable_sample_request",
    "leads_enable_registration",
    "leads_enable_contact_us",
    "userCheckbox",
    "shippingCheckbox",
    "firstname",
    "lastname",
    "email_address",
    "country_code",
    "phone_number",
  ]);

  const {
    quote_threshold_usd_unlimited,
    quote_threshold_default_unlimited,
    order_threshold_usd_unlimited,
    order_threshold_default_unlimited,
    customer_search,
    leads_notification,
    leads_enable_quote_request,
    leads_enable_sample_request,
    leads_enable_registration,
    leads_enable_contact_us,
    firstname,
    lastname,
    email_address,
    country_code,
    phone_number,
  } = watchedValues;

  useEffect(() => {
    register({ name: "user_type_chip" }, { required: true });
    register({ name: "customer_search" }, { required: true });
  }, [register]);

  useEffect(() => {
    // Accomplish two things,
    //  1. make the input requried in the corresponding
    //  "unlimited" checkbox is unchecked
    //  2. clear the error if the user tries to submit the form with the textfield
    //  in a required state and later clicks the checkbox to make it unrequired.
    setIsRequiredQuoteUSD(
      watchedValues.quote_threshold_usd_unlimited === false
    );
    if (!isRequiredQuoteUSD && errors.quote_threshold_usd) {
      clearErrors("quote_threshold_usd");
    }

    setIsRequiredOrderUSD(
      watchedValues.order_threshold_usd_unlimited === false
    );
    if (!isRequiredOrderUSD && errors.order_threshold_usd) {
      clearErrors("order_threshold_usd");
    }

    setIsRequiredQuoteDefault(
      watchedValues.quote_threshold_default_unlimited === false
    );
    if (!isRequiredQuoteDefault && errors.quote_threshold_local) {
      clearErrors("quote_threshold_local");
    }

    setIsRequiredOrderDefault(
      watchedValues.order_threshold_default_unlimited === false
    );
    if (!isRequiredOrderDefault && errors.order_threshold_local) {
      clearErrors("order_threshold_local");
    }

    if (errors.customer_search && watchedValues?.customer_search?.value) {
      clearErrors("customer_search");
    }

    setIsRequiredUserCheckbox(watchedValues.userCheckbox);
    setIsRequiredShippingCheckbox(watchedValues.shippingCheckbox);
    setIsRequiredExistingUser(pointOfContactType === "existing");
    setIsRequiredManualUser(pointOfContactType === "manual");
  }, [
    clearErrors,
    errors.order_threshold_local,
    errors.order_threshold_usd,
    errors.quote_threshold_local,
    errors.quote_threshold_usd,
    errors.customer_search,
    isRequiredOrderDefault,
    isRequiredOrderUSD,
    isRequiredQuoteDefault,
    isRequiredQuoteUSD,
    pointOfContactType,
    watchedValues.order_threshold_default_unlimited,
    watchedValues.order_threshold_usd_unlimited,
    watchedValues.quote_threshold_default_unlimited,
    watchedValues.quote_threshold_usd_unlimited,
    watchedValues.customer_search,
    watchedValues.userCheckbox,
    watchedValues.shippingCheckbox,
  ]);

  const displaySellerLimits =
    watchedUserRole.role !== undefined &&
    edition !== "pim" &&
    watchedUserRole?.role?.label !== "Admin";

  const showLocalCurrencyLimits = default_currency !== "USD";

  const onUserTypeToggle = (userTypeChipArray: ChipType[]) => {
    setValue("user_type_chip", userTypeChipArray[0]);
  };

  const onSubmit = async (inputs: FormInputs) => {
    setLoading(true);
    const requestBody = generatePOSTBody(inputs);

    if (!leadToUserData?.address || requestBody.userCheckbox) {
      try {
        const response = await Axios.post(
          endpoints.v1_storefronts_id_tenants_id_users(
            storefront_id,
            leadToUserData ? customer_search?.value : tenantId
          ),
          requestBody
        );
        if (response.status === 201) {
          setLoading(false);
          notifySuccess(t("User has been created successfully") as string);
          if (customer_search?.value) {
            history.replace(
              `${adminPath}/${
                tenantTypeToFetch === "Buyer" ? "tenants" : "distributors"
              }/${customer_search?.value}?tab=Users`
            );
          }
          onSuccess();
        }
      } catch (error) {
        setLoading(false);
        notifyError(t((error as AxiosError).message));
      }
    }
    if (leadToUserData?.address && requestBody.shippingCheckbox) {
      const isManualContact = pointOfContactType === "manual";

      const newAddress = {
        type: "Warehouse",
        company_name: customer_search?.label,
        country: inputs.country?.value,
        address1: inputs.street_name,
        address2: inputs.address2,
        state: inputs.state?.value,
        city: inputs.city,
        postal_code: inputs.postal_code,

        ...(isManualContact
          ? {
              contact_first_name: inputs.contact_first_name,
              contact_last_name: inputs.contact_last_name,
              email_address: inputs.contact_email_address,
              country_code: getPhoneCodeOption(
                getCountryCode(inputs.contact_phone_number)
              ),
              phone_number: `${inputs.contact_country_code?.value}${inputs.contact_phone_number}`,
              point_of_contact_id: undefined,
            }
          : {
              point_of_contact_id: inputs.point_of_contact_id?.value,
              contact_first_name: undefined,
              contact_last_name: undefined,
              email_address: undefined,
              country_code: undefined,
              phone_number: undefined,
            }),
      };

      try {
        const response = await Axios.post(
          `/v1/tenants/${customer_search?.value}/addresses`,
          newAddress
        );
        if (response.status === 201) {
          setLoading(false);
          notifySuccess(t("Address has been created successfully") as string);
          if (customer_search?.value) {
            history.replace(
              `${adminPath}/${
                tenantTypeToFetch === "Buyer" ? "tenants" : "distributors"
              }/${customer_search?.value}?tab=Company+Info`
            );
          }
          onSuccess();
        }
      } catch (error) {
        notifyError(strings(t).submitError, { error });
      }
    }
  };

  const { data: tenantsResponse } = useSWR<
    ITenantPaginatedOutputResponse | undefined
  >(
    user
      ? makeUrlWithParams(
          endpoints.v1_storefronts_id_tenants_id_tenants(
            storefront_id,
            user.tenant_id
          ),
          {
            offset: 0,
            limit: TEMPORARY_HIGH_LIMIT,
            // Don't let sort_by or order_by be empty strings(t).
            sort_by: null,
            order_by: "asc",
            tenant_type: tenantTypeToFetch,
          }
        )
      : null
  );

  useEffect(() => {
    if (tenantsResponse) {
      setCustomersList(
        tenantsResponse.data.map((tenant) => ({
          value: tenant.id,
          label: tenant.name,
        }))
      );
    }
  }, [tenantsResponse]);

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const handleTenantSearch = React.useCallback(
    debounce((query, setOptions) => {
      const getOptions = async (query: string) => {
        try {
          setLoading(true);
          const response = user
            ? await Axios.get<ITenantPaginatedOutputResponse>(
                makeUrlWithParams(
                  endpoints.v1_storefronts_id_tenants_id_tenants(
                    storefront_id,
                    user.tenant_id
                  ),
                  {
                    offset: 0,
                    q: query,
                    limit: TEMPORARY_HIGH_LIMIT,
                    // Don't let sort_by or order_by be empty strings(t).
                    sort_by: null,
                    order_by: "asc",
                    tenant_type: "Buyer",
                  }
                )
              )
            : null;
          const options = response?.data.data.map((tenant) => ({
            value: tenant.id,
            label: tenant.name,
          }));
          setLoading(false);
          return options;
        } catch (error) {
          notifyError(t("Something went wrong, please try again."), { error });
          setLoading(false);
          return [];
        }
      };

      getOptions(query).then((options) => setOptions(options));
    }, 1000),
    []
  );

  const handleCustomersSearchSelected = (data: any) => {
    setValue("customer_search", data);
    setValue("point_of_contact_id", { value: "", label: "" });
  };

  useEffect(() => {
    setUserNotifications((prev) => ({
      leads_enable_quote_request:
        leads_enable_quote_request ?? prev.leads_enable_quote_request,
      leads_enable_sample_request:
        leads_enable_sample_request ?? prev.leads_enable_sample_request,
      leads_enable_registration:
        leads_enable_registration ?? prev.leads_enable_registration,
      leads_enable_contact_us:
        leads_enable_contact_us ?? prev.leads_enable_contact_us,
    }));
  }, [
    leads_enable_quote_request,
    leads_enable_sample_request,
    leads_enable_registration,
    leads_enable_contact_us,
  ]);

  const headingName =
    tenantTypeToFetch === "Buyer" ? "customer" : "distributor";

  const changePointOfContact = (e: React.FormEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value) {
      setPointOfContactType(e.currentTarget.value);
    }
  };

  const handleSameAsAddressToggle = (e: ChangeEvent<HTMLInputElement>) => {
    const is_checked = e.currentTarget.checked;
    setUseHqAddress(is_checked);
    handleToggle(leadToUserData, is_checked);
  };

  const handleToggle = useCallback(
    (address: LeadData | undefined, isHqAddress: boolean) => {
      if (isHqAddress && address) {
        setValue("contact_first_name", firstname);
        setValue("contact_last_name", lastname);
        setValue("contact_email_address", email_address);
        setValue("contact_phone_number", phone_number);
        setValue("contact_country_code", country_code);
        clearErrors("contact_first_name");
        clearErrors("contact_last_name");
        clearErrors("contact_email_address");
        clearErrors("contact_phone_number");
        clearErrors("contact_country_code");
        setSwitchDisabled(true);
      } else {
        setValue("contact_first_name", "");
        setValue("contact_last_name", "");
        setValue("contact_email_address", "");
        setValue("contact_phone_number", "");
        setValue("contact_country_code", { label: "", value: "" });
        setSwitchDisabled(false);
      }
    },
    [
      setValue,
      clearErrors,
      firstname,
      lastname,
      email_address,
      phone_number,
      country_code,
    ]
  );

  const currentCompanyValue = customer_search?.value;

  const usersQuery =
    storefront_id && currentCompanyValue
      ? makeUrlWithParams(
          endpoints.v1_storefronts_id_tenants_id_users(
            storefront_id,
            currentCompanyValue
          ),
          {
            offset: 0,
            limit: 100,
            order_by: "asc",
          }
        )
      : null;

  const { data: usersData } = useSWR<AgilisUsersPaginatedOutput>(usersQuery);
  const users = usersData?.data.filter((user) => user.is_active);
  const userOptions = users?.map(convertUserToOption) ?? [];

  useEffect(() => {
    if (selectedCountry?.value && selectedCountry?.value !== storedCountry) {
      setStoredCountry(selectedCountry?.value);
      setValue("street_name", "");
      setValue("address2", "");
      setValue("city", "");
      setValue("postal_code", "");
      setValue("state", { label: "", value: "" });
    }
  }, [selectedCountry, storedCountry, setValue]);

  useEffect(() => {
    if (
      pointOfContactType &&
      pointOfContactType === "manual" &&
      useHqAddress &&
      leadToUserData
    ) {
      handleToggle(leadToUserData, useHqAddress);
    }
  }, [
    pointOfContactType,
    useHqAddress,
    leadToUserData,
    setValue,
    isRequiredShippingCheckbox,
    handleToggle,
  ]);

  useEffect(() => {
    if (countries.length > 0) {
      setValue(
        "country",
        getCountryOption(countries, leadToUserData?.address?.country)
      );
    }
  }, [countries, leadToUserData, setValue, isRequiredShippingCheckbox]);

  useEffect(() => {
    if (states.length > 0) {
      leadToUserData?.address?.state &&
        setValue(
          "state",
          getStateOption(states, leadToUserData?.address?.state)
        );
    }
  }, [leadToUserData, setValue, states, isRequiredShippingCheckbox]);

  return (
    <>
      <HeaderLeft>
        <PageTitle>
          {leadToUserData
            ? t(`Add to existing ${headingName}`)
            : t("Create new user")}
        </PageTitle>
      </HeaderLeft>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        {leadToUserData && (
          <AsyncSearchSelect
            name={"customer_search"}
            errors={errors}
            formState={formState}
            placeholder={t("Select Company")}
            searchFunction={handleTenantSearch}
            onChange={(data: any) => handleCustomersSearchSelected(data)}
            options={customersList}
            testid={"customer_search"}
            theref={register({
              required: true,
            })}
          />
        )}

        {showShippingAddress && leadToUserData?.address && (
          <CheckBoxContainer>
            <div style={{ minWidth: "12px" }}>
              <CheckBoxNoLabel
                name="userCheckbox"
                id="userCheckbox"
                defaultChecked
                ref={register({ required: false })}
                style={{ marginRight: "5px" }}
              />
            </div>
            <CheckBoxFinePrintLabel htmlFor="userCheckbox">
              User
            </CheckBoxFinePrintLabel>
          </CheckBoxContainer>
        )}

        {isRequiredUserCheckbox && (
          <>
            {leadToUserData && <H3>{t("User Details")}</H3>}
            <TextField
              name="firstname"
              label={t("First Name")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
            <TextField
              name="lastname"
              label={t("Last Name")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
            <TextField
              name="email_address"
              label={t("Email")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="email"
            />
            {!roleIsSomeKindOfBuyer && (
              <TextField
                name="crm_id"
                label={t("CRM ID")}
                theref={register({
                  required: false,
                })}
                formState={formState}
                errors={errors}
                readOnly={!roleIsSellerAdmin}
                type="text"
              />
            )}
            <Flex>
              <Flex1>
                <Controller
                  as={SelectBoxV2}
                  control={control}
                  name="country_code"
                  autoComplete="countryCode"
                  placeholder={t("Country Code")}
                  id="countryCodeSelectBox"
                  options={getPhoneCodesOptions()}
                  rules={{
                    required: true,
                  }}
                  errors={errors}
                  formState={formState}
                />
              </Flex1>
              <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
                <TextField
                  name="phone_number"
                  label={t("Phone Number")}
                  theref={register({
                    required: true,
                  })}
                  formState={formState}
                  errors={errors}
                  type="tel"
                />
              </Flex2>
            </Flex>
            <Controller
              as={SelectBoxV2}
              control={control}
              name="preferred_language"
              placeholder={t("Preferred Language")}
              options={supportedLanguageOptions}
              rules={{
                required: true,
              }}
              errors={errors}
              formState={formState}
            />
          </>
        )}

        {showShippingAddress && leadToUserData?.address && (
          <>
            <CheckBoxContainer>
              <div style={{ minWidth: "12px" }}>
                <CheckBoxNoLabel
                  name="shippingCheckbox"
                  id="shippingCheckbox"
                  defaultChecked
                  ref={register({ required: false })}
                  style={{ marginRight: "5px" }}
                />
              </div>
              <CheckBoxFinePrintLabel htmlFor="shippingCheckbox">
                Shipping Address
              </CheckBoxFinePrintLabel>
            </CheckBoxContainer>

            {isRequiredShippingCheckbox && (
              <>
                <H3>{t("Shipping Address")}</H3>
                {countries.length > 0 && (
                  <Controller
                    as={SelectBoxV2}
                    control={control}
                    name="country"
                    placeholder={t("Country")}
                    options={countries}
                    rules={{
                      required: false,
                    }}
                    errors={errors}
                    formState={formState}
                  />
                )}

                <TextField
                  name="street_name"
                  label={t("Street Name")}
                  theref={register({
                    required: true,
                  })}
                  formState={formState}
                  errors={errors}
                  type="text"
                />

                <TextField
                  name="address2"
                  label={t("Address 2 (optional)")}
                  theref={register({
                    required: true,
                  })}
                  formState={formState}
                  errors={errors}
                  type="text"
                />
                <Controller
                  as={SelectBoxV2}
                  control={control}
                  name="state"
                  placeholder={getStatesInputLabel(selectedCountry.value, t)}
                  id="portSelectBox"
                  options={states}
                  rules={{
                    required: false,
                  }}
                  errors={errors}
                  formState={formState}
                />
                <Flex>
                  <Flex2>
                    <TextField
                      name="city"
                      label={t("City")}
                      theref={register({
                        required: false,
                      })}
                      formState={formState}
                      errors={errors}
                      type="text"
                    />
                  </Flex2>
                  <Flex1>
                    <TextField
                      name="postal_code"
                      label={getZipCodeInputLabel(selectedCountry.value, t)}
                      theref={register({
                        required: false,
                      })}
                      formState={formState}
                      errors={errors}
                      type="text"
                    />
                  </Flex1>
                </Flex>

                <H3>{t("Point of Contact")}</H3>
                <RadioButtonContainer>
                  <RadioButton
                    name={"existingUser"}
                    value="existing"
                    checked={pointOfContactType === "existing"}
                    optionTitle="Existing User"
                    handleChange={changePointOfContact}
                  />
                  <RadioButton
                    name={"manualUser"}
                    value="manual"
                    checked={pointOfContactType === "manual"}
                    optionTitle="Add Manually"
                    handleChange={changePointOfContact}
                  />
                </RadioButtonContainer>

                {pointOfContactType === "existing" && (
                  <Controller
                    as={SelectBoxV2}
                    control={control}
                    name="point_of_contact_id"
                    placeholder={t("Select User")}
                    options={userOptions}
                    rules={{
                      required: true,
                    }}
                    errors={errors}
                    formState={formState}
                  />
                )}

                {pointOfContactType === "manual" && (
                  <>
                    <TextField
                      name="contact_first_name"
                      label={t("First Name")}
                      theref={register({
                        required: true,
                      })}
                      formState={formState}
                      errors={errors}
                      type="text"
                      readOnly={isSwitchDisabled}
                    />
                    <TextField
                      name="contact_last_name"
                      label={t("Last Name")}
                      theref={register({
                        required: true,
                      })}
                      formState={formState}
                      errors={errors}
                      type="text"
                      readOnly={isSwitchDisabled}
                    />
                    <TextField
                      name="contact_email_address"
                      autoComplete="email"
                      label={t("Email")}
                      theref={register({
                        required: true,
                      })}
                      formState={formState}
                      errors={errors}
                      type="email"
                      readOnly={isSwitchDisabled}
                    />
                    <Flex>
                      <Flex1>
                        <Controller
                          as={SelectBoxV2}
                          control={control}
                          name="contact_country_code"
                          autoComplete="countryCode"
                          placeholder={t("Country Code")}
                          id="countryCodeSelectBox"
                          options={getPhoneCodesOptions()}
                          rules={{
                            required: true,
                          }}
                          errors={errors}
                          formState={formState}
                          disabled={isSwitchDisabled}
                        />
                      </Flex1>
                      <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
                        <TextField
                          name="contact_phone_number"
                          autoComplete="tel"
                          label={t("Phone Number")}
                          theref={register({
                            required: true,
                          })}
                          formState={formState}
                          errors={errors}
                          type="tel"
                          readOnly={isSwitchDisabled}
                        />
                      </Flex2>
                    </Flex>
                  </>
                )}

                {leadToUserData && pointOfContactType === "manual" && (
                  <div style={{ margin: "20px 0" }}>
                    <ToggleSwitchV2
                      label={t("Same as Lead User")}
                      name="useHqAddress"
                      ref={register({ required: false })}
                      checked={useHqAddress}
                      onChange={handleSameAsAddressToggle}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}

        {roleIsSomeKindOfBuyer && (
          <Chips
            name="user_type_chip"
            errors={errors}
            header={t("Account type") as string}
            chips={userTypeChips}
            handleClick={onUserTypeToggle}
            selectionMode={"single"}
            selectedChip={defaultUserChip.name}
          />
        )}
        {roleSelectShouldBeDisplayed && (
          <Controller
            as={SelectBoxV2}
            control={control}
            name="role"
            placeholder={t("Role")}
            options={roleOptions}
            rules={{
              required: true,
            }}
            errors={errors}
            formState={formState}
          />
        )}
        {displaySellerLimits && (
          <>
            <SectionTitle style={{ marginTop: "30px" }}>
              Quote Limit
            </SectionTitle>
            <FormGrid2x2>
              <CheckBoxAndTextField>
                <CheckBoxContainer>
                  <div style={{ minWidth: "12px" }}>
                    <CheckBoxNoLabel
                      name="quote_threshold_usd_unlimited"
                      id="quote_threshold_usd_unlimited"
                      defaultChecked
                      ref={register({ required: false })}
                      style={{ marginRight: "5px" }}
                    />
                  </div>
                  <CheckBoxFinePrintLabel htmlFor="quote_threshold_usd_unlimited">
                    No Limit
                  </CheckBoxFinePrintLabel>
                </CheckBoxContainer>

                <TextField
                  name="quote_threshold_usd"
                  label={t("USD Limit")}
                  theref={register({
                    required: quote_threshold_usd_unlimited === false,
                    min: 0,
                  })}
                  disabled={!!quote_threshold_usd_unlimited}
                  formState={formState}
                  errors={errors}
                  type="number"
                />
              </CheckBoxAndTextField>
              {showLocalCurrencyLimits && (
                <CheckBoxAndTextField>
                  <CheckBoxContainer>
                    <div style={{ minWidth: "12px" }}>
                      <CheckBoxNoLabel
                        name="quote_threshold_default_unlimited"
                        id="quote_threshold_default_unlimited"
                        defaultChecked
                        ref={register({ required: true })}
                        style={{ marginRight: "5px" }}
                      />
                    </div>
                    <CheckBoxFinePrintLabel htmlFor="quote_threshold_default_unlimited">
                      {t("No Limit")}
                    </CheckBoxFinePrintLabel>
                  </CheckBoxContainer>
                  <TextField
                    name="quote_threshold_local"
                    label={t(`{{default_currency}} Limit`, {
                      default_currency,
                    })}
                    theref={register({
                      required: quote_threshold_default_unlimited === false,
                      min: 0,
                    })}
                    disabled={!!quote_threshold_default_unlimited}
                    formState={formState}
                    errors={errors}
                    type="number"
                  />
                </CheckBoxAndTextField>
              )}
            </FormGrid2x2>
            <SectionTitle style={{ marginTop: "30px" }}>
              Order Limit
            </SectionTitle>
            <FormGrid2x2>
              <CheckBoxAndTextField>
                <CheckBoxContainer>
                  <div style={{ minWidth: "12px" }}>
                    <CheckBoxNoLabel
                      name="order_threshold_usd_unlimited"
                      id="order_threshold_usd_unlimited"
                      defaultChecked
                      ref={register({ required: true })}
                      style={{ marginRight: "5px" }}
                    />
                  </div>
                  <CheckBoxFinePrintLabel htmlFor="order_threshold_usd_unlimited">
                    {t("No Limit")}
                  </CheckBoxFinePrintLabel>
                </CheckBoxContainer>
                <TextField
                  name="order_threshold_usd"
                  label={t("USD Limit")}
                  theref={register({
                    required: true,
                    min: 0,
                  })}
                  disabled={!!order_threshold_usd_unlimited}
                  formState={formState}
                  errors={errors}
                  type="number"
                />
              </CheckBoxAndTextField>
              {showLocalCurrencyLimits && (
                <CheckBoxAndTextField>
                  <CheckBoxContainer>
                    <div style={{ minWidth: "12px" }}>
                      <CheckBoxNoLabel
                        name="order_threshold_default_unlimited"
                        id="order_threshold_default_unlimited"
                        defaultChecked
                        ref={register({ required: true })}
                        style={{ marginRight: "5px" }}
                      />
                    </div>
                    <CheckBoxFinePrintLabel htmlFor="order_threshold_default_unlimited">
                      {t("No Limit")}
                    </CheckBoxFinePrintLabel>
                  </CheckBoxContainer>
                  <TextField
                    name="order_threshold_local"
                    label={t(`{{default_currency}} Limit`, {
                      default_currency,
                    })}
                    theref={register({
                      required: true,
                      min: 0,
                    })}
                    disabled={!!order_threshold_default_unlimited}
                    formState={formState}
                    errors={errors}
                    type="number"
                  />
                </CheckBoxAndTextField>
              )}
            </FormGrid2x2>
          </>
        )}

        {edition !== "pim" && roleSelectShouldBeDisplayed && (
          <>
            <SectionTitle style={{ marginTop: "30px" }}>
              {t("Notification Preferences")}
            </SectionTitle>
            <ToggleSwitchWrapper style={{ margin: "20px 0 15px" }}>
              <ToggleSwitch
                label={t("Leads")}
                name={"leads_notification"}
                theref={register({ required: true })}
                isChecked={true}
              />
            </ToggleSwitchWrapper>
            {leads_notification && (
              <Card style={{ background: "#fff", padding: "5px 15px" }}>
                <ToggleSwitchWrapper>
                  <ToggleSwitch
                    label={t("Quote Request")}
                    name={"leads_enable_quote_request"}
                    theref={register({ required: true })}
                    isChecked={userNotifications.leads_enable_quote_request}
                  />
                </ToggleSwitchWrapper>
                <ToggleSwitchWrapper>
                  <ToggleSwitch
                    label={t("Sample Request")}
                    name={"leads_enable_sample_request"}
                    theref={register({ required: true })}
                    isChecked={userNotifications.leads_enable_sample_request}
                  />
                </ToggleSwitchWrapper>
                <ToggleSwitchWrapper>
                  <ToggleSwitch
                    label={t("Register")}
                    name={"leads_enable_registration"}
                    theref={register({ required: true })}
                    isChecked={userNotifications.leads_enable_registration}
                  />
                </ToggleSwitchWrapper>
                <ToggleSwitchWrapper>
                  <ToggleSwitch
                    label={t("Contact Us")}
                    name={"leads_enable_contact_us"}
                    theref={register({ required: true })}
                    isChecked={userNotifications.leads_enable_contact_us}
                  />
                </ToggleSwitchWrapper>
              </Card>
            )}
          </>
        )}
        <PrimaryButtonFitContainer
          type="submit"
          loading={loading}
          style={{ marginBottom: "30px" }}
          disabled={!isRequiredShippingCheckbox && !isRequiredUserCheckbox}
        >
          {leadToUserData ? t("Add") : t("Create user")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
}
