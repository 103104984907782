import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import {
  GoBackButtonSmall,
  PrimaryButton,
  DestructiveButton,
  ButtonWithConfirmDialog,
  InvisibleButton,
} from "../../../components/Buttons/Buttons";
import {
  PageWrapper,
  PageHeader,
  PageTitle,
  FullWidthHorizontalSeparator,
} from "../../../layout/portalPageLayout";

import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import type { DataMutate } from "../../../types/types";

import useSWR from "swr";
import { providePrivatePageProps, useRoutePath } from "../../../util/Routing";
import { ErrorPlaceholder } from "../../../components/Error";
import { useStoreState, makeUrlWithParams } from "../../../util/util";
import { endpoints } from "../../../endpoints";
import { useTranslation } from "react-i18next";
import type {
  Assets,
  PIMProduct,
  ProductStatusType,
  TdsGeneratedAssetSchema,
} from "../../../types/types.PIM";
import {
  getProductStatusColor,
  getProductStatusText,
  StatusLeft,
} from "../../../components/Status/Status";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { useAuthContext } from "../../../components/Auth";
import { ProductTabs } from "./util";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { Tabs } from "@reach/tabs";
import { Modal } from "../../../components/Modal/Modal";
import ReactTooltip from "react-tooltip";
import {
  HeaderContainer,
  SubheaderInformationContainer,
} from "../../../components/Layout/Layout";
import {
  InnerTitleText,
  SubheaderContentText,
} from "../../../components/Typography/Typography";
import { ProductShareButton } from "./ProductShareButton";
import type { IProductVisibilityData } from "./Connections/Connections";

export const ProductHeader = styled(PageHeader)`
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
`;

const ProductHeaderImage = styled.img`
  min-width: 100px;
  max-width: 200px;
  max-height: 150px;
  margin-right: 15px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 8px;
  padding: 1px;
  align-self: center;
  cursor: pointer;
`;

const ProductHeaderActions = styled.div`
  justify-self: end;
  align-self: end;
  display: flex;
  min-width: 100px;
  gap: 16px;
`;

const ModalWrapper = styled.div`
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaddedPrimaryButton = styled(PrimaryButton)`
  padding: 8px 16px;
  white-space: nowrap;
  svg {
    vertical-align: bottom;
  }
  margin-bottom: 5px;
`;

const PaddedDestructiveButton = styled(DestructiveButton)`
  padding: 8px 16px;
  white-space: nowrap;
  svg {
    vertical-align: bottom;
  }
  margin-bottom: 5px;
`;

export type ProductMutate = DataMutate<PIMProduct>;

/**
 * Product detail page. Used for seller admin.
 */
export const ProductDetailPage = providePrivatePageProps(({ user }) => {
  const { tenant_id, storefront_id, storeDispatch, slug } = useStoreState();

  const { hasPermission } = useAuthContext();

  const location = useLocation();

  const { product_id: productId } = useParams<{ product_id: string }>();

  const { adminPath } = useRoutePath();

  const { t } = useTranslation();

  const { notifyError, notifySuccess } = useContext(Notifications);

  const history = useHistory();

  const [loadingState, setLoadingState] = useState<ProductStatusType>();
  const [showProductImageModal, setShowProductImageModal] = useState(false);
  const [fullSizeProductImage, setFullSizeProductImage] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [teamName, setTeamName] = useState<string>("");
  const [visibilityData, setVisibilityData] =
    useState<IProductVisibilityData>();
  // We cant annotate the type inline while destucturing but "mutate"
  // here is type ProductMutate
  const {
    data: product,
    error: productError,
    mutate,
  } = useSWR<PIMProduct, AxiosError>(
    productId
      ? makeUrlWithParams(
          endpoints.v2_tenants_id_pim_products_id(tenant_id, productId),
          { use_database: "true", return_staged: "true" }
        )
      : null,
    { revalidateOnFocus: false }
  );

  const handleTabChange = async (index: number) => {
    setCurrentTab(index);
    await mutate();
  };
  const goBackToProductsList = () => {
    history.push(`${adminPath}/pim/products`);
  };

  const goBack = () => {
    history.length > 0 && !location.search.includes("source=new")
      ? history.goBack()
      : goBackToProductsList();
  };

  useEffect(() => {
    const teamNamesString = (() => {
      const teams = product?.team_names;
      const length = teams?.length;

      if (!length || product?.is_globally_viewable) {
        return t("None");
      } else if (length <= 2) {
        return teams.join(", ");
      } else {
        const firstTwo = teams.slice(0, 2);
        return `${firstTwo.join(", ")} + ${length - 2} ${t("more")}`;
      }
    })();
    setTeamName(teamNamesString);
  }, [product?.is_globally_viewable, product?.team_names, t]);

  if (productError) {
    return (
      <>
        <InvisibleButton onClick={() => goBack()}>
          <GoBackButtonSmall text={t("Back")} />
        </InvisibleButton>
        <ErrorPlaceholder message={"There was an error loading the product."} />
      </>
    );
  }
  if (!product) {
    // Product data is still loading.
    return <DelayedSpinner />;
  }

  // const productModifiedAt = formatDateTime(
  //   product.last_modified_full?.modified_at
  // );
  // const productModifiedBy = product.last_modified_full?.modified_by;

  const replaceProductId = async (productId: string) => {
    history.replace(
      location.search.includes("source=new")
        ? `${adminPath}/pim/products/${productId}?source=new`
        : `${adminPath}/pim/products/${productId}`
    );
    await mutate();
  };

  const productCoverImage = product.cover_image?.signed_url;

  const pollUntilFinished = async (
    url: string,
    /**
     * Polling interval in milliseconds
     */
    interval: number = 2000,
    /**
     * Maximum time to wait before giving up in milliseconds
     */
    timeout: number = 30000
  ): Promise<AxiosResponse<Assets>> => {
    const startTime = Date.now();

    const checkStatus = async (): Promise<AxiosResponse<Assets>> => {
      const response = await axios.get(url);
      let timeout_id: NodeJS.Timeout | undefined = undefined;

      if (
        response.data.status === "finished" ||
        Date.now() - startTime > timeout
      ) {
        clearTimeout(timeout_id);
        return response;
      } else {
        await new Promise((resolve) => {
          timeout_id = setTimeout(resolve, interval);
        });
        return checkStatus();
      }
    };

    return checkStatus();
  };

  /**
   * The first series of calls is to kick of an async job to regenerate the
   * assets, the second array of calls is to poll the status of the async job.
   */
  const regenerateAssets = async (
    generatedAssets: TdsGeneratedAssetSchema[],
    productId: string
  ) => {
    const genAssetsPromiseArray = generatedAssets.reduce<
      Promise<AxiosResponse<Assets>>[]
    >((acc, genAsset) => {
      if (genAsset.is_active) {
        acc.push(
          axios.post(
            endpoints.v2_tenants_tenant_id_products_product_id_generate_assets_id_async(
              tenant_id,
              productId,
              genAsset.id
            ),
            null,
            {
              params: {
                return_invisible_asset: false,
                regenerate_product_asset: true,
              },
            }
          )
        );
      }
      return acc;
    }, []);

    const genAssetsToPollPromiseArray = generatedAssets.reduce<
      Promise<AxiosResponse<Assets>>[]
    >((acc, genAsset) => {
      if (genAsset.is_active) {
        acc.push(
          pollUntilFinished(
            endpoints.v2_tenants_tenant_id_products_product_id_generate_assets_id_status(
              tenant_id,
              productId,
              genAsset.id
            )
          )
        );
      }
      return acc;
    }, []);

    await Promise.all(genAssetsPromiseArray);
    await Promise.all(genAssetsToPollPromiseArray);
  };

  const canRegenerateAssets = (product: PIMProduct) => {
    const { assets, product_schema } = product;
    return (
      product_schema.generated_assets.some((genAsset) => genAsset.is_active) &&
      assets.some((asset) => asset.is_generated)
    );
  };

  const handleStatusUpdate = async (statusToBecome: ProductStatusType) => {
    setLoadingState(statusToBecome);

    try {
      if (statusToBecome === "published") {
        storeDispatch({ type: "SET_IS_PUBLISHING_PRODUCT", payload: true });
      }

      const { data: updatedProduct } = await axios.post<
        { status: ProductStatusType },
        AxiosResponse<PIMProduct>
      >(
        endpoints.v2_storefronts_id_or_slug_products_id_or_slug_status(
          storefront_id,
          product.product_number ?? product.id
        ),
        { status: statusToBecome }
      );
      notifySuccess(t("Product status updated successfully"));
      if (statusToBecome === "published" || statusToBecome === "discarded") {
        if (
          statusToBecome === "published" &&
          canRegenerateAssets(updatedProduct)
        ) {
          await regenerateAssets(
            updatedProduct.product_schema.generated_assets,
            updatedProduct.id
          );
          await mutate();
          notifySuccess(t("Assets regenerated for product"));
        }
        const product_id =
          updatedProduct.product_number ?? updatedProduct.published_product_id;
        await replaceProductId(product_id);
      }
      await mutate();
      setLoadingState(undefined);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error updating the product status"),
        {
          error,
        }
      );
      setLoadingState(undefined);
    } finally {
      storeDispatch({ type: "SET_IS_PUBLISHING_PRODUCT", payload: false });
    }
  };

  const discardConfirmMessage = t(
    "You will lose all changes made to this product. Are you sure you want to continue?"
  );

  const isDisabledBasedOnStatusPermission = (status: ProductStatusType) => {
    if (
      ["published", "staged", "unpublished"].includes(status) &&
      !hasPermission("modify_products")
    ) {
      return true;
    } else if (
      ["discarded", "archived"].includes(status) &&
      !hasPermission("delete_products")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateLoadingAndDisabledState = (status: ProductStatusType) => {
    if (!product.is_editable) {
      return {
        loading: loadingState === status,
        disabled: true,
      };
    } else {
      const disabledStatus = isDisabledBasedOnStatusPermission(status)
        ? true
        : Boolean(loadingState);
      return {
        loading: loadingState === status,
        disabled: disabledStatus,
      };
    }
  };

  const actionButtonDisabledMessage = !product.is_editable
    ? t("This product is assigned to 1 or more teams and cannot be edited")
    : "";

  const statusActionButtons = () => {
    switch (product.status) {
      case "draft":
        return (
          <>
            <ButtonWithConfirmDialog
              Button={PaddedDestructiveButton}
              testid="draft_discard"
              confirmMessage={discardConfirmMessage}
              handleConfirm={async () => {
                await handleStatusUpdate("discarded");
                goBackToProductsList();
              }}
              loading={updateLoadingAndDisabledState("discarded").loading}
              disabled={updateLoadingAndDisabledState("discarded").disabled}
              buttonText={t("Delete")}
              datatip={actionButtonDisabledMessage}
              datafor="draft_tooltip"
            />
            <ReactTooltip id="draft-discard" />
            <PaddedPrimaryButton
              onClick={() => handleStatusUpdate("published")}
              loading={updateLoadingAndDisabledState("published").loading}
              disabled={updateLoadingAndDisabledState("published").disabled}
              data-tip={actionButtonDisabledMessage}
              data-for="draft_tooltip"
            >
              {t("Publish")}
            </PaddedPrimaryButton>
          </>
        );
      case "staged":
        return (
          <>
            <ButtonWithConfirmDialog
              Button={PaddedDestructiveButton}
              testid="staged_discard"
              confirmMessage={discardConfirmMessage}
              handleConfirm={() => handleStatusUpdate("discarded")}
              buttonText={t("Discard Changes")}
              loading={updateLoadingAndDisabledState("discarded").loading}
              disabled={updateLoadingAndDisabledState("discarded").disabled}
              datatip={actionButtonDisabledMessage}
              datafor="staged_tooltip"
            />
            <ReactTooltip id="staged_discard" />
            <PaddedPrimaryButton
              onClick={() => handleStatusUpdate("published")}
              loading={updateLoadingAndDisabledState("published").loading}
              disabled={updateLoadingAndDisabledState("published").disabled}
              data-tip={actionButtonDisabledMessage}
              data-for="staged_tooltip"
            >
              {t("Publish")}
            </PaddedPrimaryButton>
          </>
        );
      case "published":
        return (
          <>
            <PaddedPrimaryButton
              onClick={() => handleStatusUpdate("unpublished")}
              loading={updateLoadingAndDisabledState("unpublished").loading}
              disabled={updateLoadingAndDisabledState("unpublished").disabled}
              data-tip={actionButtonDisabledMessage}
              data-for="published_tooltip"
            >
              {t("Unpublish")}
            </PaddedPrimaryButton>
            <ReactTooltip id="published_tooltip" />
          </>
        );
      case "unpublished":
        return (
          <>
            <PaddedDestructiveButton
              onClick={() => handleStatusUpdate("archived")}
              loading={updateLoadingAndDisabledState("archived").loading}
              disabled={updateLoadingAndDisabledState("archived").disabled}
              data-tip={actionButtonDisabledMessage}
              data-for="unpublished_tooltip"
            >
              {t("Archive")}
            </PaddedDestructiveButton>
            <PaddedPrimaryButton
              onClick={() => handleStatusUpdate("published")}
              loading={updateLoadingAndDisabledState("published").loading}
              disabled={updateLoadingAndDisabledState("published").disabled}
              data-tip={actionButtonDisabledMessage}
              data-for="unpublished_tooltip"
            >
              {t("Publish")}
            </PaddedPrimaryButton>
            <ReactTooltip id="unpublished_tooltip" />
          </>
        );
      case "unpublished_staged":
        return (
          <>
            <ButtonWithConfirmDialog
              Button={PaddedDestructiveButton}
              testid="unpublished_staged_discard"
              confirmMessage={discardConfirmMessage}
              handleConfirm={() => handleStatusUpdate("discarded")}
              buttonText={t("Discard Changes")}
              loading={updateLoadingAndDisabledState("discarded").loading}
              disabled={updateLoadingAndDisabledState("discarded").disabled}
              datatip={actionButtonDisabledMessage}
              datafor="unpublished_staged_tooltip"
            />
            <PaddedPrimaryButton
              onClick={() => handleStatusUpdate("published")}
              loading={updateLoadingAndDisabledState("published").loading}
              disabled={updateLoadingAndDisabledState("published").disabled}
              data-tip={actionButtonDisabledMessage}
              data-for="unpublished_staged_tooltip"
            >
              {t("Publish")}
            </PaddedPrimaryButton>
          </>
        );
      case "archived":
        return (
          <>
            <PaddedPrimaryButton
              onClick={() => handleStatusUpdate("unpublished")}
              loading={updateLoadingAndDisabledState("unpublished").loading}
              disabled={updateLoadingAndDisabledState("unpublished").disabled}
              data-tip={actionButtonDisabledMessage}
              data-for="archived_tooltip"
            >
              {t("Restore")}
            </PaddedPrimaryButton>
            <ReactTooltip id="archived_tooltip" />
          </>
        );
      default:
        return <></>;
    }
  };

  const isLinkDisabled: boolean = product.status !== "published";

  const handleVisibilityDataFetched = (
    visibilityData: IProductVisibilityData
  ) => {
    setVisibilityData(visibilityData);
  };

  const isConnectionOn = visibilityData?.visible_storefronts.some(
    ({ slug: storefrontSlug }) => storefrontSlug === slug
  );

  return (
    <PageWrapper>
      <InvisibleButton onClick={() => goBack()}>
        <GoBackButtonSmall text={t("Back")} />
      </InvisibleButton>
      <ProductHeader>
        {productCoverImage && (
          <ProductHeaderImage
            src={productCoverImage}
            alt={product.name}
            onClick={() => setShowProductImageModal(true)}
          />
        )}
        <HeaderContainer>
          <PageTitle>{product.name}</PageTitle>
          <SubheaderInformationContainer>
            <InnerTitleText>{t("Team")}:</InnerTitleText>
            <SubheaderContentText>{teamName}</SubheaderContentText>
          </SubheaderInformationContainer>
          <SubheaderInformationContainer>
            <InnerTitleText>{t("Template")}:</InnerTitleText>
            <SubheaderContentText>
              {product.product_schema.template_name}
            </SubheaderContentText>
          </SubheaderInformationContainer>
          <SubheaderInformationContainer>
            <InnerTitleText>{t("Product Completion")}:</InnerTitleText>
            <SubheaderContentText>
              {product.display_status === "staged" ||
              product.display_status === "unpublished_staged"
                ? product.display_completion_score
                : product.completion_score}
              %
            </SubheaderContentText>
          </SubheaderInformationContainer>
          <SubheaderInformationContainer>
            <StatusLeft
              color={getProductStatusColor(product.status ?? "draft")}
              text={getProductStatusText(product.status ?? "draft", t)}
              textStyle={{ fontSize: "13px" }}
              productStatusType="product"
            />
          </SubheaderInformationContainer>
        </HeaderContainer>

        <ProductHeaderActions>
          <ProductShareButton
            productId={productId}
            slug={product.slug}
            name={product.name}
            produced_by={product.produced_by}
            isLinkDisabled={isLinkDisabled}
            isConnectionOn={isConnectionOn}
          />
          {statusActionButtons()}
        </ProductHeaderActions>
      </ProductHeader>

      <FullWidthHorizontalSeparator margin={"48px 0 0"} />
      {product && (
        <Tabs onChange={handleTabChange} index={currentTab}>
          {ProductTabs({
            product,
            t,
            refreshKey: currentTab,
            replaceProductId,
            mutate,
            hasPermission,
            onVisibilityDataFetched: handleVisibilityDataFetched,
          })}
        </Tabs>
      )}

      {showProductImageModal && productCoverImage && (
        <Modal
          show={showProductImageModal}
          closeModal={() => setShowProductImageModal(false)}
        >
          <ModalWrapper>
            <img
              src={productCoverImage}
              alt={product.name}
              style={{
                maxWidth: fullSizeProductImage ? "auto" : "100%",
                maxHeight: fullSizeProductImage ? "auto" : "100%",
                cursor: fullSizeProductImage ? "zoom-out" : "zoom-in",
              }}
              onClick={() => setFullSizeProductImage((current) => !current)}
            />
          </ModalWrapper>
        </Modal>
      )}
    </PageWrapper>
  );
});
