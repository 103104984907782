import React, { useEffect, useState } from "react";
import { DropDown } from "../../../components/DropDown/DropDown";
import {
  PageWrapper,
  PageHeader,
  PageTitle,
  ContentWrapper,
  HorizontalButtonsContainer,
} from "../../../layout/portalPageLayout";
import { QuoteRequestsList } from "./QuoteRequestsList";
import { RegistrationList } from "./RegistrationList";
import { ContactUsList } from "./ContactUsList";
import { providePrivatePageProps } from "../../../util/Routing";
import { SampleRequestLeadList } from "../SampleRequestLeadList/SampleRequestLeadList";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "../../../util/hooks";
import {
  useQueryParams,
  NumberParam,
  StringParam,
  ArrayParam,
} from "use-query-params";
import { TabPanels, Tabs } from "@reach/tabs";
import { useTranslation } from "react-i18next";
import {
  ContactRequestIcon,
  LabFlaskIcon,
  NavQuotesIcon,
  RegistrationIcon,
} from "../../../components/Icons/Icons";
import { TabsAndFilters } from "../../../components/ReachTabs";
import { TabList } from "../../../components/ReachTabs/TabList";
import { Tab } from "../../../components/ReachTabs/Tab";
import { useStoreState, useInAppNotifications } from "../../../util/util";
import { Badge } from "../../../layout/shared";
import styled from "styled-components/macro";
import { useAuthContext } from "../../../components/Auth";
import { CustomTabPanel } from "../../../components/ReachTabs/TabPanel";
import { SecondaryButtonMedium } from "../../../components/Buttons/Buttons";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { DownloadReport } from "../SellerOrdersListPage/DownloadReport/DownloadReport";

const TabBadge = styled(Badge)`
  display: inline-block;
  margin-left: 5px;
  position: relative;
  top: -2px;
`;

const LeadsDropdownWrapper = styled.div`
  position: absolute;
  top: 70px;
  right: 0;
`;

/**
 * The "Leads" page that displays a list of quote requests, registrations,
 * contact us, etc.  It is used for seller admin
 */
export const LeadsPage = providePrivatePageProps(({ user }) => {
  const { storefront_id } = useStoreState();
  const { leadsNotifications } = useInAppNotifications(storefront_id, user);
  const [offset, setOffset] = useState(0);
  const [refreshKey, setRefreshKey] = useState<number>();
  const [downloadReportSlideOut, setDownloadReportSlideOut] = useState(false);
  const perPageItems = [10, 20, 50, 100];
  const query = useQuery();
  const [perPage, setPerPage] = useState(
    Number(query.get("perPage") ?? perPageItems[0]) ?? perPageItems[0]
  );
  const [tabIndex, setTabIndex] = React.useState(0);
  const [userQuery, setQuery] = useQueryParams({
    tab: NumberParam,
    offset: NumberParam,
    perPage: NumberParam,
    status: ArrayParam,
    q: StringParam,
  });
  const [selectedStatusList, setSelectedStatusList] = useState<string[]>(
    userQuery.status?.filter((status): status is string => status !== null) ??
      []
  );
  const [searchQuery, setSearchQuery] = useState(userQuery.q ?? "");

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const { roleIsSomeKindOfSeller } = useAuthContext();

  const closeDownloadReportSlideout = () => setDownloadReportSlideOut(false);

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const handleTabsChange = (index: number) => {
    setRefreshKey(index);
    history.replace({
      search: `?tab=${index}&offset=0`,
    });
    setTabIndex(index);
  };

  useEffect(() => {
    const listState = (location.state as any)?.listState;
    if (listState) {
      setTabIndex(listState.tabIndex ?? 0);
      setOffset(listState.offset ?? 0);
      setPerPage(listState.perPage ?? 10);
      setSelectedStatusList(listState.selectedStatusList ?? []);
      setSearchQuery(listState.searchQuery ?? "");

      // Clear the location state to prevent unwanted restorations on subsequent renders
      history.replace({ ...location, state: undefined });
    }
  }, [location, history]);

  useEffect(() => {
    setQuery({
      tab: tabIndex,
      offset,
      perPage,
      status: selectedStatusList,
      q: searchQuery || undefined,
    });
  }, [tabIndex, offset, perPage, selectedStatusList, searchQuery, setQuery]);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>{t("Leads")}</PageTitle>
      </PageHeader>
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabsAndFilters style={{ position: "relative" }}>
          <TabList>
            {roleIsSomeKindOfSeller && (
              <Tab style={{ position: "relative" }}>
                <NavQuotesIcon />
                {t("Quote Request")}
                {leadsNotifications &&
                  leadsNotifications.quote_requests.total > 0 && (
                    <TabBadge>
                      {leadsNotifications.quote_requests.total.toString()}
                    </TabBadge>
                  )}
              </Tab>
            )}
            {roleIsSomeKindOfSeller && (
              <>
                <Tab>
                  <LabFlaskIcon />
                  {t("Sample Request")}
                  {leadsNotifications &&
                    leadsNotifications.sample_requests.total > 0 && (
                      <TabBadge>
                        {leadsNotifications.sample_requests.total.toString()}
                      </TabBadge>
                    )}
                </Tab>
                <Tab>
                  <RegistrationIcon />
                  {t("Registration")}
                  {leadsNotifications &&
                    leadsNotifications.registrations.total > 0 && (
                      <TabBadge>
                        {leadsNotifications.registrations.total.toString()}
                      </TabBadge>
                    )}
                </Tab>
                <Tab>
                  <ContactRequestIcon />
                  {t("Contact Us")}
                  {leadsNotifications &&
                    leadsNotifications.contact_us.total > 0 && (
                      <TabBadge>
                        {leadsNotifications.contact_us.total.toString()}
                      </TabBadge>
                    )}
                </Tab>
              </>
            )}
          </TabList>
          <HorizontalButtonsContainer>
            <SecondaryButtonMedium
              onClick={() => setDownloadReportSlideOut(true)}
            >
              {t("Download Reports")}
            </SecondaryButtonMedium>
          </HorizontalButtonsContainer>
          <LeadsDropdownWrapper>
            <DropDown
              items={perPageItems}
              activeItem={perPage}
              textRight={t(" Per Page")}
              showIcon
              hideBorder
              textLeft={t("items") + ":"}
              direction={"left"}
              className={"per_Page"}
              clickHandler={changePerPage}
            />
          </LeadsDropdownWrapper>
        </TabsAndFilters>
        <TabPanels>
          <ContentWrapper>
            <CustomTabPanel refreshKey={refreshKey}>
              <QuoteRequestsList perPage={perPage} tabIndex={tabIndex} />
            </CustomTabPanel>
            <CustomTabPanel refreshKey={refreshKey}>
              <SampleRequestLeadList perPage={perPage} tabIndex={tabIndex} />
            </CustomTabPanel>
            <CustomTabPanel refreshKey={refreshKey}>
              <RegistrationList
                perPage={perPage}
                user={user}
                tabIndex={tabIndex}
              />
            </CustomTabPanel>
            <CustomTabPanel refreshKey={refreshKey}>
              <ContactUsList perPage={perPage} tabIndex={tabIndex} />
            </CustomTabPanel>
            <SlideOut
              closeFlyout={closeDownloadReportSlideout}
              show={downloadReportSlideOut}
            >
              <DownloadReport
                closeSlideout={closeDownloadReportSlideout}
                transactionType={"lead"}
              />
            </SlideOut>
          </ContentWrapper>
        </TabPanels>
      </Tabs>
    </PageWrapper>
  );
});
