import type { ChangeEvent, MouseEvent } from "react";
import React, { useState, useEffect, useContext } from "react";
import useSWR from "swr";
import { Table } from "../../../components/Table/Table";
import { Pagination } from "../../../components/Pagination/Pagination";
import {
  convertStringArrayToObj,
  fetcher,
  formatDate,
  makeUrlWithParams,
  rowHover,
  useInAppNotifications,
  useStoreState,
} from "../../../util/util";
import type {
  Lead,
  IPagination,
  User,
  LocationState,
} from "../../../types/types";
import { formateInternationalPhoneNumber } from "../../../util/phone";
import { useHistory, useLocation } from "react-router-dom";
import { useRoutePath } from "../../../util/Routing";
import { Auth } from "../../../components/Auth";
import { StatusRight } from "../../../components/Status/Status";
import type { StatusCellProps } from "./leadsUtilities";
import { LeadStatusFilters, getLeadStatusDetails } from "./leadsUtilities";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import { useTranslation } from "react-i18next";
import { FiltersWrapper } from "../../../layout/portalPageLayout";
import { FiltersDropDown } from "../../../components/FiltersDropDown/FiltersDropDown";
import type { FiltersDropDownItem } from "../../../components/FiltersDropDown/FiltersDropDown";
import { useDebounce } from "../../../util/hooks";
import { SearchBar } from "../../../components/SearchBar/SearchBar";

const BASE_URL = (storefrontId: string) =>
  `/v1/storefronts/${storefrontId}/leads/contact-requests?`;

type ContactUsListProps = {
  perPage: number;
  tabIndex: number;
};

type ContactUsAPIResponse = {
  data: Lead[];
  pagination: IPagination;
};

type MessagesTableData = {
  id: string;
  companyName: string;
  fullName: string;
  email: string;
  phone: string;
  received: string;
  unread: boolean;
};

type ApplyFiltersToURL = {
  params: URLSearchParams;
};

function applyFiltersToURL({ params }: ApplyFiltersToURL): URLSearchParams {
  return params;
}

export const ContactUsList = ({ perPage, tabIndex }: ContactUsListProps) => {
  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    status: ArrayParam,
    q: StringParam,
  });
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const history = useHistory();
  const location = useLocation();
  const { adminPath } = useRoutePath();
  const { t } = useTranslation();
  const { user } = useContext(Auth);
  const { storefront_id } = useStoreState();
  const [selectedStatus, setSelectedStatus] = useState<FiltersDropDownItem[]>(
    []
  );
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [selectedStatusList, setSelectedStatusList] = useState<string[]>(
    (query?.status?.filter((status) => !!status) as string[]) ?? []
  );
  const { leadsNotifications } = useInAppNotifications(
    storefront_id,
    user as User
  );

  function constructQuery() {
    const params = new URLSearchParams(
      `offset=${offset as number}&limit=${perPage}&q=${debouncedSearchQuery}`
    );

    if (selectedStatusList.length) {
      selectedStatusList.forEach((param) => params.append("status", param));
    }

    return (
      BASE_URL(storefront_id) +
      applyFiltersToURL({
        params: params,
      })
    );
  }

  const { data: messagesResponse, error: messagesError } =
    useSWR<ContactUsAPIResponse>(constructQuery(), fetcher);

  const isLoading = !!(!messagesResponse && !messagesError);

  //table
  const [tableData, setTableData] = useState<MessagesTableData[]>([]);

  // Placeholder condition for showing 'Region' column
  const [showRegion, setShowRegion] = useState(false);
  useEffect(() => {
    if (messagesResponse) {
      // Check if 'region' is present in any of the sample requests
      const hasRegion = messagesResponse.data.some((lead) => "region" in lead);
      setShowRegion(hasRegion);
    }
  }, [messagesResponse]);
  const tableColumns = React.useMemo(
    () => [
      {
        Header: t("Customer Name"),
        accessor: "fullName",
      },
      {
        Header: t("Company Name"),
        accessor: "companyName",
      },
      {
        Header: t("Email"),
        accessor: "email",
      },
      ...(showRegion
        ? [
            {
              Header: t("Region"),
              accessor: "region",
            },
          ]
        : []),
      {
        Header: t("Received"),
        accessor: "received",
        align: "right",
      },
      {
        Header: t("Assignee"),
        accessor: "assignee",
      },
      {
        Header: t("Status"),
        accessor: "status",
        align: "right",
        Cell: ({ value }: { value: StatusCellProps }) => {
          return <StatusRight color={value?.color} text={value?.text} />;
        },
      },
    ],
    [t, showRegion]
  );

  useEffect(() => {
    setQuery({ offset, status: selectedStatusList });
    const statusObj = convertStringArrayToObj(selectedStatusList);
    setSelectedStatus(
      LeadStatusFilters(t).filter((item) => !!statusObj[item.value])
    );
  }, [offset, perPage, selectedStatusList, setQuery, t]);

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  useEffect(() => {
    const getUnread = (message: Lead): boolean => {
      return leadsNotifications?.contact_us.ids
        ? leadsNotifications?.contact_us.ids.includes(message?.id)
        : false;
    };

    const handleMessagesData = ({ data, pagination }: ContactUsAPIResponse) => {
      setTableData(
        data.map((message: Lead) => ({
          id: message.id,
          companyName: message.buyer_company_name || "--",
          assignee: message.assignee
            ? `${message.assignee?.firstname} ${message.assignee?.lastname}`
            : "--",
          fullName:
            `${message.buyer_first_name} ${message.buyer_last_name}` || "--",
          email: message.buyer_email || "--",
          phone: message.buyer_phone_number
            ? formateInternationalPhoneNumber(message.buyer_phone_number)
            : "--",
          received: formatDate(message.created_at),
          unread: getUnread(message),
          status: {
            ...getLeadStatusDetails(message.status, t),
            lead: message,
          },
          region: message?.region || "--",
        }))
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (messagesResponse) {
      const { data: messages, pagination } = messagesResponse;

      handleMessagesData({ data: messages, pagination });
    }
  }, [
    messagesResponse,
    setTableData,
    perPage,
    leadsNotifications,
    t,
    showRegion,
  ]);

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };
  const applyStatus = (items: FiltersDropDownItem[]) => {
    setSelectedStatus(items);
    setSelectedStatusList(items.map((item) => item.value));
    changePage(0);
  };

  const handleContactClick = (e: MouseEvent) => {
    history.push(
      makeUrlWithParams(`${adminPath}/leads/contact-us/${e.currentTarget.id}`, {
        offset,
        perPage,
      }),
      {
        listState: {
          offset,
          perPage,
          selectedStatusList,
          searchQuery,
          tabIndex,
        },
      }
    );
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  useEffect(() => {
    const listState = (location.state as LocationState)?.listState;

    if (listState) {
      setOffset(listState.offset ?? 0);
      setSelectedStatusList(listState.selectedStatusList ?? []);
      setSearchQuery(listState.searchQuery ?? "");

      // Clear the state after restoring it
      history.replace({ ...location, state: undefined });
    }
  }, [location, history]);

  return (
    <>
      <FiltersWrapper>
        <FiltersDropDown
          activeItems={selectedStatus}
          applyStatus={applyStatus}
          list={LeadStatusFilters(t)}
        />
        <SearchBar
          query={searchQuery}
          placeHolder={t("Search by Company Name, Customer or Assignee")}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
      </FiltersWrapper>

      <Table
        columns={tableColumns}
        data={tableData}
        isLoading={isLoading}
        error={messagesError}
        rowClick={handleContactClick}
        rowHover={rowHover}
      />
      <Pagination
        pagination={tablePagination}
        offset={offset}
        handlePageClick={changePage}
      />
    </>
  );
};
