import React, { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import type { TagClassificationConfig } from "../../../../types/types";
import { useQueryParams, StringParam, NumberParam } from "use-query-params";
import type { SortingRule } from "react-table";
import { useDebounce } from "../../../../util/hooks";
import { Pagination } from "../../../../components/Pagination/Pagination";
import {
  PrimaryButtonWithPlusIcon,
  SecondaryButtonMedium,
} from "../../../../components/Buttons/Buttons";
import { SearchBar } from "../../../../components/SearchBar/SearchBar";
import {
  PageWrapper,
  ContentWrapper,
  PageTitle,
  FullWidthHorizontalSeparator,
} from "../../../../layout/portalPageLayout";
import { useHistory, useLocation } from "react-router-dom";
import { useRoutePath } from "../../../../util/Routing";
import {
  formatDate,
  updateCustomLabels,
  useStoreState,
  useSupportedLanguages,
  defaultHandleSort,
  rowHover,
} from "../../../../util/util";
import { endpoints } from "../../../../endpoints";
import { useTranslation } from "react-i18next";
import { Flex, Flex1, Flex2 } from "../../../../layout/FormLayout";
import { DropDown } from "../../../../components/DropDown/DropDown";
import { DropdownContainer } from "../../../../components/Layout/Layout";
import type {
  PIMProductBase,
  PIMProductBasePaginagedOutput,
  ProductStatusType,
} from "../../../../types/types.PIM";
import { FiltersArea } from "../../../../components/Filters/Filters";
import {
  getProductStatusColor,
  getProductStatusText,
  StatusLeft,
} from "../../../../components/Status/Status";
import {
  applyFiltersToURL,
  get_all_condensed_products,
} from "./ProductsList.util";
import type { ConstructQuery, SellerTableProduct } from "./ProductsList.util";
import { TableWithBulkAction } from "../../../../components/Table/TableWithBulkAction";
import { BulkActionOnProducts } from "./BulkActionOnProducts/BulkActionOnProducts";
import styled from "styled-components";
import { WithPermission } from "../../../../components/WithPermission/WithPermission";
import { useAuthContext } from "../../../../components/Auth";
import { Table } from "../../../../components/Table/Table";
import { groupPortfolioParamsByKey } from "../../../public";
import { SelectedFiltersArea } from "../../../../components/Filters/SelectedFiltersArea";
import { useStorageState } from "react-storage-hooks";
import { ProductSearchPopup } from "../../../public/ProductDetailsPortfolio/ProductSearchPopup";
import { PRODUCT_SEARCH_POPUP } from "../../../public/pages.public.util";
import type { TableRefProps } from "../../../../components/Table/utils";

export const TotalProducts = styled.div`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: 0;
`;

const FilterContainer = styled.div`
  transition: height 0.3s ease-in-out;
`;

const TransitionWrapper = styled.div<{ isVisible: boolean }>`
  max-height: ${(props) => (props.isVisible ? "1000px" : "0")};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  pointer-events: ${(props) => (props.isVisible ? "auto" : "none")};
`;

/**
 * Products list page (e.g. manage > products).  Used for seller admin.
 */
export function ProductsList() {
  const queryObject = () => {
    const querylist: any = {
      product: StringParam,
      q: StringParam,
      offset: NumberParam,
      perPage: NumberParam,
    };
    return querylist;
  };

  const [showFilters, setShowFilters] = useStorageState(
    sessionStorage,
    "productListShowFilters",
    true
  );

  const [show_enterprise_search, set_show_enterprise_search] = useState(false);

  const [query, setQuery] = useQueryParams(queryObject());

  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });

  // searchQuery is loaded from the URL if q exists.
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const { adminPath } = useRoutePath();
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const perPageItems = [10, 20, 50];
  const [perPage, setPerPage] = useState(query.perPage ?? 10);
  const [tableData, setTableData] = useState<SellerTableProduct[]>([]);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
    totalCount: 0,
  });

  const [filtersFromUrl, setFiltersFromUrl] =
    useState<{ [key: string]: string[] }>();

  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);

  const search_ref = useRef<HTMLDivElement>(null);

  const resetSelectedRowsRef = useRef<TableRefProps>(null);

  const {
    storefront_id,
    tenant_id,
    storefront_metadata: { only_bulk_upload_enabled, enable_vertex_ai_search },
  } = useStoreState();

  const { t } = useTranslation();

  const { supportedLanguageOptions } = useSupportedLanguages();
  const { hasPermission, roleIsSomeKindOfSeller } = useAuthContext();

  const {
    data: productsResponse,
    error: productsError,
    mutate: mutateProducts,
  } = useSWR<PIMProductBasePaginagedOutput>(
    constructQuery({
      baseUrl: endpoints.v2_tenants_id_pim_products_summary(tenant_id),
      query: debouncedSearchQuery,
      filtersFromUrl,
    })
  );

  const { data: customLabels, error: customLabelsError } = useSWR<
    TagClassificationConfig[]
  >(
    storefront_id
      ? endpoints.v2_storefronts_id_products_filters_customLabels(storefront_id)
      : null,
    { revalidateOnFocus: true }
  );

  updateCustomLabels(customLabels, supportedLanguageOptions);

  const history = useHistory();

  const { search, pathname } = useLocation();

  //table
  const tableColumns = React.useMemo(
    () => [
      {
        Header: t("Product ID"),
        accessor: "externalId",
        disableSortBy: true,
      },
      {
        Header: t("Product Name"),
        accessor: "name",
      },
      {
        Header: t("Chemical Name"),
        accessor: "chemical_name",
      },
      {
        Header: t("Last Modified"),
        accessor: "modified_at",
        Cell: ({ value }: { value: { date: string; id: string } }) => (
          <span>{value.date}</span>
        ),
      },
      {
        Header: t("Product Completion"),
        accessor: "completion_score",
      },
      {
        Header: t("Status"),
        accessor: "status",
        disableSortBy: true,
        align: "left",
        Cell: ({ value: status }: { value: ProductStatusType }) => (
          <StatusLeft
            color={getProductStatusColor(status ?? "draft")}
            text={getProductStatusText(status ?? "draft", t)}
            textStyle={{ fontSize: "13px" }}
            productStatusType="product"
          />
        ),
      },
    ],
    [t]
  );

  /**
   * construct query from selected filters,
   * add search term if it exists
   */
  function constructQuery({
    baseUrl,
    query,
    filtersFromUrl,
  }: ConstructQuery<PIMProductBase>) {
    const paramsWithOffset = new URLSearchParams(
      `offset=${offset}&limit=${perPage}`
    );

    if (query) paramsWithOffset.append("q", query);
    paramsWithOffset.append("order_by", sortingRules.orderBy || "asc");
    if (sortingRules.sortBy) {
      paramsWithOffset.append("sort_by", sortingRules.sortBy);
    }
    paramsWithOffset.append("show_inactive", "true");
    return (
      baseUrl +
      "?" +
      applyFiltersToURL({
        filtersFromUrl,
        params: paramsWithOffset,
      })
    );
  }

  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const handleClearFilter = () => {
    const destination = pathname;
    history.replace(destination);
    setQuery({ q: query.q, offset: query.offset, perPage: query.per_Page });
    mutateProducts();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
    if (e.target.value) {
      localStorage.removeItem(PRODUCT_SEARCH_POPUP);
    }
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const close_search_popup = () => {
    set_show_enterprise_search(false);
    localStorage.setItem(PRODUCT_SEARCH_POPUP, "closed");
  };

  const handleRowClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    const basePath = `${adminPath}/pim/products`;
    const url = `${basePath}/${e.currentTarget.id}`;
    if (e.metaKey || e.ctrlKey) {
      window.open(url, "_blank");
    } else {
      history.push(url);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(search.substring(1));
    const filters = groupPortfolioParamsByKey(params);
    setFiltersFromUrl((prev) => {
      /**
       * If the offset is the same as the previous offset, or if the perPage changes and triggers a new offset (of zero),
       * then reset the selected rows.
       */
      if (
        filters?.offset?.[0] === prev?.offset?.[0] ||
        (filters?.perPage?.[0] !== prev?.perPage?.[0] &&
          filters?.offset?.[0] === "0")
      ) {
        resetSelectedRowsRef?.current?.resetSelectedRows();
      }
      return filters;
    });
  }, [search]);

  useEffect(() => {
    const handleProductsData = ({
      data,
      pagination,
    }: PIMProductBasePaginagedOutput) => {
      setTableData(
        data.map(
          ({
            id,
            name,
            last_modified_full: { modified_at },
            display_status,
            status,
            primary_staged_product_id,
            completion_score,
            display_completion_score,
            product_number,
            chemical_name,
          }) => {
            return {
              id: product_number ?? primary_staged_product_id ?? id,
              externalId: product_number ?? "--",
              name: name ?? "--",
              chemical_name: chemical_name ?? "--",
              modified_at: {
                date: formatDate(modified_at),
                id,
              },
              completion_score:
                display_status === "staged" ||
                display_status === "unpublished_staged"
                  ? `${
                      display_completion_score ? display_completion_score : "--"
                    }%`
                  : `${completion_score ? completion_score : "--"}%`,
              status: display_status ?? status ?? "draft",
            };
          }
        )
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
        totalCount: pagination.total,
      });
    };

    if (productsResponse) {
      const { data: products, pagination } = productsResponse;
      handleProductsData({ data: products, pagination });
    }
  }, [productsResponse, perPage]);

  useEffect(() => {
    setQuery({ offset, perPage });
  }, [offset, perPage, setQuery]);

  useEffect(() => {
    // This useEffect handles setting the selected product,
    // and keeping the selected product and query in sync with the URL.
    setQuery({ q: !!debouncedSearchQuery ? debouncedSearchQuery : undefined });
    const should_search_popup_be_closed = Boolean(
      localStorage.getItem(PRODUCT_SEARCH_POPUP)
    );
    set_show_enterprise_search(
      !should_search_popup_be_closed &&
        !!debouncedSearchQuery &&
        enable_vertex_ai_search
    );
    if (!debouncedSearchQuery) {
      localStorage.removeItem(PRODUCT_SEARCH_POPUP);
    }
  }, [setQuery, debouncedSearchQuery, enable_vertex_ai_search]);

  useEffect(() => {
    if (search) {
      mutateProducts();
    }
  }, [search, mutateProducts]);

  useEffect(() => {
    if (filtersFromUrl) {
      const count = Object.entries(filtersFromUrl).reduce(
        (acc, [key, value]) => {
          if (
            key !== "offset" &&
            key !== "perPage" &&
            key !== "q" &&
            key !== "active_tag_view"
          ) {
            return acc + value.length;
          }
          return acc;
        },
        0
      );
      setAppliedFiltersCount(count);
    } else {
      setAppliedFiltersCount(0);
    }
  }, [filtersFromUrl]);

  const isLoading = !productsResponse && !productsError;

  const placeholder = t("Search by product name or CAS#");

  const search_bar_left_position = search_ref.current?.getBoundingClientRect()
    .left
    ? Math.ceil(search_ref.current?.getBoundingClientRect().left)
    : 0;
  const search_bar_top_position = search_ref.current?.getBoundingClientRect()
    .top
    ? Math.ceil(search_ref.current?.getBoundingClientRect().top)
    : 0;

  return (
    <PageWrapper>
      <PageTitle>{t("Products")}</PageTitle>
      <FullWidthHorizontalSeparator />
      <Flex style={{ marginTop: "30px" }}>
        <Flex2 style={{ display: "flex", alignItems: "center" }}>
          <SecondaryButtonMedium
            onClick={() => setShowFilters(!showFilters)}
            style={{ marginRight: "10px" }}
          >
            {showFilters ? t("Hide Filters") : t("Show Filters")}
            {!showFilters &&
              appliedFiltersCount > 0 &&
              ` (${appliedFiltersCount})`}
          </SecondaryButtonMedium>
          <SearchBar
            query={searchQuery}
            placeHolder={placeholder}
            handleChange={handleSearch}
            handleClearInput={handleClearSearch}
            maxWidth="450px"
            ref={search_ref}
          />
        </Flex2>
        {/* All sellers can create new products. */}
        <Flex1 style={{ textAlign: "right" }}>
          <div
            data-alignright
            style={{ display: "flex", justifyContent: "end" }}
          >
            <WithPermission permission="modify_products">
              <PrimaryButtonWithPlusIcon
                onClick={() => history.push(`${adminPath}/pim/products/new`)}
                style={{ padding: "8px 12px" }}
              >
                {only_bulk_upload_enabled ? t("Upload") : t("Create")}
              </PrimaryButtonWithPlusIcon>
            </WithPermission>
          </div>
        </Flex1>
      </Flex>
      <FilterContainer>
        <TransitionWrapper isVisible={showFilters}>
          {!customLabelsError && (
            <FiltersArea
              resetOffset={setOffset}
              customLabels={customLabels ?? []}
              clearFilter={() => handleClearFilter()}
              showStatusFilter={true}
            />
          )}
        </TransitionWrapper>
        <TransitionWrapper isVisible={!showFilters}>
          <div>
            <SelectedFiltersArea
              clearFilter={handleClearFilter}
              filtersList={
                customLabels?.map(
                  (filter: TagClassificationConfig) => filter.filter_type
                ) || []
              }
              isPublic={false}
            />
          </div>
        </TransitionWrapper>
      </FilterContainer>

      <DropdownContainer style={{ position: "relative", zIndex: 2 }}>
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textLeft={t("items") + ":"}
          textRight={t(" Per Page")}
          direction={"right"}
          className={"per_Page"}
          clickHandler={changePerPage}
        ></DropDown>
        <TotalProducts>
          {tablePagination.totalCount} {t("products")}
        </TotalProducts>
      </DropdownContainer>
      <ContentWrapper style={{ position: "relative", zIndex: 1 }}>
        {hasPermission("modify_products") ? (
          <TableWithBulkAction
            columns={tableColumns}
            data={tableData}
            isLoading={isLoading}
            error={productsError}
            rowClick={handleRowClick}
            rowHover={rowHover}
            handleSort={handleSort}
            bulkActionElement={({ rows, resetSelectedRows }) => (
              <BulkActionOnProducts
                rows={
                  rows === "all"
                    ? get_all_condensed_products(
                        tenant_id,
                        new URLSearchParams(
                          search.substring(1) + "&show_inactive=true"
                        )
                      )
                    : (rows as SellerTableProduct[]).map(({ id, name }) => ({
                        id,
                        name,
                      }))
                }
                mutateProducts={mutateProducts}
                onResetSelectedRows={resetSelectedRows}
              />
            )}
            pageIndex={tablePagination.pageIndex}
            ref={resetSelectedRowsRef}
          />
        ) : (
          <Table
            columns={tableColumns}
            data={tableData}
            isLoading={isLoading}
            error={productsError}
            rowClick={handleRowClick}
            rowHover={rowHover}
            handleSort={handleSort}
          />
        )}

        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
      {show_enterprise_search && roleIsSomeKindOfSeller && (
        <ProductSearchPopup
          search_bar_left_position={search_bar_left_position}
          search_bar_top_position={search_bar_top_position}
          search_query={searchQuery}
          debounced_search_query={debouncedSearchQuery}
          placeholder={placeholder}
          handle_parent_search={handleSearch}
          handle_parent_clear_search={handleClearSearch}
          search_bar_max_width="450px"
          custom_labels={customLabels}
          close_search_popup={close_search_popup}
          clear_filter={handleClearFilter}
          handle_product_click={(product_id) => {
            handleRowClick({
              currentTarget: { id: product_id },
            } as React.MouseEvent<HTMLTableRowElement, MouseEvent>);
          }}
        />
      )}
    </PageWrapper>
  );
}
