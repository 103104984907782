import { useAuthContext } from "../../../components/Auth";
import type { DataMutate } from "../../../types/types";
import type { PIMProduct, TemplateTabData } from "../../../types/types.PIM";
import { CollectionComponent } from "./Collections/CollectionComponent";
import { ProductGroupCard } from "./ProductOverview/ProductGroupCard";

interface ITabPanelProps {
  product: PIMProduct;
  tab: TemplateTabData;
  mutateProduct: DataMutate<PIMProduct>;
  replaceProductId: (id: string) => void;
  isPortfolio: boolean;
}

export const ProductTabPanel = ({
  product,
  tab,
  isPortfolio = false,
  mutateProduct,
  replaceProductId,
}: ITabPanelProps) => {
  const { roleIsGuest } = useAuthContext();
  return (
    <>
      {tab.items.map((item) => {
        if (
          item.group_type === "collection" &&
          !!product.product_schema.collections.find((collection) =>
            collection.name.toLowerCase().includes(item.name.toLowerCase())
          )
        ) {
          const collection = product.product_schema.collections.find(
            (collection) =>
              collection.name.toLowerCase() === item.name.toLowerCase()
          );
          const showCollection =
            (!!collection?.value_rows?.length &&
              collection.name.toLocaleLowerCase() !== "skus" &&
              collection?.value_rows?.length > 0) ||
            (!isPortfolio && collection?.name.toLocaleLowerCase() !== "skus");

          return (
            <>
              {!!collection && showCollection && (
                <CollectionComponent
                  collection={collection}
                  product={product}
                  fetchProductData={mutateProduct}
                  replaceProductId={replaceProductId}
                  isPortfolio={isPortfolio}
                />
              )}
            </>
          );
        } else {
          const group = product.product_schema.groups.find(
            (group) => group.name.toLowerCase() === item.name.toLowerCase()
          );

          const groupHasValues = () => {
            let hasValues = false;
            group?.attributes?.forEach((attribute) => {
              if (
                attribute.values?.length > 0 &&
                (!isPortfolio ||
                  (isPortfolio && roleIsGuest && !attribute.is_restricted) ||
                  (isPortfolio && !roleIsGuest))
              ) {
                hasValues = true;
              }
            });
            return hasValues;
          };
          const showGroup =
            (!!group?.attributes?.length &&
              group.name.toLowerCase() !== "seo" &&
              groupHasValues()) ||
            (!isPortfolio && group?.name.toLowerCase() !== "seo");
          return (
            <>
              {!!group && showGroup && (
                <ProductGroupCard
                  product={product}
                  group={group}
                  key={group.id}
                  mutateProduct={mutateProduct}
                  isPortfolio={isPortfolio}
                  replaceProductId={replaceProductId}
                />
              )}
            </>
          );
        }
      })}
    </>
  );
};
