import { useTranslation } from "react-i18next";
import type {
  Assets,
  AssetsSummary,
  AttributeSchema,
  AttributeValue,
  ImageAttributeValue,
} from "../../../../types/types.PIM";
import { useRoutePath } from "../../../../util/Routing";
import { useAuthContext } from "../../../../components/Auth";
import moment from "moment";
import { ToggleSwitchV2 } from "../../../../components/ToggleSwitch/ToggleSwitch";
import { LinkAttributeValueSchema } from "../../../../util/zod.util";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { ImageAttributePreview } from "../../../../components/AttributeGroupFormGenerator/ImageAttributePreview";
import { useStoreState } from "../../../../util/util";
import useSWR from "swr";
import { endpoints } from "../../../../endpoints";
import { DelayedSpinner } from "../../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../../components/Error";
import { ImagePlaceHolderIcon } from "../../../../components/Icons/Icons";
import { TwoColumGrid } from "../../../../layout/portalPageLayout";

const InnerContentText = styled.p`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  margin: 2px 0 7px;
`;

const UL = styled.ul`
  padding-left: 8px;
  margin: 5px 0 10px;
  margin-left: 13px;
  li {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    line-height: 1.33;
    padding-bottom: 5px;
    &::marker {
      font-size: ${({ theme }) => theme.fontSizes.regular};
    }
    span {
      margin-left: -2px;
      position: relative;
    }
  }
`;

const MultilineWrapper = styled.div<{ line_clamp: number }>`
  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${({ line_clamp }) => line_clamp};
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
`;

const renderIconWithText = (
  value: AttributeValue,
  size: "small" | "medium" | "large"
) => {
  const imageSize = size === "small" ? 32 : size === "medium" ? 64 : 128;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: size === "large" ? "column" : "row",
        alignItems: size === "small" || size === "medium" ? "center" : "unset",
        justifyContent: "flex-start",
      }}
    >
      {value.enum_variant?.thumbnail ? (
        <img
          src={value.enum_variant?.thumbnail}
          alt="attribute-icon"
          width={imageSize}
          height={imageSize}
          style={{
            marginRight: size !== "large" ? "10px" : "0",
            marginBottom: size === "large" ? "10px" : "0",
            borderRadius: "3px",
          }}
        />
      ) : (
        <div
          style={{
            marginRight: size !== "large" ? "10px" : "0",
            marginBottom: size === "large" ? "10px" : "0",
          }}
        >
          <ImagePlaceHolderIcon width={imageSize} height={imageSize} />
        </div>
      )}
      <div>{value.enum_variant?.variant}</div>
    </div>
  );
};

const ImageAttributeDisplay = ({
  attribute,
  isPortfolio,
}: {
  attribute: AttributeSchema;
  isPortfolio: boolean;
}) => {
  const { tenant_id } = useStoreState();
  const { t } = useTranslation();

  const { data: asset, error: asset_error } = useSWR<AssetsSummary>(
    attribute.values?.length
      ? endpoints.v2_tenants_id_pim_assets_id_summary(
          tenant_id,
          (attribute.values[0].value as ImageAttributeValue).asset_id
        )
      : null
  );

  return (
    <>
      {!asset_error && !asset && <DelayedSpinner />}
      {asset_error && <ErrorPlaceholder message={t("Could not load image")} />}
      {asset && (
        <ImageAttributePreview
          attribute={attribute}
          asset={asset as Assets}
          is_portfolio_page={isPortfolio}
        />
      )}
    </>
  );
};

export const AttributeValueDisplay = ({
  attribute,
  isPortfolio,
  max_line_clamp,
}: {
  attribute: AttributeSchema;
  isPortfolio: boolean;
  max_line_clamp?: number;
}) => {
  const { storePath, adminPath } = useRoutePath();
  const { t } = useTranslation();
  const { roleIsSellerAdmin, user } = useAuthContext();

  switch (attribute.input_type) {
    case "single_select":
      if (attribute.icons_enabled && attribute.values?.length) {
        return renderIconWithText(
          attribute.values[0],
          attribute.size ?? "medium"
        );
      }
      return (
        <InnerContentText>
          {attribute.values?.length && attribute.values[0].value !== " "
            ? (attribute.values[0].value as string)
            : "--"}
        </InnerContentText>
      );
    case "numeric":
      return (
        <InnerContentText>
          {attribute.values?.length && attribute.values[0].value !== " "
            ? (attribute.values[0].value as string)
            : "--"}
        </InnerContentText>
      );
    case "date":
      return (
        <InnerContentText>
          {attribute.values?.length && attribute.values[0].value !== " "
            ? moment(attribute.values[0].value as string)?.format("MM/DD/YYYY")
            : "--"}
        </InnerContentText>
      );
    case "form_field":
      return (
        <InnerContentText>
          {attribute.values?.length && attribute.values[0].value !== " "
            ? (attribute.values[0].value as string)
            : "--"}
        </InnerContentText>
      );
    case "multiline_entry":
      return (
        <>
          {attribute.values?.length ? (
            <>
              {max_line_clamp ? (
                <MultilineWrapper
                  line_clamp={max_line_clamp}
                  dangerouslySetInnerHTML={{
                    __html:
                      `<div class="ql-editor">${
                        attribute.values[0].value as string
                      }</div>` || "--",
                  }}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      `<div class="ql-editor">${
                        attribute.values[0].value as string
                      }</div>` || "--",
                  }}
                />
              )}
            </>
          ) : (
            <InnerContentText>--</InnerContentText>
          )}
        </>
      );
    case "toggle":
      return (
        <>
          <ToggleSwitchV2
            label={attribute.display_name ? t([attribute.display_name]) : ""}
            name={attribute.name}
            defaultChecked={
              attribute.values?.length
                ? (attribute.values[0].value as boolean)
                : false
            }
            key={attribute.id}
            disabled={true}
          />
        </>
      );
    case "multi_select":
      const size = attribute.size;
      const imageSize = size === "small" ? 32 : size === "medium" ? 64 : 128;

      const itemWidth = size === "large" ? imageSize : `calc(33.33% - 10px)`;

      if (attribute.values?.length && !attribute.icons_enabled) {
        return (
          <TwoColumGrid>
            <UL>
              {attribute.values.map((value) => {
                return (
                  <li key={value.id}>
                    <span>{value.enum_variant?.variant}</span>
                  </li>
                );
              })}
            </UL>
          </TwoColumGrid>
        );
      }

      return attribute.values?.length && attribute.icons_enabled ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "15px",
            justifyContent: "flex-start",
          }}
        >
          {attribute.values.map((value) => (
            <div key={value.id} style={{ width: itemWidth }}>
              {renderIconWithText(value, size)}
            </div>
          ))}
        </div>
      ) : (
        <InnerContentText>--</InnerContentText>
      );
    case "link":
      const parsed = LinkAttributeValueSchema.safeParse(
        attribute.values?.[0]?.value
      );

      if (parsed.success && parsed.data.url) {
        return (
          <InnerContentText>
            <a href={parsed.data.url} target={"_blank"} rel="noreferrer">
              {parsed.data.display_text}
            </a>
          </InnerContentText>
        );
      } else if (
        parsed.success &&
        !parsed.data.url &&
        parsed.data.display_text !== ""
      ) {
        return <span>{parsed.data.display_text}</span>;
      } else return <span>--</span>;
    case "product_reference":
      return attribute.values?.length ? (
        <UL>
          {attribute.values.map((value) => {
            const tenantHasAccess =
              value?.product_ref?.visibility_tenants?.find(
                (tenant) => tenant?.id === user?.tenant_id
              )?.id;
            return (
              <li key={value.id}>
                {(value?.product_ref?.status === "published" &&
                  value?.product_ref?.is_visible_on_storefronts &&
                  (value?.product_ref?.is_accessible || tenantHasAccess)) ||
                roleIsSellerAdmin ||
                (value?.product_ref?.status === "published" &&
                  value?.product_ref?.is_accessible &&
                  user) ||
                (value?.product_ref?.status === "published" &&
                  tenantHasAccess) ? (
                  <Link
                    to={
                      isPortfolio
                        ? `${storePath}/product/${value?.product_ref?.slug}`
                        : `${adminPath}/pim/products/${
                            value?.product_ref?.product_number ??
                            value?.product_ref?.id
                          }`
                    }
                  >
                    {value.product_ref?.name}
                  </Link>
                ) : (
                  <>
                    <span
                      data-tip={
                        value?.product_ref?.status !== "published"
                          ? t("Product informations is unavailable to view")
                          : t("Product informations is not accessible")
                      }
                      data-for={value.id}
                    >
                      {value.product_ref?.name}
                    </span>
                    <ReactTooltip
                      delayHide={200}
                      id={value.id}
                      effect="solid"
                    />
                  </>
                )}
              </li>
            );
          })}
        </UL>
      ) : (
        <InnerContentText>--</InnerContentText>
      );
    case "image":
      return attribute.values?.length ? (
        <ImageAttributeDisplay
          attribute={attribute}
          isPortfolio={isPortfolio}
        />
      ) : (
        <InnerContentText>--</InnerContentText>
      );
    default:
      return (
        <InnerContentText>
          {attribute.values?.length
            ? (attribute.values[0].value as string)
            : "--"}
        </InnerContentText>
      );
  }
};
