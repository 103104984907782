import React, { useContext } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  DeleteButton,
  PrimaryButtonFitContainer,
  SecondaryButtonWithPlusIcon,
} from "../../../../../../components/Buttons/Buttons";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import type {
  CollectionColumnCreationSchema,
  CollectionCreationSchema,
} from "../../../../../../types/types.PIM";
import { useFormWrapper, useStoreState } from "../../../../../../util/util";
import type { AxiosError } from "axios";
import Axios from "axios";
import * as z from "zod";
import {
  zodOptionalString,
  zodRequiredString,
} from "../../../../../../util/zod.util";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "../../../../../../layout/FormLayout";
import { SectionTitle } from "../../../../../../components/Form/Form";
import { TextField } from "../../../../../../components/TextFields/TextFields";
import { endpoints } from "../../../../../../endpoints";
import {
  MarginBottomH6,
  MarginBottomHeaderLeft,
  shouldUpdateDisplayName,
} from "../../SellerAdminPIMAttributes/CreateAttribute";
import { SelectWithDeleteButton } from "../../../../../../layout/shared";
import { findNonEmptyDuplicateIndexes } from "../../../../../../util/form.utils";
import { AsyncSearchSelect } from "../../../../../../components/AsyncSearchSelect/AsyncSearchSelect";
import {
  useHandleAttributesAsyncSearch,
  zodSelectAttribute,
  zodSelectAttributeType,
} from "../util";

const CreateAttributeCollectionSchema = z.object({
  name: z.string(),
  display_name: z.string().optional(),
  first_attribute: zodSelectAttributeType,
  attributes: z
    .object({
      attribute: zodSelectAttributeType,
    })
    .array()
    .optional(),
});

const CreateAttributeCollectionSchemaFn = (t: (s: string) => string) =>
  z
    .object({
      name: zodRequiredString(t),
      display_name: zodOptionalString,
      first_attribute: zodSelectAttribute(t),
      attributes: z
        .object({
          attribute: zodSelectAttribute(t),
        })
        .array()
        .optional(),
    })
    .superRefine(({ first_attribute, attributes }, ctx) => {
      const duplicateIdxs = attributes
        ? findNonEmptyDuplicateIndexes([
            first_attribute.value,
            ...attributes.map(({ attribute: { value } }) => value),
          ])
        : [];
      if (duplicateIdxs.length > 0) {
        duplicateIdxs.forEach((idx) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("Duplicate attributes not allowed"),
            path: [`attributes[${idx - 1}].attribute`],
          });
        });
      }
    });

type FormOutput = z.infer<typeof CreateAttributeCollectionSchema>;

export function SellerAdminAttributesCollectionForm({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { handleAttributesSearchNoMultiline } =
    useHandleAttributesAsyncSearch("collection");

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(CreateAttributeCollectionSchemaFn(t)),
    defaultValues: {
      first_attribute: { label: undefined, value: undefined },
    },
  });
  const {
    handleSubmit,
    control,
    formState,
    errors,
    register,
    watch,
    setValue,
  } = methodsOfUseForm;
  const { notifyError, notifySuccess } = useContext(Notifications);

  const { fields, remove, append } = useFieldArray({
    control,
    name: "attributes",
  });

  const createFields = (): JSX.Element[] => {
    return fields.map((field, index: number) => (
      <SelectWithDeleteButton key={field.id}>
        <Controller
          key={field.id}
          as={AsyncSearchSelect}
          control={control}
          name={`attributes[${index}].attribute`}
          defaultValue={{ label: "", value: "" }}
          placeholder={t("Select Attribute")}
          searchFunction={handleAttributesSearchNoMultiline}
          errors={{
            [`attributes[${index}].attribute`]:
              errors?.attributes?.[index]?.attribute?.value ??
              errors?.attributes?.[index]?.attribute ??
              undefined,
          }}
          formState={formState}
          defaultOptions
        />
        <DeleteButton
          testid={`delete-button-${index}`}
          onClick={() => remove(index)}
          type="button"
          height={20}
          width={20}
        />
      </SelectWithDeleteButton>
    ));
  };

  const onSubmit = async (values: FormOutput) => {
    const attrs = values.attributes
      ? [
          values.first_attribute,
          ...values.attributes.map((attr) => attr.attribute),
        ]
      : [values.first_attribute];

    const collection: CollectionCreationSchema = {
      name: values.name.trim(),
      display_name: values.display_name ? values.display_name.trim() : "",
      description: "",
      columns: attrs.reduce<CollectionColumnCreationSchema[]>(
        (columns, { value, display_name }) => [
          ...columns,
          {
            attribute_id: value,
            display_name,
            is_required: false,
            is_editable: true,
          },
        ],
        []
      ),
      rows: [],
    };

    try {
      await Axios.post(
        endpoints.v2_tenants_id_pim_collections(tenant_id),
        collection
      );
      notifySuccess(t("Collection created successfully"));
      onSuccess();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error creating the collection"),
        {
          error,
        }
      );
    }
  };

  const { name: nameValue, display_name: displayNameValue } = watch([
    "name",
    "display_name",
  ]);
  if (
    shouldUpdateDisplayName(
      nameValue,
      displayNameValue,
      !!formState.dirtyFields["display_name"]
    )
  ) {
    setValue("display_name", nameValue);
  }
  return (
    <>
      <MarginBottomHeaderLeft>
        <SectionTitle>{t("Create Collection")}</SectionTitle>
      </MarginBottomHeaderLeft>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <MarginBottomH6>{t("Overview")}</MarginBottomH6>
        <TextField
          name="name"
          label={t("Collection Name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextField
          name="display_name"
          label={t("Display Name")}
          theref={register()}
          formState={formState}
          errors={errors}
          type="text"
        />
        <MarginBottomH6>{t("Configure attributes")}</MarginBottomH6>
        <div style={{ width: "420px" }}>
          <Controller
            as={AsyncSearchSelect}
            control={control}
            name="first_attribute"
            placeholder={t("Select Attribute")}
            searchFunction={handleAttributesSearchNoMultiline}
            autoComplete="on"
            testid="first_attribute_search"
            errors={errors}
            formState={formState}
            defaultOptions
          />
        </div>
        {createFields()}
        <SecondaryButtonWithPlusIcon
          type="button"
          style={{ marginBottom: "40px" }}
          onClick={() => append({ name: "" })}
        >
          {t("Add attribute")}
        </SecondaryButtonWithPlusIcon>
        <PrimaryButtonFitContainer type="submit">
          {t("Create Collection")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
}
